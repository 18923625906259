import { useState } from 'react';
import { EsdtTokenType } from 'types/MultiversX';
import { mapNftToAccountNftType } from 'utils';
import {
  ImageAssetThumbnailCardHeader,
  VideoAssetThumbnailCardHeader
} from '../ThumbnailCardHeader';
import './style.css';

export const InventoryThumbnail = ({
  nft,
  onClick,
  customColSize
}: {
  nft: EsdtTokenType;
  onClick?: () => void;
  customColSize?: string;
}) => {
  const _nft = mapNftToAccountNftType(nft);
  const [isVideo] = useState(_nft.videoResourceUrl !== '');
  const defaultColSize = 'col-12 col-xl-2 col-lg-4 col-md-6 col-sm-12';

  return (
    <div className={customColSize ?? defaultColSize}>
      <div className='card bg-transparent border-0 inventory-item p-relative'>
        {isVideo ? (
          <VideoAssetThumbnailCardHeader
            assetName={_nft.name}
            videoUrl={_nft.videoResourceUrl || ''}
            assetNonce={_nft.nonce}
            quantity={!Number.isNaN(_nft.amount) ? _nft.amount : undefined}
            onClick={onClick}
          />
        ) : (
          <ImageAssetThumbnailCardHeader
            assetName={_nft.name}
            assetNonce={_nft.nonce}
            thumbnailUrl={_nft.thumbnailUrl}
            summoningInfo={{
              regularSummons: _nft.regularSummoningCount ?? 0,
              specialSummons: _nft.specialSummoningCount
            }}
            quantity={!Number.isNaN(_nft.amount) ? _nft.amount : undefined}
            onClick={onClick}
          />
        )}
        {/* {_nft.isSoul && (
          <div className='actions'>
            <button className='btn btn-primary'>List for sale</button>
          </div>
        )} */}
      </div>
    </div>
  );
};
