import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { RootState } from 'store';
import { EsdtTokenType } from 'types/MultiversX';
import {
  StakedAssetType,
  StoreStateStatusType,
  StoreStateType
} from 'types/store';

// TODO: move to staking pool config
export const LAND_PLOT_STAKING_SCORES = [30, 15, 10, 5, 1];

export interface LandPlotStakingPoolState {
  totalStakedSftsCount: number;
  totalStakedScore: number;
  userStakedSftsCount: number;
  userStakedScore: number;
  stakedLandPlots: StakedAssetType[];
  pendingRewards: EsdtTokenType[];
}

const defaultState: LandPlotStakingPoolState = {
  totalStakedSftsCount: 0,
  totalStakedScore: 0,
  userStakedSftsCount: 0,
  userStakedScore: 0,
  stakedLandPlots: [],
  pendingRewards: []
};

const initialState: StoreStateType<LandPlotStakingPoolState> = {
  data: defaultState,
  status: 'loading',
  error: null
};

const landPlotStakingPoolSlice = createSlice({
  name: 'landPlotStakingPool',
  initialState,
  reducers: {
    setStatus: (
      state: StoreStateType<LandPlotStakingPoolState>,
      action: PayloadAction<StoreStateStatusType>
    ) => {
      state.status = action.payload;
    },
    setLandPlotStakingPool: (
      state: StoreStateType<LandPlotStakingPoolState>,
      action: PayloadAction<LandPlotStakingPoolState>
    ) => {
      state.data = action.payload;
      console.log('setLandPlotStakingPool', state.data);
    }
  }
});

export const { setStatus, setLandPlotStakingPool } =
  landPlotStakingPoolSlice.actions;

export const selectLandPlotStakingStats = (state: RootState) => {
  const {
    totalStakedSftsCount,
    totalStakedScore,
    userStakedSftsCount,
    userStakedScore,
    stakedLandPlots
  } = state.kosonv2.landPlotStakingPool.data;

  const totalUnbondingFee = stakedLandPlots.reduce(
    (acc, { unstakeFee, amount }) => acc.plus(unstakeFee).multipliedBy(amount),
    new BigNumber(0)
  );

  const maxStakedEpoch = Math.max(
    ...stakedLandPlots.map((slp) => slp.stakeEpoch)
  );
  return {
    totalStakedSftsCount,
    totalStakedScore,
    userStakedSftsCount,
    userStakedScore,
    totalUnbondingFee: totalUnbondingFee.toString(10),
    lastStakedEpoch: maxStakedEpoch
  };
};

export const selectStakedLandPlots = (state: RootState) => {
  return state.kosonv2.landPlotStakingPool.data.stakedLandPlots;
};

export const selectPendingRewards = (state: RootState) => {
  return state.kosonv2.landPlotStakingPool.data.pendingRewards;
};

export const selectStatus = (state: RootState) =>
  state.kosonv2.landPlotStakingPool.status;

export default landPlotStakingPoolSlice.reducer;
