import BigNumber from 'bignumber.js';
import {
  KOSON_STAKING_POOL_SC_ADDRESSES,
  LAND_CHEST_SALE_SC_ADDRESS,
  // LAND_PLOT_STAKING_SC_ADDRESS,
  MARKETPLACE_SC_ADDRESS,
  MINTING_SC_ADDRESS,
  SOUL_NFT_STAKING_SC_ADDRESS,
  NFT_SWAPS_SC_ADDRESS,
  VESTING_SC_ADDRESS,
  SUMMONING_SC_ADDRESS,
  toLocaleStringOptions,
  LAND_CHEST_OPENING_SC_ADDRESS,
  OLD_LAND_PLOT_TOKEN_IDENTIFIER,
  LAND_CHEST_NAMES,
  LAND_PLOT_STAKING_SC_ADDRESS
} from 'config/dapp-config';
import { KOSON_STAKING_POOLS } from 'config/koson-staking-pools';
import { getNftName } from 'contexts/Web3Context/helpers/nftUtils';
import { ACTIVITY_INTERACTION_TYPES } from '../../../../types';

export const getActivityInfo = (tx: any) => {
  let activityInfo = {
    timestamp: 0,
    interaction: ACTIVITY_INTERACTION_TYPES.KosonStaking,
    message: '',
    valueMessage: '',
    txHash: ''
  };
  const actualTxReceiver =
    tx.sender === tx.receiver ? tx.action.arguments.receiver : tx.receiver;
  switch (actualTxReceiver) {
    case MARKETPLACE_SC_ADDRESS:
      activityInfo = parseMarketplaceTransaction(tx, activityInfo);
      break;
    case NFT_SWAPS_SC_ADDRESS:
      activityInfo = parseSwapsOfferTransaction(tx, activityInfo);
      break;
    case MINTING_SC_ADDRESS:
      activityInfo = parseMintingOrMigrateTransaction(tx, activityInfo);
      break;
    case VESTING_SC_ADDRESS:
      activityInfo = parseVestingTransaction(tx, activityInfo);
      break;
    case SUMMONING_SC_ADDRESS:
      activityInfo = parseSummoningTransaction(tx, activityInfo);
      break;
    case SOUL_NFT_STAKING_SC_ADDRESS:
      activityInfo = parseNftStakingTransaction(tx, activityInfo);
      break;
    case LAND_PLOT_STAKING_SC_ADDRESS:
      activityInfo = parseLandChestStakingTransaction(tx, activityInfo);
      break;
    case LAND_CHEST_SALE_SC_ADDRESS:
      activityInfo = parseLandChestSaleTransaction(tx, activityInfo);
      break;
    case LAND_CHEST_OPENING_SC_ADDRESS:
      activityInfo = parseLandChestOpeningTransaction(tx, activityInfo);
      break;
    default:
      if (KOSON_STAKING_POOL_SC_ADDRESSES.includes(actualTxReceiver)) {
        const index = KOSON_STAKING_POOL_SC_ADDRESSES.indexOf(actualTxReceiver);
        activityInfo = parseKosonStakingTransaction(
          tx,
          activityInfo,
          KOSON_STAKING_POOLS[index].name
        );
      }
      break;
  }

  if (activityInfo.message === '') {
    activityInfo.message = 'This needs decoding; Contact support';
  }
  activityInfo.txHash = tx.txHash;
  activityInfo.timestamp = tx.timestamp;
  return activityInfo;
};

const parseLandChestSaleTransaction = (tx: any, activityInfo: any) => {
  const parsedData = Buffer.from(tx.data, 'base64').toString();
  const split = parsedData.split('@');
  const purchaseInfo = split[split.length - 1];
  const chestNonceHex = purchaseInfo.slice(0, 16);
  const quantityHex = purchaseInfo.slice(16);

  const chestNonce = parseInt(chestNonceHex, 16);
  const quantity = parseInt(quantityHex, 16);

  const landChestName = LAND_CHEST_NAMES[chestNonce - 1];

  activityInfo.interaction = ACTIVITY_INTERACTION_TYPES.LandChestSale;
  activityInfo.message = 'Purchased land chests';
  activityInfo.valueMessage = `${quantity} x ${landChestName} Chest${
    quantity > 1 ? 's' : ''
  }`;
  return activityInfo;
};

const parseLandChestOpeningTransaction = (tx: any, activityInfo: any) => {
  activityInfo.message = 'Opened land chests';

  const transfers = tx?.action?.arguments?.transfers;
  if (transfers === undefined) {
    return activityInfo;
  }
  for (let i = 0; i < transfers.length; i++) {
    const nonce = transfers[i].identifier.split('-')[2];
    let chestName = '';
    switch (nonce) {
      case '01':
        chestName = 'Continental';
        break;
      case '02':
        chestName = 'Steepe';
        break;
      case '03':
        chestName = 'Panonic';
        break;
      case '04':
        chestName = 'Pontic';
        break;
      default:
        continue;
    }
    const quantity = transfers[i].value;
    if (activityInfo.valueMessage.length > 0) {
      activityInfo.valueMessage += ', ';
    }
    activityInfo.valueMessage += `${quantity} x ${chestName} Chest${
      quantity > 1 ? 's' : ''
    }`;
  }
  return activityInfo;
};

const parseLandChestStakingTransaction = (tx: any, activityInfo: any) => {
  const parsedData = Buffer.from(tx.data, 'base64').toString();
  const split = parsedData.split('@');
  let message = '';
  let valueMessage = '';
  switch (tx.function) {
    case 'unstakeChests':
    case 'unstakeSfts':
      message = 'Started a land chest unstake process';
      break;
    case 'unstakePlotsSfts':
      message = 'Started a land plot unstake process';
      break;
    case 'claimReward':
      message = 'Claimed land chest staking rewards';
      break;
    case 'claimChestUnstake':
    case 'claimUnstake':
      message = 'Claimed unbonded land chests';
      break;
    case 'claimPlotUnstake':
      message = 'Claimed unbonded land plots';
      break;

    case 'stake':
      message = 'Increased your land chests/plots stake';
      const txTransfers = tx.action.arguments.transfers;
      for (let i = 0; i < txTransfers.length; i++) {
        const nonce = parseInt(txTransfers[i].identifier.split('-')[2], 16);
        let transferMessage;
        if (
          txTransfers[i].identifier.includes(OLD_LAND_PLOT_TOKEN_IDENTIFIER)
        ) {
          transferMessage = `${txTransfers[i].value} x ${
            LAND_CHEST_NAMES[5 - nonce]
          } Plot${txTransfers[i].value !== '1' ? 's' : ''}`;
        } else {
          transferMessage = `${txTransfers[i].value} x ${
            LAND_CHEST_NAMES[nonce - 1]
          } Chest${txTransfers[i].value !== '1' ? 's' : ''}`;
        }
        if (valueMessage !== '') {
          transferMessage = ', ' + transferMessage;
        }
        valueMessage += transferMessage;
      }
      break;
  }
  activityInfo.interaction = ACTIVITY_INTERACTION_TYPES.LandChestStaking;
  activityInfo.message = message;
  activityInfo.valueMessage = valueMessage;
  return activityInfo;
};

const parseNftStakingTransaction = (tx: any, activityInfo: any) => {
  const parsedData = Buffer.from(tx.data, 'base64').toString();
  const split = parsedData.split('@');
  let message = '';
  let valueMessage = '';
  switch (tx.function) {
    case 'claimReward':
      message = 'Claimed soul staking rewards';
      break;
    case 'stakeNfts':
      message = 'Increased your soul stake';
      const txTransfers = tx.action.arguments.transfers;
      for (let i = 0; i < txTransfers.length; i++) {
        const nonce = parseInt(txTransfers[i].identifier.split('-')[2], 16);
        const soulName = getNftName(txTransfers[i].ticker, nonce);
        let transferMessage = `${soulName} ${
          txTransfers[i].value !== '1' ? 's' : ''
        }`;
        if (valueMessage !== '') {
          transferMessage = ', ' + transferMessage;
        }
        valueMessage += transferMessage;
      }
      valueMessage = 'Added ' + valueMessage;
      break;
    case 'unstakeNfts':
      message = 'Started a soul unstake process';
      break;
    case 'claimUnstake':
      message = 'Claimed unbonded souls';
      break;
    default:
      // console.log('Nft staking missing mapping', tx.function, tx.txHash);
      break;
  }
  activityInfo.interaction = ACTIVITY_INTERACTION_TYPES.NftStaking;
  activityInfo.message = message;
  activityInfo.valueMessage = valueMessage;
  return activityInfo;
};

const parseVestingTransaction = (tx: any, activityInfo: any) => {
  let message = '';
  let valueMessage = '';
  switch (tx.function) {
    case 'buyPresale':
      message = 'Purchased seed round $KOSON';
      valueMessage = `Spent ${new BigNumber(tx.value)
        .div(new BigNumber(10).pow(18))
        .toNumber()
        .toLocaleString(undefined, toLocaleStringOptions)} EGLD`;
      break;
    case 'claimKoson':
    case 'claimOffset':
      message = 'Claimed unlocked vesting $KOSON';
      break;
    case 'lockKoson':
      const lockAmount = new BigNumber(tx.action.arguments.transfers[0].value)
        .div(new BigNumber(10).pow(18))
        .toNumber();
      message = 'Participated in the $KOSON vesting updates event';
      valueMessage = `Locked ${lockAmount.toLocaleString(
        undefined,
        toLocaleStringOptions
      )} $KOSON (10% of total vested)`;
      break;
    default:
      // console.log('vesting missing mapping', tx.function, tx.txHash);
      break;
  }
  activityInfo.interaction = ACTIVITY_INTERACTION_TYPES.Vesting;
  activityInfo.message = message;
  activityInfo.valueMessage = valueMessage;
  return activityInfo;
};

const parseKosonStakingTransaction = (
  tx: any,
  activityInfo: any,
  poolName: string
) => {
  let message; // = `Increased ${poolName} pool stake`;
  let valueMessage; // = '123.45 KOSON';
  switch (tx.function) {
    case 'stake': {
      const amount = new BigNumber(tx.action.arguments.transfers[0].value)
        .div(new BigNumber(10).pow(18))
        .toNumber();
      message = `Increased ${poolName} stake`;
      valueMessage = `+${amount.toLocaleString(
        undefined,
        toLocaleStringOptions
      )} $KOSON`;
      break;
    }
    case 'claim':
      message = `Claimed rewards from ${poolName} pool`;
      break;
    case 'unstake': {
      const amount = new BigNumber(tx.action.arguments.transfers[0].value)
        .div(new BigNumber(10).pow(18))
        .toNumber();
      message = `Decreased ${poolName} stake`;
      valueMessage = `-${amount.toLocaleString(
        undefined,
        toLocaleStringOptions
      )} $KOSON`;
      break;
    }
    default:
      // console.log('Koson staking missing mapping', tx.function, tx.txHash);
      break;
  }
  activityInfo.interaction = ACTIVITY_INTERACTION_TYPES.KosonStaking;
  activityInfo.message = message;
  activityInfo.valueMessage = valueMessage;
  return activityInfo;
};

const parseMarketplaceTransaction = (tx: any, activityInfo: any) => {
  let message = '';
  let valueMessage = '';
  switch (tx.function) {
    case 'auctionToken':
      const txArgs = tx.action.arguments;
      const nonce = parseInt(txArgs.transfers[0].identifier.split('-')[2], 16);
      const soulName = getNftName(txArgs.transfers[0].ticker, nonce);
      const minBid = new BigNumber(txArgs.functionArgs[0], 16).div(
        new BigNumber(10).pow(18)
      );
      const maxBid = new BigNumber(txArgs.functionArgs[1], 16).div(
        new BigNumber(10).pow(18)
      );

      let transferMessage = `${soulName}`;

      if (minBid.isEqualTo(maxBid)) {
        transferMessage += ` listed for buyout at ${minBid
          .toNumber()
          .toLocaleString(undefined, toLocaleStringOptions)} EGLD`;
      } else {
        transferMessage += ` listed for auction starting at ${minBid
          .toNumber()
          .toLocaleString(undefined, toLocaleStringOptions)} EGLD`;
      }

      message = 'Listed a soul for sale';
      valueMessage = transferMessage;
      break;
    case 'buyout':
    case 'bid':
      message =
        tx.function === 'buyout'
          ? 'Purchased a soul NFT'
          : 'Bid on a soul NFT auction';
      const amount = new BigNumber(tx.value)
        .div(new BigNumber(10).pow(18))
        .toNumber();
      valueMessage = `Spent ${amount.toLocaleString(
        undefined,
        toLocaleStringOptions
      )} EGLD`;
      break;
    case 'endAuction':
      message = 'Claimed a completed/failed listing';
      break;
    case 'withdraw':
      message = 'Cancelled a listing';
      break;
    default:
      // console.log('Marketplace missing mapping', tx.function, tx.txHash);
      break;
  }
  activityInfo.interaction = ACTIVITY_INTERACTION_TYPES.Marketplace;
  activityInfo.message = message;
  activityInfo.valueMessage = valueMessage;
  return activityInfo;
};

const parseSwapsOfferTransaction = (tx: any, activityInfo: any) => {
  let message = '';
  switch (tx.function) {
    case 'makeNftOffer':
      message = 'Made a swap offer';
      break;
    case 'depositSwapContent':
      message = 'Deposited swap offer content';
      break;
    case 'acceptOffer':
      message = 'Accepted a swap offer';
      break;
    case 'cancelOffer':
      message = 'Cancelled a swap offer';
      break;
    default:
      // console.log('Swaps missing mapping', tx.function, tx.txHash);
      break;
  }
  activityInfo.interaction = ACTIVITY_INTERACTION_TYPES.NftSwaps;
  activityInfo.message = message;
  return activityInfo;
};

const parseMintingOrMigrateTransaction = (tx: any, activityInfo: any) => {
  let message = '';
  let valueMessage = '';
  switch (tx.function) {
    case 'buy':
      const quantity = new BigNumber(tx.value)
        .div(new BigNumber(10).pow(18))
        .div(0.5)
        .toNumber();
      message = 'Minted origin souls';
      valueMessage = `${quantity} x Origin Souls`;
      break;
    case 'migrate':
      message = 'Migrated an Origin soul';
      break;
    default:
      // console.log('Mint or migrate missing mapping', tx.function, tx.txHash);
      break;
  }
  activityInfo.interaction = ACTIVITY_INTERACTION_TYPES.OriginMintOrMigrate;
  activityInfo.message = message;
  activityInfo.valueMessage = valueMessage;
  return activityInfo;
};

const parseSummoningTransaction = (tx: any, activityInfo: any) => {
  let message = '';
  let valueMessage = '';
  switch (tx.function) {
    case 'depositSummoningNfts':
      const txTransfers = tx.action.arguments.transfers;
      const isCommonSummoning = txTransfers.length === 2;
      message = `Created a ${
        isCommonSummoning ? 'regular' : 'Death soul'
      } summoning process`;
      for (let i = 0; i < txTransfers.length; i++) {
        const nonce = parseInt(txTransfers[i].identifier.split('-')[2], 16);
        const soulName = getNftName(txTransfers[i].ticker, nonce);
        let transferMessage = `${soulName} ${
          txTransfers[i].value !== '1' ? 's' : ''
        }`;
        if (valueMessage !== '') {
          transferMessage = ', ' + transferMessage;
        }
        valueMessage += transferMessage;
      }
      valueMessage = 'Deposited ' + valueMessage;
      break;
    case 'depositOffering':
      const depositAmount = new BigNumber(
        tx.action.arguments.transfers[0].value
      )
        .div(new BigNumber(10).pow(18))
        .toNumber();
      message = 'Deposited $KOSON offering';
      valueMessage = `${depositAmount.toLocaleString(
        undefined,
        toLocaleStringOptions
      )} $KOSON`;
      break;
    case 'startSummoning':
      const summoningFee = new BigNumber(tx.value)
        .div(new BigNumber(10).pow(18))
        .toNumber();
      message = 'Started the summoning process';
      valueMessage = `Paid ${summoningFee.toLocaleString(
        undefined,
        toLocaleStringOptions
      )} EGLD in summoning fees`;
      break;
    case 'fulfillBendisCall':
      const bendisSummoningFee = new BigNumber(
        tx.action.arguments.transfers[0].value
      )
        .div(new BigNumber(10).pow(18))
        .toNumber();
      message = 'Started the summoning process';
      valueMessage = `Paid ${bendisSummoningFee.toLocaleString(
        undefined,
        toLocaleStringOptions
      )} $KOSON in summoning fees`;
      break;
    case 'cancelSummoning':
      message = 'Cancelled the summoning process';
      break;
    case 'claim':
      message = 'Claimed the summoned soul';
      break;
    case 'burn':
      const txTransfer = new BigNumber(tx.action.arguments.transfers[0].value)
        .div(new BigNumber(10).pow(18))
        .toNumber();
      message = 'You have burned $KOSON';
      valueMessage = `${txTransfer} $KOSON burned`;
      break;
    default:
      message = 'a';
      valueMessage = 'abcc';
      // console.log('Summoning missing mapping', tx.function, tx.txHash);
      break;
  }
  activityInfo.interaction = ACTIVITY_INTERACTION_TYPES.Summoning;
  activityInfo.message = message;
  activityInfo.valueMessage = valueMessage;
  return activityInfo;
};
