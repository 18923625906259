import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { KosonSymbol } from 'components/KosonSymbol';
import { ReactComponent as ElrondLogo } from './../../../assets/images/aoz/egld-symbol.svg';
import { ReactComponent as KossonsSymbolBasileus } from './../../../assets/images/aoz/Koson_Basileus.svg';
import { ReactComponent as KossonsSymbolComati } from './../../../assets/images/aoz/Koson_Comati.svg';
import { ReactComponent as KossonsSymbolPileati } from './../../../assets/images/aoz/Koson_Pileati.svg';
import { ReactComponent as KossonsSymbolPlebea } from './../../../assets/images/aoz/Koson_Plebea.svg';
import { ReactComponent as KossonsSymbolStrategos } from './../../../assets/images/aoz/Koson_Strategos.svg';
import { ReactComponent as KossonsSymbolTarabostes } from './../../../assets/images/aoz/Koson_Tarabostes.svg';
import './style.css';

const SimpleStatsCard = ({
  title,
  value,
  icon,
  badgeText,
  navUrl,
  showKosonSymbol,
  showEgldSymbol,
  imgUrl,
  backgroundColor,
  kosonStakingIcon,
  valueFontSize,
  iconColor
}: {
  title: string;
  value: string;
  icon?: any;
  badgeText?: string;
  navUrl?: string;
  showKosonSymbol?: boolean;
  showEgldSymbol?: boolean;
  imgUrl?: string;
  backgroundColor?: string;
  kosonStakingIcon?: number;
  valueFontSize?: string;
  iconColor?: string;
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    if (navUrl) {
      // const path = window.location.origin;
      // window.open(`${path}${navUrl}`, '_blank');
      navigate(navUrl);
    }
  };

  const getKosonStakingIcon = () => {
    if (!kosonStakingIcon) return <KosonSymbol tokenIdentifier='' />;
    switch (kosonStakingIcon) {
      case 0:
        return <KossonsSymbolPlebea />;
      case 1:
        return <KossonsSymbolComati />;
      case 2:
        return <KossonsSymbolPileati />;
      case 3:
        return <KossonsSymbolTarabostes />;
      case 4:
        return <KossonsSymbolStrategos />;
      case 5:
        return <KossonsSymbolBasileus />;
    }
  };

  return (
    <div
      className='stats-card card'
      style={{
        cursor: navUrl ? 'pointer' : 'auto',
        background: backgroundColor ?? ''
      }}
      onClick={handleNavigation}
    >
      <div className='card-header bgl-warning text-warning'>
        <span className='text-white'>{title}</span>
        {icon && iconColor && (
          <FontAwesomeIcon icon={icon} style={{ color: iconColor }} />
        )}
        {icon && !iconColor && <FontAwesomeIcon icon={icon} />}
        {imgUrl && <img src={imgUrl} className='img-fluid rounded-circle' />}
        {kosonStakingIcon && getKosonStakingIcon()}
      </div>
      <div className='card-body ms-2'>
        {!showKosonSymbol && !showEgldSymbol && (
          <h4 className='mb-0' style={{ fontSize: valueFontSize ?? '' }}>
            {value}
          </h4>
        )}
        {showKosonSymbol && !showEgldSymbol && (
          <h4 className='mb-0'>
            {value}{' '}
            <KosonSymbol
              tokenIdentifier=''
              // style={{ width: '20px', height: '44px', marginLeft: '5px' }}
            />
          </h4>
        )}
        {!showKosonSymbol && showEgldSymbol && (
          <h4 className='mb-0'>
            {value} <ElrondLogo style={{ width: '20px', marginLeft: '5px' }} />
          </h4>
        )}
      </div>
      <div className='card-footer p-1 border-0 mb-1'>
        {badgeText && (
          <span className='badge bg-warning ms-4 mb-2'>{badgeText}</span>
        )}
      </div>
    </div>
  );
};

export default SimpleStatsCard;
