import React from 'react';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSummoningCounts } from 'api/backendRequests';
import {
  ALL_NFT_COLLECTIONS,
  OLD_KOSON_TICKER,
  LAND_CHEST_TOKEN_IDENTIFIER,
  ORIGIN_SOULS_TOKEN_IDENTIFIERS,
  STAKED_KOSON_TOKEN_IDENTIFIERS
} from 'config/dapp-config';
import Alert from './Alert';

const Verify = () => {
  const icon: any = faShieldCheck;
  const [identifierToCheck, setIdentifierToCheck] = React.useState('');
  const [alertToDisplay, setAlertToDisplay] = React.useState<JSX.Element>();

  const handleVerify = async () => {
    const inputSplit = identifierToCheck.split('-');
    switch (inputSplit.length) {
      case 2:
        // collection identifier
        if (identifierToCheck === OLD_KOSON_TICKER) {
          setAlertToDisplay(
            <Alert
              strongMessage={'Validation successful!'}
              message={`The ${identifierToCheck} identifier is the legit Age Of Zalmoxis $KOSON token identifier`}
              type={'success'}
            />
          );
          break;
        }
        if (ALL_NFT_COLLECTIONS.includes(identifierToCheck)) {
          setAlertToDisplay(
            <Alert
              strongMessage={'Validation successful!'}
              message={`The ${identifierToCheck} collection identifier is part of the Age Of Zalmoxis NFT collections`}
              type={'success'}
            />
          );
        } else {
          setAlertToDisplay(
            <Alert
              strongMessage={'Validation failed!'}
              message={`The ${identifierToCheck} collection identifier is NOT part of the Age Of Zalmoxis NFT collections`}
              type={'danger'}
            />
          );
        }
        break;
      case 3:
        // NFT identifier
        await handleNftIdentifierCheck(inputSplit);
        break;
      default:
        setAlertToDisplay(
          <Alert
            strongMessage={'Error!'}
            message={`${identifierToCheck} is not a valid MultiversX collection or NFT identifier`}
            type={'danger'}
          />
        );
        break;
    }
  };

  const handleNftIdentifierCheck = async (inputSplit: string[]) => {
    const collection = `${inputSplit[0]}-${inputSplit[1]}`;
    // make sure collection is valid
    if (!ALL_NFT_COLLECTIONS.includes(collection)) {
      setAlertToDisplay(
        <Alert
          strongMessage={'Validation failed!'}
          message={`The ${identifierToCheck} NFT is NOT part of the Age Of Zalmoxis NFT collections`}
          type={'danger'}
        />
      );
      return;
    }

    // check land chests first so we don't load summoning info for them
    if (collection === LAND_CHEST_TOKEN_IDENTIFIER) {
      setAlertToDisplay(
        <Alert
          strongMessage={'Validation successful!'}
          message={`The ${identifierToCheck} NFT is a valid Age Of Zalmoxis Land Chest`}
          type={'success'}
        />
      );
      return;
    }

    // check staked koson ticker before NFT checks for the same reasons
    if (STAKED_KOSON_TOKEN_IDENTIFIERS.includes(collection)) {
      setAlertToDisplay(
        <Alert
          strongMessage={'Validation successful!'}
          message={`The ${identifierToCheck} identifier is a valid Age Of Zalmoxis $KOSON staking batch`}
          type={'success'}
        />
      );
      return;
    }

    // fetch NFT summoning info
    const summoningCounts = await getSummoningCounts([identifierToCheck]);
    if (!summoningCounts.success) {
      setAlertToDisplay(
        <Alert
          strongMessage={'Summoning validation failed!'}
          message={`The ${identifierToCheck} NFT is part of the Age Of Zalmoxis NFT collections but an error has occured while fetching summoning info`}
          type={'warning'}
        />
      );
      return;
    }

    const summoningCountsArray = summoningCounts.data[identifierToCheck];
    setAlertToDisplay(
      <Alert
        strongMessage={'Validation successful!'}
        message={`The ${identifierToCheck} NFT is a valid Age Of Zalmoxis soul NFTs, with ${
          summoningCountsArray[0]
        }/6 regular summonings done${
          summoningCountsArray[1] === null
            ? ''
            : ' and ' + summoningCountsArray[1] + '/1 special summoning done'
        }`}
        type={'success'}
      />
    );
  };

  //   const successAlert = (strongMsg: string, regularMsg: string) => {
  //     const alertIcon: any = faCircleCheck;
  //     return (
  //       <div className='alert alert-outline-success alert-dismissible fade show'>
  //         <button
  //           type='button'
  //           className='btn-close'
  //           data-bs-dismiss='alert'
  //           aria-label='btn-close'
  //         ></button>{' '}
  //         <FontAwesomeIcon icon={alertIcon} className='me-2' />
  //         {strongMsg && <strong>{strongMsg}</strong>} {regularMsg}
  //       </div>
  //     );
  //   };

  //   const failAlert = (strongMsg: string, regularMsg: string) => {
  //     const alertIcon: any = faCircleXmark;
  //     return (
  //       <div className='alert alert-outline-danger alert-dismissible fade show'>
  //         <button
  //           type='button'
  //           className='btn-close'
  //           data-bs-dismiss='alert'
  //           aria-label='btn-close'
  //         ></button>
  //         <FontAwesomeIcon icon={alertIcon} className='me-2' />
  //         {strongMsg && <strong>{strongMsg}</strong>} {regularMsg}
  //       </div>
  //     );
  //   };

  //   const warningAlert = (strongMsg: string, regularMsg: string) => {
  //     const alertIcon: any = faCircleExclamation;
  //     return (
  //       <div className='alert alert-outline-warning alert-dismissible fade show'>
  //         <button
  //           type='button'
  //           className='btn-close'
  //           data-bs-dismiss='alert'
  //           aria-label='btn-close'
  //         ></button>
  //         <FontAwesomeIcon icon={alertIcon} className='me-2' />
  //         {strongMsg && <strong>{strongMsg}</strong>} {regularMsg}
  //       </div>
  //     );
  //   };
  return (
    <div className='row'>
      <div className='col-lg-12 col-md-12 col-sm-12'>{alertToDisplay}</div>
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <div className='card'>
          <div className='card-body text-center ai-icon text-primary row'>
            <div className='col-lg-12 col-sm-12 text-center'>
              <h3 className='my-2'>
                <b>Verify</b>
              </h3>
            </div>
            <div className='col-lg-12 col-sm-12 text-center'>
              <h6>
                Input an identifier and make sure the collection is part of the
                Age of Zalmoxis NFTs
              </h6>
              <small>
                Works with souls, land chests and staked $KOSON batches
              </small>
            </div>
            <div className='col-lg-3 col-md-3 col-sm-3'></div>
            <div className='col-lg-6 col-md-6 col-sm-6 text-center mt-5'>
              <input
                type='text'
                className='form-control input-rounded mb-2'
                placeholder={`${ORIGIN_SOULS_TOKEN_IDENTIFIERS[1]}-01 or ${ORIGIN_SOULS_TOKEN_IDENTIFIERS[1]} or ${LAND_CHEST_TOKEN_IDENTIFIER}-01 etc.`}
                onChange={(e) => setIdentifierToCheck(e.target.value)}
              />
            </div>
            <div className='col-lg-4 col-md-4 col-sm-4'></div>
            <div className='col-lg-4 col-md-4 col-sm-4 text-center'>
              <button
                className='btn my-2 btn-primary btn-block px-4'
                onClick={handleVerify}
              >
                <FontAwesomeIcon icon={icon} className='me-2' />
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
