import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import Activity from 'components/Activity';
import NftStaking from 'components/Containers/NftStaking';
import { PRIMORDIAL_KOSON_TOKEN_IDENTIFIER } from 'config/dapp-config';
import { useLandPlotStaking } from 'hooks/contracts/landPlotStaking/useLandPlotStaking';
import { selectLandPlots } from 'store/slices/kosonv2/account';
import { selectStakedLandPlots } from 'store/slices/kosonv2/land-plot-staking-pool';
import { ACTIVITY_INTERACTION_TYPES } from 'types';
import { StakeableAssetType } from 'types/store';
import LandPlotStakingCardInfo from './CardInfo';

const LandPlotStaking = () => {
  const stakedLandPlots = useSelector(selectStakedLandPlots);
  const walletLandPlots = useSelector(selectLandPlots);

  const { stakePlots, unstakePlots } = useLandPlotStaking();

  const handleStakeNfts = async (
    _nfts: StakeableAssetType[],
    quantities: { [identifier: string]: number }
  ) => {
    const payments = walletLandPlots.map((nft) => ({
      tokenIdentifier: nft.tokenIdentifier,
      nonce: nft.nonce,
      amount:
        quantities !== undefined && quantities[nft.fullIdentifier] > 0
          ? quantities[nft.fullIdentifier].toString()
          : '1'
    }));

    if (payments.length === 0) {
      return;
    }

    await stakePlots(payments);
  };

  const handleUnstake = async (
    _nftsToUnstake: StakeableAssetType[],
    quantitiesToUnstake: { [identifier: string]: number },
    unstakeFee: BigNumber
  ) => {
    const unstakeRequest = stakedLandPlots
      .filter(
        (slp) =>
          quantitiesToUnstake[slp.fullIdentifier] !== undefined &&
          quantitiesToUnstake[slp.fullIdentifier] > 0
      )
      .map((nft) => ({
        tokenIdentifier: nft.tokenIdentifier,
        nonce: nft.nonce,
        amount: quantitiesToUnstake[nft.fullIdentifier].toString()
      }));

    const feePayment = {
      tokenIdentifier: PRIMORDIAL_KOSON_TOKEN_IDENTIFIER,
      amount: unstakeFee.multipliedBy(1.02).toString(10),
      nonce: 0
    };

    if (unstakeRequest.length === 0) {
      return;
    }

    await unstakePlots(unstakeRequest, feePayment);
  };

  return (
    <>
      <div className='row'>
        <LandPlotStakingCardInfo />
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
          <NftStaking
            title={'Land Plot Staking'}
            stakedItems={stakedLandPlots}
            availableItems={walletLandPlots}
            handleStake={handleStakeNfts}
            handleUnstake={handleUnstake}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
          <Activity interaction={ACTIVITY_INTERACTION_TYPES.LandChestStaking} />
        </div>
      </div>
    </>
  );
};

export default LandPlotStaking;
