import React from 'react';
import BigNumber from 'bignumber.js';
import ActionAlert from 'components/Alerts/ActionAlert';
import { MARKETPLACE_SC_ADDRESS } from 'config/dapp-config';
import { Web3Context } from 'contexts/Web3Context';
import {
  addr2Hex,
  int2hex,
  str2hex
} from 'contexts/Web3Context/helpers/generalUtils';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';
import { AccountNft } from 'types';

const ListNftModal = ({ nft }: { nft?: AccountNft }) => {
  const [isAuction, setIsAuction] = React.useState(true);
  const [instantBuyPrice, setInstantBuyPrice] = React.useState<number>(0);
  const [auctionStartPrice, setAuctionStartPrice] = React.useState<number>(0);
  const [listingValidityTime, setListingValidityTime] = React.useState('hour');
  const context = React.useContext(Web3Context);

  const { address } = useGetAccountInfoWrapper();

  const handleNftListing = async () => {
    if (!nft) {
      return;
    }
    let lowerRange = new BigNumber(auctionStartPrice);
    const upperRange = new BigNumber(instantBuyPrice);

    if (lowerRange.isNaN()) {
      if (isAuction) {
        window.alert('Invalid auction starting price');
        return;
      } else {
        lowerRange = upperRange;
      }
    }

    if (upperRange.isNaN()) {
      window.alert(
        `Invalid ${
          isAuction ? 'auction starting price' : 'buyout listing price'
        }`
      );
      return;
    }

    if (lowerRange.isGreaterThan(upperRange)) {
      if (isAuction) {
        window.alert(
          'Auction starting price cannot be larger than the instant buy price'
        );
        return;
      } else {
        lowerRange = upperRange;
      }
    }

    if (lowerRange.isZero() && isAuction) {
      window.alert('The auction cannot start at 0 EGLD');
      return;
    }
    if (upperRange.isZero()) {
      window.alert('Cannot list an NFT for 0 EGLD instant buy price');
      return;
    }

    if (!isAuction && !lowerRange.isEqualTo(upperRange)) {
      lowerRange = upperRange;
    }

    if (!address) {
      window.alert('Something went wrong');
      return;
    }

    const esdtNftTransfer = `ESDTNFTTransfer@${str2hex(
      nft.collection
    )}@${int2hex(nft.nonce)}@01@${addr2Hex(MARKETPLACE_SC_ADDRESS)}`;
    const endpoint = `${str2hex('auctionToken')}`;
    let minPrice = lowerRange
      .multipliedBy(new BigNumber(10).pow(18))
      .toString(16);
    if (minPrice.length % 2 === 1) {
      minPrice = '0' + minPrice;
    }
    let maxPrice = upperRange
      .multipliedBy(new BigNumber(10).pow(18))
      .toString(16);
    if (maxPrice.length % 2 === 1) {
      maxPrice = '0' + maxPrice;
    }

    const deadline = int2hex(getDeadline());
    const acceptedPaymentToken = str2hex('EGLD');

    const data = `${esdtNftTransfer}@${endpoint}@${minPrice}@${maxPrice}@${deadline}@${acceptedPaymentToken}`;
    await context.sendTransaction(address, 20_000_000, data);
  };

  const getDeadline = () => {
    const now = parseInt((new Date().getTime() / 1000).toString());
    let offset = 0;
    switch (listingValidityTime) {
      case 'hour':
        offset = 3600;
        break;
      case 'day':
        offset = 3600 * 24;
        break;
      case 'week':
        offset = 3600 * 24 * 7;
        break;
      case 'month':
        offset = 3600 * 24 * 30;
        break;
    }
    return now + offset;
  };

  const auctionListButton = () => {
    return !new BigNumber(instantBuyPrice).isZero() &&
      !new BigNumber(auctionStartPrice).isZero() &&
      new BigNumber(instantBuyPrice).isGreaterThanOrEqualTo(
        new BigNumber(auctionStartPrice)
      ) ? (
      <button
        type='button'
        className='btn btn-primary'
        onClick={handleNftListing}
        data-bs-dismiss='modal'
      >
        List
      </button>
    ) : (
      <button type='button' className='btn btn-primary disabled'>
        List
      </button>
    );
  };

  const buyoutListButton = () => {
    return !new BigNumber(instantBuyPrice).isZero() ? (
      <button
        type='button'
        className='btn btn-primary'
        onClick={handleNftListing}
        data-bs-dismiss='modal'
      >
        List
      </button>
    ) : (
      <button type='button' className='btn btn-primary disabled'>
        List
      </button>
    );
  };

  const inputNumberValidator = (value: string, action: (v: number) => void) => {
    let v = parseFloat(value);
    if (v < 0) {
      v = 0;
    }
    action(v);
  };

  return (
    <div className='modal-dialog modal-dialog-centered' role='document'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>List {nft?.name ?? 'N/A'} for sale</h5>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
          ></button>
        </div>
        <div className='modal-body'>
          <form>
            <div className='row'>
              <div className='col-12'>
                <strong className='ms-2'>Listing type</strong>
              </div>
              <div className='col-6 mt-2'>
                <input
                  type='radio'
                  className='form-check-input ms-2'
                  id='set-listing-auction-radio'
                  name='listing-type-radio'
                  defaultChecked
                  onClick={() => setIsAuction(true)}
                />
                <label className='form-check-label mt-1'>Auction</label>
              </div>
              <div className='col-6 mt-2'>
                <input
                  type='radio'
                  className='form-check-input ms-2'
                  id='set-listing-buyout-radio'
                  name='listing-type-radio'
                  onClick={() => setIsAuction(false)}
                />
                <label className='form-check-label mt-1'>Buyout</label>
              </div>
              <div className='col-12 mt-3'>
                {isAuction && (
                  <>
                    <strong className='ms-2'>
                      Auction starting price (EGLD)
                    </strong>
                    <input
                      type={'number'}
                      className='form-control input-rounded mb-2'
                      placeholder='9.8765'
                      min={0}
                      onChange={(e) =>
                        inputNumberValidator(
                          e.target.value,
                          setAuctionStartPrice
                        )
                      }
                    />
                    {new BigNumber(auctionStartPrice).isGreaterThan(
                      new BigNumber(instantBuyPrice)
                    ) && (
                      <>
                        <strong className='text-danger ms-3 mb-2'>
                          Auction starting price cannot be larger than the
                          instant buy price
                        </strong>
                        <br />
                      </>
                    )}

                    {new BigNumber(auctionStartPrice).isZero() && (
                      <>
                        <strong className='text-danger ms-3 mb-2'>
                          Starting price cannot be zero
                        </strong>
                        <br />
                      </>
                    )}
                  </>
                )}
                <strong className='ms-2'>
                  {isAuction ? 'Instant buy price (EGLD)' : 'Price (EGLD)'}
                </strong>
                <input
                  type={'number'}
                  className='form-control input-rounded mb-2'
                  placeholder='9.8765'
                  min={0}
                  onChange={(e) =>
                    inputNumberValidator(e.target.value, setInstantBuyPrice)
                  }
                />
                {new BigNumber(instantBuyPrice).isZero() && (
                  <>
                    <strong className='text-danger ms-3 mb-2'>
                      {isAuction
                        ? 'Starting price cannot be zero'
                        : 'Listing price cannot be zero'}
                    </strong>
                    <br />
                  </>
                )}
              </div>
              <div className='col-12 mt-3'>
                <strong className='ms-2'>Listing expiring time</strong>
                <br />
                <select
                  id='expire-period'
                  className='ms-2 mt-2'
                  onChange={(e) => setListingValidityTime(e.target.value)}
                >
                  <option value='hour' selected>
                    1 hour
                  </option>
                  <option value='day'>1 day</option>
                  <option value='week'>1 week</option>
                  <option value='month'>1 month</option>
                </select>
              </div>
              <div className='mt-5'>
                <ActionAlert
                  alertText={`The NFT ${nft?.name} with ${
                    nft?.regularSummoningCount
                  }/6 summonings ${
                    nft?.specialSummoningCount !== undefined
                      ? ' and ' +
                        nft.specialSummoningCount +
                        '/1 Death Soul summonings'
                      : ''
                  } will be listed as ${
                    isAuction
                      ? 'an auction starting from ' +
                        auctionStartPrice +
                        ' EGLD, purchasable for ' +
                        instantBuyPrice
                      : 'a buyout for the price of ' + instantBuyPrice
                  } EGLD, and the listing will be valid for one ${listingValidityTime}`}
                  actionText={''}
                  action={async () => {
                    /* nothing */
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-danger light'
            data-bs-dismiss='modal'
          >
            Close
          </button>
          {isAuction ? auctionListButton() : buyoutListButton()}
        </div>
      </div>
    </div>
  );
};

export default ListNftModal;
