import React, { useContext } from 'react';
import './style.css';
import {
  faArrowRightFromBracket,
  faBoltLightning
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Web3Context } from 'contexts/Web3Context';
import { NAVBAR_MENU_ITEMS, NavbarMenuItem, routeNames } from 'routes';
import { ReactComponent as BitMartLogo } from './../../../assets/images/aoz/bitmart-icon.svg';

interface SidebarProps {
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, toggleCollapse }) => {
  const isLoggedIn = useGetIsLoggedIn();
  const navigate = useNavigate();
  const { priceState } = useContext(Web3Context);
  // const isAdmin = adminAddresses.includes(accountState?.account.address ?? '');

  const handleCloseNavbar = () => {
    const width = window.innerWidth;
    const isMobile = width <= 768;

    if (isMobile && !isCollapsed) {
      toggleCollapse();
    }
  };

  const navbarMenuItem = (item: NavbarMenuItem, index: number) => {
    if (item.isProtectedRoute && !isLoggedIn) {
      return null;
    }

    const isExactMatch = location.pathname === item.route;
    const isParentOfCurrentRoute = item.subMenuItems?.some((subItem) =>
      location.pathname.startsWith(subItem.route)
    );

    return (
      <>
        <div
          onClick={() => {
            handleCloseNavbar();
            navigate(item.route);
          }}
          className={`w-100 align-items-center menu-item ${
            isExactMatch ? 'active' : ''
          }`}
          key={`sidenav-menu-item-${index}-${item.display}`}
          data-tip={item.display}
        >
          <FontAwesomeIcon icon={item.icon} className='link-icon' />{' '}
          <span>{item.display}</span>
        </div>
        {item.subMenuItems?.length > 0 &&
          (isExactMatch || isParentOfCurrentRoute) && (
            <ul className='list-unstyled hide-on-collapsed'>
              {item.subMenuItems.map((smi: NavbarMenuItem, i: number) =>
                smi.isProtectedRoute && !isLoggedIn ? null : (
                  <li
                    className={`menu-item sub ${
                      location.pathname === smi.route ? 'active' : ''
                    }`}
                    key={`sidenav-menu-item-${index}-submenu-entry-${i}`}
                    onClick={() => {
                      handleCloseNavbar();
                      navigate(smi.route);
                    }}
                  >
                    <FontAwesomeIcon icon={smi.icon} className='link-icon' />{' '}
                    <span>{smi.display}</span>
                  </li>
                )
              )}
            </ul>
          )}
      </>
    );
  };

  return (
    <div
      className={`sidebar text-white d-flex flex-column align-items-${
        isCollapsed ? 'center' : 'start'
      } py-3 px-2 ${isCollapsed ? 'collapsed' : ''}`}
      id='sidebar'
    >
      <div className='w-100'>
        <div className='exchange-price d-none'>
          <BitMartLogo className='exchange-logo' />
          <a
            href='https://www.bitmart.com/trade/en-US?symbol=KOSON_USDT'
            className='buy-koson-link text-center'
            target='_blank'
            rel='noreferrer'
          >
            Koson price: ${priceState?.kosonPrice}
          </a>
        </div>
        {NAVBAR_MENU_ITEMS.map((item, index) =>
          item.subMenuItems?.length > 0
            ? navbarMenuItem(item, index)
            : navbarMenuItem(item, index)
        )}
      </div>
      <div className='connect-button'>
        <div
          onClick={() => {
            if (isLoggedIn) {
              logout();
            } else {
              navigate(routeNames.unlock);
            }
            handleCloseNavbar();
          }}
          className='d-flex align-items-center menu-item'
        >
          <FontAwesomeIcon
            icon={
              (isLoggedIn ? faArrowRightFromBracket : faBoltLightning) as any
            }
            data-tip={isLoggedIn ? 'Disconnect' : 'Connect'}
            className='link-icon'
          />
          <span className='nav-text'>
            {isLoggedIn ? 'Disconnect' : 'Connect'}
          </span>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
};

export default Sidebar;
