import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Web3Context } from 'contexts/Web3Context';
import { selectLoadingStatus } from 'store/slices/kosonv2';
import { Loading } from './Loading';

export const LoadingGuard = ({ children }: { children: React.ReactNode }) => {
  const { vestingState } = useContext(Web3Context);
  const { stillLoading } = useSelector(selectLoadingStatus)(
    vestingState?.isLoading ?? true
  );

  return (
    <>
      {stillLoading.length === 0 ? (
        children
      ) : (
        <Loading coverFullPage={true} loadingText={stillLoading[0]} />
      )}
    </>
  );
};
