import React from 'react';
import {
  AddressValue,
  Address,
  U64Value,
  U32Value
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { VESTING_SC_ADDRESS } from 'config/dapp-config';
import {
  getSmartContract,
  Parser,
  Provider
} from 'contexts/Web3Context/helpers/getScObj';

const useVestingInfo = ({ address }: { address: string }) => {
  const [remainingVested, setRemainingVested] = React.useState(0);
  const [totalVested, setTotalVested] = React.useState(0);
  const [claimedRounds, setClaimedRounds] = React.useState(0);
  const [nextClaimIn, setNextClaimIn] = React.useState('N/A');
  const [pendingClaimableAmount, setPendingClaimableAmount] = React.useState(0);
  const [canClaim, setCanClaim] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    refreshState().then(() => {
      setIsLoading(false);
    });
  }, []);

  const refreshState = async () => {
    const lastClaimed = await getClaimedRounds(address);

    const [lremainingVested, ltotalVested, nextRoundClaimTime] =
      await Promise.all([
        getRemainingVested(address),
        getTotalVested(address),
        getVestingRoundClaimTime(lastClaimed + 1)
      ]);

    setRemainingVested(lremainingVested);
    setTotalVested(ltotalVested);
    setClaimedRounds(lastClaimed);
    const claimableInMessage = getNextClaimMessage(nextRoundClaimTime);
    setNextClaimIn(claimableInMessage);
    const isClaimTimeInPast = nextRoundClaimTime < new Date().getTime() / 1000;
    setCanClaim(isClaimTimeInPast);
    if (isClaimTimeInPast) {
      const singleClaimRoundAmount = totalVested / 10;
      setPendingClaimableAmount(singleClaimRoundAmount);
    }
    setIsLoading(false);
  };

  const getNextClaimMessage = (nextRoundClaimTime: number) => {
    let claimableInMessage = '';
    let diff = nextRoundClaimTime - new Date().getTime() / 1000;
    if (diff > 30 * 24 * 3600) {
      const months = Math.floor(diff / (30 * 24 * 3600));
      claimableInMessage += `${months} months`;
      diff -= months * (30 * 24 * 3600);
    } else {
      if (diff > 7 * 24 * 3600) {
        const weeks = Math.floor(diff / (7 * 24 * 3600));
        claimableInMessage += `${weeks} weeks`;
        diff -= weeks * (7 * 24 * 3600);
      }
    }
    if (diff > 24 * 3600) {
      const days = Math.floor(diff / (24 * 3600));
      if (claimableInMessage !== '') {
        claimableInMessage += ', ';
      }
      claimableInMessage += `${days} days`;
      diff -= days * 24 * 3600;
    }
    if (claimableInMessage === '') {
      claimableInMessage = '0 days';
    }
    return claimableInMessage;
  };

  const getRemainingVested = async (userAddress: string) => {
    const addressArg = new AddressValue(new Address(userAddress));

    const contract = await getSmartContract(VESTING_SC_ADDRESS);
    const interaction = contract.methodsExplicit.remainingVested([addressArg]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      return parsedResponse.firstValue
        ?.valueOf()
        .div(new BigNumber(10).pow(18))
        .toNumber();
    }
    return 0;
  };

  const getTotalVested = async (userAddress: string) => {
    const addressArg = new AddressValue(new Address(userAddress));

    const contract = await getSmartContract(VESTING_SC_ADDRESS);
    const interaction = contract.methodsExplicit.totalVested([addressArg]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      return parsedResponse.firstValue
        ?.valueOf()
        .div(new BigNumber(10).pow(18))
        .toNumber();
    }
    return 0;
  };

  const getClaimedRounds = async (userAddress: string) => {
    const addressArg = new AddressValue(new Address(userAddress));

    const contract = await getSmartContract(VESTING_SC_ADDRESS);
    const interaction = contract.methodsExplicit.periodsClaimed([addressArg]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      return parsedResponse.firstValue?.valueOf().toNumber();
    }
    return 0;
  };

  const getVestingRoundClaimTime = async (claimRound: number) => {
    const vestingPeriodArg = new U64Value(new U32Value(claimRound).value);

    const contract = await getSmartContract(VESTING_SC_ADDRESS);
    const interaction = contract.methodsExplicit.vestingPeriod([
      vestingPeriodArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      return parsedResponse.firstValue?.valueOf().toNumber();
    }
    return 0;
  };

  return {
    remainingVested,
    totalVested,
    claimedRounds,
    nextClaimTime: nextClaimIn,
    pendingClaimableAmount,
    canClaim,
    refreshState,
    isLoading
  };
};

export default useVestingInfo;

export interface IVestingInfoType {
  isLoading: boolean;
  remainingVested: number;
  totalVested: number;
  claimedRounds: number;
  nextClaimTime: string;
  pendingClaimableAmount: number;
  canClaim: boolean;
  refreshState: () => Promise<any>;
}
