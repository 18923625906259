import { int2hex } from 'contexts/Web3Context/helpers/generalUtils';
import { formatBigNumberString } from 'utils';
import { Soul } from './types';

export const SoulMigrationLogs = ({
  soulMigrationLog
}: {
  soulMigrationLog: Soul;
}) => {
  return (
    <>
      <div
        className='col-12 mt-3'
        style={{ fontSize: '22px', fontWeight: 'bolder' }}
      >
        Souls{' '}
        <small style={{ fontWeight: 'normal' }}>
          {soulMigrationLog.stakedNfts.length} Staked souls;{' '}
          {soulMigrationLog.unbondingNfts.length} Unbonding souls;{' '}
          {formatBigNumberString(soulMigrationLog.pendingRewards)} KOSON v1
          Pending rewards
        </small>
      </div>
      {soulMigrationLog.stakedNfts.length > 0 && (
        <>
          <div className='col-12 mt-3' style={{ fontSize: '20px' }}>
            Staked Souls
          </div>
          <div className='col-12 mt-1'>
            {soulMigrationLog.stakedNfts.map((stakedNft, index) => (
              <div key={`staked-nfts-logs-${index}`} className='ms-3'>
                {stakedNft.token_id}-{int2hex(parseInt(stakedNft.nonce))}
              </div>
            ))}
          </div>
        </>
      )}
      {soulMigrationLog.unbondingNfts.length > 0 && (
        <>
          <div className='col-12 mt-3' style={{ fontSize: '20px' }}>
            Unbonding Souls
          </div>
          <div className='col-12 mt-1'>
            {soulMigrationLog.unbondingNfts.map((unbondingNft, index) => (
              <div key={`unbonding-nfts-logs-${index}`} className='ms-3'>
                {unbondingNft.field1.map((stakedNft, index2) => (
                  <div
                    key={`unbonding-nfts-logs-batch-${index}-${index2}`}
                    className='ms-3'
                  >
                    {stakedNft.token_id}-{int2hex(parseInt(stakedNft.nonce))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
