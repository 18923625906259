import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import { EventSettings } from 'types';
import jsonConfig from './config.json';

//////////////////////////////////////////////////////
// 					dApp Config						//
//////////////////////////////////////////////////////

export const dAppName = 'Age Of Zalmoxis';

export const toLocaleStringOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};
export const toLocaleStringOptionsNoDecimals = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};

export const DEFAULT_TOKEN_DECIMALS = 18;
export const GAS_MULTIPLY_FACTOR = 1.3; // TODO: remove this

export const EVENT_SETTINGS: EventSettings = {
  bendisCall: false,
  onlyKosonChestPurchase: true
};
export const PLATFORM_ID = '2';

export const LAND_CHEST_NAMES = ['Continental', 'Steepe', 'Panonic', 'Pontic'];

//////////////////////////////////////////////////////
// 					NETWORK CONFIG					//
//////////////////////////////////////////////////////

export const walletConnectV2ProjectId = '42c7a7e62eabcc790986a821ff4b143a';
const IS_DEV = jsonConfig.networkSettings.Environment === 'devnet';

export const environment = IS_DEV
  ? EnvironmentsEnum.devnet
  : EnvironmentsEnum.mainnet;
export const apiAddress = `https://${
  IS_DEV ? 'devnet-' : ''
}api.multiversx.com`;
export const gatewayAddress = `https://${
  IS_DEV ? 'devnet-' : ''
}gateway.multiversx.com`;
export const explorerAddress = `https://${
  IS_DEV ? 'devnet-' : ''
}explorer.multiversx.com`;
export const backendApiAddress = 'https://ageofzamolxis.com';

//////////////////////////////////////////////////////
// 				OLD CONFIG - DO NOT USE				//
//////////////////////////////////////////////////////
// export const KOSON_STAKING_POOL_SC_ADDRESSES = [
//   'erd1qqqqqqqqqqqqqpgqrg2yady60jezk3zpks0hr4uzq9cslt4jyl5s2rdn3h',
//   'erd1qqqqqqqqqqqqqpgqgts4dfjyh28p07zjye326kwdqq58wsasyl5s2duk0j',
//   'erd1qqqqqqqqqqqqqpgqt2czgzjm4j7enrer9uqxyd0tdvkkqaw2yl5sdpmuk9',
//   'erd1qqqqqqqqqqqqqpgqvlnjqtqnxpyt87qgczgj5tc08n3007aqyl5sp726e9',
//   'erd1qqqqqqqqqqqqqpgqk05lklxqd679zf30qfc90wl3ahcf4s0myl5slvw9xz',
//   'erd1qqqqqqqqqqqqqpgqhp5ex0at4e8fy2gen0vl2a8k6ln7f325yl5sk3yzk8'
// ];

export const OLD_KOSON_TICKER = jsonConfig.OLD_COLLECTIONS.OLD_KOSON_TOKEN_ID;
export const OLD_LAND_PLOT_TOKEN_IDENTIFIER =
  jsonConfig.OLD_COLLECTIONS.LAND_PLOT_TOKEN_ID;
export const OLD_LAND_CHEST_TOKEN_IDENTIFIER =
  jsonConfig.OLD_COLLECTIONS.LAND_CHEST_TOKEN_ID;
export const OLD_STAKED_KOSON_TOKEN_IDS =
  jsonConfig.OLD_COLLECTIONS.OLD_STAKED_KOSON_TOKEN_IDS;

export const ALL_OLD_LAND_SFT_TOKEN_IDENTIFIERS = [
  OLD_LAND_PLOT_TOKEN_IDENTIFIER,
  OLD_LAND_CHEST_TOKEN_IDENTIFIER,
  jsonConfig.OLD_COLLECTIONS.WEAPON_TOKEN_ID,
  jsonConfig.OLD_COLLECTIONS.ARMOUR_TOKEN_ID,
  jsonConfig.OLD_COLLECTIONS.MISC_TOKEN_ID,
  jsonConfig.OLD_COLLECTIONS.COLLECTIBLES_TOKEN_ID
];

export const OLD_KOSON_STAKING_POOL_SC_ADDRESSES =
  jsonConfig.OLD_CONTRACTS.KOSON_STAKING_POOLS;

//////////////////////////////////////////////////////
// 				  	  NEW CONFIG					//
//////////////////////////////////////////////////////
export const KOSON_STAKING_POOL_SC_ADDRESSES =
  jsonConfig.contracts.KOSON_STAKING_POOL_SC_ADDRESSES;

export const PRIMORDIAL_KOSON_TOKEN_IDENTIFIER =
  jsonConfig.Tokens.PRIMORDIAL_KOSON_TOKEN_ID;
export const ANCIENT_KOSON_TOKEN_IDENTIFIER =
  jsonConfig.Tokens.ANCIENT_KOSON_TOKEN_ID;
export const ESOTERIC_KOSON_TOKEN_IDENTIFIER =
  jsonConfig.Tokens.ESOTERIC_KOSON_TOKEN_ID;

//////////////////////////////////////////////////////
// 					CURRENT CONFIG					//
//////////////////////////////////////////////////////

export const VESTING_SC_ADDRESS = jsonConfig.contracts.VESTING_SC_ADDRESS;
//   'erd1qqqqqqqqqqqqqpgqe5nrv2wpcq8jrc307klfhpg7xxc2xszqlwfqymjaxd';
export const MINTING_SC_ADDRESS = jsonConfig.contracts.MINTING_SC_ADDRESS;
//   'erd1qqqqqqqqqqqqqpgqv9pzdh94lnr2s9aha3zlf4yxmjuhjwhtyl5squ2whs';
export const MARKETPLACE_SC_ADDRESS =
  jsonConfig.contracts.MARKETPLACE_SC_ADDRESS;
//   'erd1qqqqqqqqqqqqqpgqfz8nmyy6gfvn48hpffnlnmulnnuzx25cyl5s9w8m2v';
// export const SOUL_NFT_STAKING_SC_ADDRESS = Old address
//   'erd1qqqqqqqqqqqqqpgqz5jh90tx3d45rjxd8yqkmgt69dqp5ckeyl5s34wdmf';
export const NFT_SWAPS_SC_ADDRESS = jsonConfig.contracts.NFT_SWAPS_SC_ADDRESS;
//   'erd1qqqqqqqqqqqqqpgqkv9uee67h5rq6604zfczdmupvtgqymkeyl5sy6lkvh';
export const SUMMONING_SC_ADDRESS = jsonConfig.contracts.SUMMONING_SC_ADDRESS;
//   'erd1qqqqqqqqqqqqqpgqcuws9ujvmp984x53y3f4dg7h66axualuyl5sxdhdxw';
// 'erd1qqqqqqqqqqqqqpgqjlcgewzlgzy0g8u6ne9zpy673jmp5nsg6ppsemng8m';//devnet
export const LAND_CHEST_SALE_SC_ADDRESS =
  jsonConfig.contracts.LAND_CHEST_SALE_SC_ADDRESS;
//   'erd1qqqqqqqqqqqqqpgq68rc5hdt9ssnzvufe056sc5nxelqygjtyl5s066emh';
// export const LAND_PLOT_STAKING_SC_ADDRESS =
//   'erd1qqqqqqqqqqqqqpgqyg86jc0u9tuxyn49fzcyygjtpcqjmzajyl5sep0a4g'; // old address
export const LAND_CHEST_OPENING_SC_ADDRESS =
  jsonConfig.contracts.LAND_CHEST_OPENING_SC_ADDRESS;
//   'erd1qqqqqqqqqqqqqpgq5hyj8le8xpedcmqjw2444azcvjv6derfyl5sc29r66';
export const ASSET_REMAPPER_SC_ADDRESS =
  jsonConfig.contracts.ASSET_REMAPPER_SC_ADDRESS;
//   'erd1qqqqqqqqqqqqqpgqkhgzhwftml7txn5fg323cd52jr3x2a9wyl5sp7khee';
export const SOUL_NFT_STAKING_SC_ADDRESS =
  jsonConfig.contracts.SOUL_NFT_STAKING_SC_ADDRESS;
//   'erd1qqqqqqqqqqqqqpgqqx6sumdzn3rpxulvwydej7kac93rs8anyl5sym5njl';
export const LAND_PLOT_STAKING_SC_ADDRESS =
  jsonConfig.contracts.LAND_PLOT_STAKING_SC_ADDRESS;
//   'erd1qqqqqqqqqqqqqpgq56pjjtph4vppdzcufqpmd7dp9k9xgq2vyl5svyt6dm';

export const ORIGIN_SOULS_TOKEN_IDENTIFIERS = jsonConfig.NFTs.ORIGIN_SOULS;

export const SUMMONED_SOULS_TOKEN_IDENTIFIERS = jsonConfig.NFTs.SUMMONED_SOULS;

export const LAND_PLOT_TOKEN_IDENTIFIER = jsonConfig.SFTs.LAND_PLOT_TOKEN_ID;
export const LAND_CHEST_TOKEN_IDENTIFIER = jsonConfig.SFTs.LAND_CHEST_TOKEN_ID;
export const STAKED_KOSON_TOKEN_IDENTIFIERS = [
  jsonConfig.Tokens.STAKED_KOSON_POOL_1,
  jsonConfig.Tokens.STAKED_KOSON_POOL_2,
  jsonConfig.Tokens.STAKED_KOSON_POOL_3,
  jsonConfig.Tokens.STAKED_KOSON_POOL_4,
  jsonConfig.Tokens.STAKED_KOSON_POOL_5,
  jsonConfig.Tokens.STAKED_KOSON_POOL_6
];

export const UNBONDING_KOSON_TOKEN_IDENTIFIERS = [
  jsonConfig.Tokens.UNBONDING_KOSON_POOL_1,
  jsonConfig.Tokens.UNBONDING_KOSON_POOL_2,
  jsonConfig.Tokens.UNBONDING_KOSON_POOL_3,
  jsonConfig.Tokens.UNBONDING_KOSON_POOL_4,
  jsonConfig.Tokens.UNBONDING_KOSON_POOL_5,
  jsonConfig.Tokens.UNBONDING_KOSON_POOL_6
];

export const ITEM_NFT_TOKEN_IDENTIFIERS = [
  jsonConfig.SFTs.WEAPON_TOKEN_ID,
  jsonConfig.SFTs.ARMOUR_TOKEN_ID,
  jsonConfig.SFTs.MISC_TOKEN_ID,
  jsonConfig.SFTs.COLLECTIBLES_TOKEN_ID
];

//////////////////////////////////////////////////////
// 					CONFIG AGGREG.					//
//////////////////////////////////////////////////////
export const SMARTCONTRACT_ADDRESSES = KOSON_STAKING_POOL_SC_ADDRESSES.concat([
  VESTING_SC_ADDRESS,
  MINTING_SC_ADDRESS,
  MARKETPLACE_SC_ADDRESS,
  SOUL_NFT_STAKING_SC_ADDRESS,
  NFT_SWAPS_SC_ADDRESS,
  SUMMONING_SC_ADDRESS,
  LAND_CHEST_SALE_SC_ADDRESS,
  LAND_PLOT_STAKING_SC_ADDRESS,
  LAND_CHEST_OPENING_SC_ADDRESS,
  ASSET_REMAPPER_SC_ADDRESS,
  SOUL_NFT_STAKING_SC_ADDRESS,
  LAND_PLOT_STAKING_SC_ADDRESS
]);

export const ALL_SOUL_NFT_TOKEN_IDENTIFIERS =
  ORIGIN_SOULS_TOKEN_IDENTIFIERS.concat(SUMMONED_SOULS_TOKEN_IDENTIFIERS);

export const ALL_KOSON_TOKEN_IDENTIFIERS = [
  PRIMORDIAL_KOSON_TOKEN_IDENTIFIER,
  ANCIENT_KOSON_TOKEN_IDENTIFIER,
  ESOTERIC_KOSON_TOKEN_IDENTIFIER
];

export const ALL_ESDT_ASSETS_TOKEN_IDENTIFIERS =
  ALL_KOSON_TOKEN_IDENTIFIERS.concat(STAKED_KOSON_TOKEN_IDENTIFIERS);

export const ALL_NFT_ASSET_TOKEN_IDENTIFIERS =
  ALL_SOUL_NFT_TOKEN_IDENTIFIERS.concat(LAND_PLOT_TOKEN_IDENTIFIER)
    .concat(LAND_CHEST_TOKEN_IDENTIFIER)
    .concat([...ITEM_NFT_TOKEN_IDENTIFIERS])
    .concat([...UNBONDING_KOSON_TOKEN_IDENTIFIERS]);

export const LAND_CHEST_CONTENT_COLLECTIONS = [
  LAND_PLOT_TOKEN_IDENTIFIER
].concat(...ITEM_NFT_TOKEN_IDENTIFIERS);

export const ALL_NFT_COLLECTIONS = ALL_SOUL_NFT_TOKEN_IDENTIFIERS.concat(
  LAND_CHEST_TOKEN_IDENTIFIER
)
  .concat(LAND_PLOT_TOKEN_IDENTIFIER)
  .concat(...ITEM_NFT_TOKEN_IDENTIFIERS);
