import React from 'react';
import { StakedSoulType, StakedAssetType } from 'types/store';
import { SummoningInfo } from '../ThumbnailCardHeader/types';
import { StakedAssetThumbnail, StakedAssetThumbnailProps } from './Thumbnailv2';

type StakedSoulThumbnailProps = Omit<StakedAssetThumbnailProps, 'token'> & {
  nft: StakedSoulType;
};

export const StakedSoulThumbnail: React.FC<StakedSoulThumbnailProps> = ({
  nft,
  ...props
}) => {
  const token = nft as StakedAssetType;
  const summoningInfo: SummoningInfo = {
    regularSummons: nft.summoningCounts,
    specialSummons: nft.deathSoulSummoning
  };
  return (
    <StakedAssetThumbnail
      token={token}
      summoningInfo={summoningInfo}
      {...props}
    />
  );
};
