import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toLocaleStringOptions } from 'config/dapp-config';
import {
  KOSON_STAKING_POOLS,
  KOSON_STAKING_POOLS_ICONS
} from 'config/koson-staking-pools';
import { routeNames } from 'routes';
import { SingleKosonStakingPoolState } from 'store/slices/kosonv2/koson-staking-pools';

export const KosonStakingPoolRow = ({
  pool
}: {
  pool: SingleKosonStakingPoolState;
}) => {
  const navigate = useNavigate();
  const handleNavigateToPool = () => {
    navigate(
      routeNames.kosonStakingPool.replace(':poolId', pool.poolId.toString())
    );
  };

  const poolDefinition = KOSON_STAKING_POOLS[pool.poolId];

  return (
    <div className='row koson-staking-pool-row w-100'>
      <div className='col-sm-12 col-md-5'>
        <div className='pool-title'>
          {poolDefinition.name} {KOSON_STAKING_POOLS_ICONS[pool.poolId]}
        </div>
        <div>
          <strong className='important-text'>
            {(pool.poolShareOfDailyEmission * 100).toLocaleString(
              undefined,
              toLocaleStringOptions
            )}
            %
          </strong>{' '}
          of daily $KOSON emission
        </div>
      </div>
      <div className='col-sm-12 col-md-5 pool-description'>
        <div className='pool-subtitle'>
          Reward Index:{' '}
          <strong className='important-text'>{pool.rewardIndex}</strong>
        </div>
        <div>
          Unbonding epochs:{' '}
          <strong className='important-text'>{pool.unbondingEpochs}</strong>
        </div>
      </div>
      <div className='col-sm-12 col-md-2 pool-actions'>
        <button
          className='btn btn-primary btn-circle'
          onClick={handleNavigateToPool}
        >
          &#8594;
        </button>
        <button
          className='btn btn-primary btn-action'
          onClick={handleNavigateToPool}
        >
          Stake
        </button>
      </div>
    </div>
  );
};
