import { Address } from '@multiversx/sdk-core/out';
import { LAND_PLOT_STAKING_SC_ADDRESS } from 'config/dapp-config';
import { PaymentType } from 'types';
import { getTokenTransfer, parseEsdtTokenPayment } from 'utils';
import { useMvxContractUtils } from '../useMvxContractUtils';
import abiJson from './land-plot-staking.abi.json';
import { LandPlotStakingContextScType } from './types';

export const useLandPlotStaking = () => {
  const { getSmartContract, queryContract, sendTransaction } =
    useMvxContractUtils();

  const getLandPlotNftStakingContract = () => {
    return getSmartContract(LAND_PLOT_STAKING_SC_ADDRESS, abiJson);
  };

  const getLandPlotStakingContext = async (
    address: string
  ): Promise<LandPlotStakingContextScType> => {
    const sc = getLandPlotNftStakingContract();

    const interaction = sc.methods.getStakingContext([new Address(address)]);

    const result = await queryContract(interaction);

    return {
      aggregatedScore: result.aggregated_score.toNumber(),
      userScore: result.user_score.toNumber(),
      pendingRewards: result.pending_rewards.map(parseEsdtTokenPayment),
      stakedAssets: result.staked_assets.map((stakedAsset: any) => ({
        asset: parseEsdtTokenPayment(stakedAsset.nft),
        stakeEpoch: stakedAsset.stake_epoch.toNumber(),
        unstakeFee: stakedAsset.unstake_fee.toString(10)
      }))
    };
  };

  const stakePlots = async (nfts: PaymentType[]) => {
    const sc = getLandPlotNftStakingContract();

    const payments = nfts.map(getTokenTransfer);

    const interaction = sc.methods
      .stake([])
      .withMultiESDTNFTTransfer(payments)
      .withGasLimit(35_000_000 + nfts.length * 1_000_000);

    await sendTransaction(interaction, 'Stake Plots');
  };

  const unstakePlots = async (
    request: PaymentType[],
    feePayment: PaymentType
  ) => {
    const sc = getLandPlotNftStakingContract();

    const interaction = sc.methods
      .unstake(
        request.map((r) => ({
          nonce: r.nonce,
          amount: r.amount
        }))
      )
      .withSingleESDTTransfer(getTokenTransfer(feePayment))
      .withGasLimit(35_000_000 + request.length * 1_000_000);

    await sendTransaction(interaction, 'Unstake Plots');
  };

  const claimRewards = async () => {
    const sc = getLandPlotNftStakingContract();

    const interaction = sc.methods.claimRewards([]).withGasLimit(35_000_000);

    await sendTransaction(interaction, 'Claim Rewards');
  };

  return {
    getLandPlotStakingContext,
    stakePlots,
    unstakePlots,
    claimRewards
  };
};
