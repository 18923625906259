import React from 'react';
import { SoulNftType, StakeableAssetType } from 'types/store';
import { SummoningInfo } from '../ThumbnailCardHeader/types';
import {
  AvailableAssetThumbnail,
  AvailableAssetThumbnailProps
} from './AvailableAssetThumbnail';

type AvailableSoulThumbnailProps = Omit<
  AvailableAssetThumbnailProps,
  'token'
> & {
  nft: SoulNftType;
};

export const AvailableSoulThumbnail: React.FC<AvailableSoulThumbnailProps> = ({
  nft,
  ...props
}) => {
  const token = nft as StakeableAssetType;
  const summoningInfo: SummoningInfo = {
    regularSummons: nft.summoningCounts,
    specialSummons: nft.deathSoulSummoning
  };
  return (
    <AvailableAssetThumbnail
      token={token}
      summoningInfo={summoningInfo}
      {...props}
    />
  );
};
