import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { getTimeUntilShort } from 'contexts/Web3Context/helpers/generalUtils';
import { MarketplaceListing, MarketplaceListingV2 } from 'types';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { Web3Context } from 'contexts/Web3Context';
import { ImageAssetThumbnailCardHeader } from '../ThumbnailCardHeader';
import {
  AvailableListingInteractions,
  getAvailableListingInteractions
} from 'helpers';
import {
  useGetAccountInfo,
  useGetIsLoggedIn
} from '@multiversx/sdk-dapp/hooks';
import { MarketplaceInteractionButton } from 'components/Buttons/MarketplaceInteractionButton';
import { routeNames } from 'routes';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';

const MarketplaceListingThumbnail = ({
  nft
}: {
  nft: MarketplaceListing | MarketplaceListingV2;
}) => {
  const navigate = useNavigate();
  const loggedIn = useGetIsLoggedIn();

  const { account } = useGetAccountInfoWrapper();
  const { priceState } = useContext(Web3Context);

  const [currentPrice, setCurrentPrice] = useState(0);
  const [expiringTime, setExpiringTime] = useState('');
  const [currentUsdPrice, setCurrentUsdPrice] = useState(0);
  const [buySettings, setBuySettings] =
    useState<AvailableListingInteractions>();

  useEffect(() => {
    let bigNumberPrice;
    if (nft.minBid instanceof String || typeof nft.minBid === 'string') {
      bigNumberPrice = new BigNumber(nft.minBid);
    } else {
      bigNumberPrice = nft.minBid;
    }
    const nftPrice = bigNumberPrice.shiftedBy(-18).toNumber();
    const nftPriceUsd = (priceState?.egldPrice ?? -1) * nftPrice;
    setCurrentPrice(nftPrice);
    setCurrentUsdPrice(nftPriceUsd);
  }, [nft, nft.minBid, priceState?.egldPrice]);

  useEffect(() => {
    setExpiringTime(getTimeUntilShort(nft.endTime));
  }, [nft, nft.endTime]);

  const handleNavigation = () => {
    navigate(`/marketplace?listingId=${nft.listingId}`);
  };

  useEffect(() => {
    if (account === undefined || !account.address) {
      return;
    }
    const lbuySettings = getAvailableListingInteractions(
      {
        address: account.address,
        balance: account.balance
      },
      nft
    );
    setBuySettings(lbuySettings);
  }, [account]);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className='col-12 col-xl-2 col-lg-4 col-md-6 col-sm12'>
      <div className='card marketplace-listing'>
        <ImageAssetThumbnailCardHeader
          assetName={nft.name}
          assetNonce={nft.nonce}
          thumbnailUrl={nft.thumbnailUrl}
          summoningInfo={{
            regularSummons: nft.regularSummoningCount,
            specialSummons: nft.specialSummoningCount
          }}
          assetPrice={{
            currentPrice,
            currentUsdPrice
          }}
          onClick={handleNavigation}
        />
        {expiringTime && (
          <div
            className='card-body'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              className={`expire-deadline ${loggedIn && 'connected'}`}
              onClick={() => console.log('expired box')}
              style={{ opacity: isHovered ? 0 : 1 }}
            >
              {!nft.hasExpired && (
                <div className='p-2 mt-1'>Expires in {expiringTime}</div>
              )}
              {nft.hasExpired && (
                <div className='p-2 mt-1'>Listing has expired</div>
              )}
            </div>
            {isHovered && loggedIn && buySettings !== undefined && (
              <div className='quick-buy'>
                <MarketplaceInteractionButton
                  isBuyout={nft.isBuyout}
                  buyoutPayload={{
                    listingId: nft.listingId,
                    collection: nft.collection,
                    nonce: nft.nonce,
                    paymentToken: undefined,
                    listingAmountDenominated: nft.minBid,
                    isBuyout: nft.isBuyout
                  }}
                  buySettings={buySettings}
                />
              </div>
            )}
            {isHovered && !loggedIn && (
              <div className='quick-buy'>
                <button
                  className='btn btn-primary w-50 p-2 mt-2'
                  onClick={() => navigate(routeNames.unlock)}
                >
                  ⚡️ Connect
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketplaceListingThumbnail;
