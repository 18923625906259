import React from 'react';
import { useSelector } from 'react-redux';
import { OLD_KOSON_TICKER } from 'config/dapp-config';
import { useAssetRemapper } from 'hooks/contracts/assetRemapper/useAssetRemapper';
import { selectEsdtBalance } from 'store/slices/kosonv2/account';
import { TokenMigrationAmountMessage } from './TokenMigrationAmountMessage';

export const KosonTokenMigration = () => {
  const kosonV1Balance = useSelector(selectEsdtBalance)(OLD_KOSON_TICKER);
  const kosonSwapRate = 516.619;
  const { sendMigrateTransaction } = useAssetRemapper();

  return (
    <div>
      <h3>Migrate Koson</h3>
      <span>
        $KOSON migration is a one-way process. The exchange rate applied is
        <strong className='important-text'>
          {' '}
          {kosonSwapRate} old KOSON : 1 PRIMORDIAL KOSON
        </strong>
        .
      </span>
      <div className='mt-3 mb-2'>
        <TokenMigrationAmountMessage
          totalAmountIn={kosonV1Balance}
          exchangeRate={kosonSwapRate}
          tokenIn={'$KOSON V1'}
        />
      </div>
      <div className='mb-5'>
        <button
          className='ms-3 btn btn-outline-primary'
          onClick={() =>
            sendMigrateTransaction([
              {
                tokenIdentifier: OLD_KOSON_TICKER,
                amount: kosonV1Balance.toString(10)
              }
            ])
          }
        >
          Migrate
        </button>
      </div>
    </div>
  );
};
