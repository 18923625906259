import { useState } from 'react';
import { StakedAssetType } from 'types/store';
import { formatBigNumberString } from 'utils';
import {
  ImageAssetThumbnailCardHeader,
  VideoAssetThumbnailCardHeader
} from '../ThumbnailCardHeader';
import './style.css';
import { SummoningInfo } from '../ThumbnailCardHeader/types';

export interface StakedAssetThumbnailProps {
  token: StakedAssetType;
  isSelected: boolean;
  selectedQuantity: number;
  // setIsSelected: (isSelected: boolean) => void;
  // setSelectedQuantity: (selectedQuantity: number) => void;
  summoningInfo?: SummoningInfo;
  handleSelect?: () => void;
  handleUpdateQuantity?: (qty: number) => void;
  customColSize?: string;
}

export const StakedAssetThumbnail = ({
  token,
  summoningInfo,
  handleSelect,
  handleUpdateQuantity,
  customColSize,
  isSelected,
  selectedQuantity
}: // setIsSelected,
// setSelectedQuantity
StakedAssetThumbnailProps) => {
  const [isVideo] = useState(
    token.videoUrl !== undefined && token.videoUrl !== 'N/A'
  );
  const [isMultiple] = useState(Number(token.amount) > 1);
  const [isSelectable] = useState(true);
  // const [isSelected, setIsSelected] = useState(false);
  // const [selectedQuantity, setSelectedQuantity] = useState(0);

  const toggleSelect = () => {
    if (!isSelectable) {
      return;
    }
    // if (isSelected) {
    //   handleUpdateQuantity(isMultiple ? 0 : 1);
    // }
    if (handleSelect && !isMultiple) {
      handleSelect();
    }
    if (handleUpdateQuantity && isMultiple) {
      handleUpdateQuantity(0);
    }
    // setIsSelected(!isSelected);
  };

  const handleQuantityChangeSelector = (step: number) => {
    console.log('qty update', step);
    if (!handleUpdateQuantity) {
      return;
    }
    let newQty = selectedQuantity + step;
    if (newQty < 0) {
      newQty = 0;
    }
    if (newQty > Number(token.amount)) {
      newQty = Number(token.amount);
      return;
    }
    // setSelectedQuantity(newQty);
    handleUpdateQuantity(newQty);
  };

  return (
    <div
      className={customColSize ?? 'col-12 col-xl-2 col-lg-4 col-md-6 col-sm-12'}
    >
      <div className='card bg-transparent staked-item p-relative border-0'>
        <div
          onClick={toggleSelect}
          className={`${isSelectable ? 'selectable' : ''} ${
            isSelected ? 'selected' : ''
          }`}
        >
          {isVideo ? (
            <VideoAssetThumbnailCardHeader
              assetName={token.name ?? ''}
              videoUrl={token.videoUrl ?? ''}
              assetNonce={token.nonce}
              assetScore={token.stakeScore}
              quantity={Number(token.amount)}
            />
          ) : (
            <ImageAssetThumbnailCardHeader
              assetName={token.name || ''}
              assetNonce={token.nonce}
              assetScore={token.stakeScore}
              thumbnailUrl={token.thumbnailUrl ?? ''}
              summoningInfo={summoningInfo}
              quantity={Number(token.amount)}
            />
          )}
          <div className='stake-info'>
            Unstake Fee: {formatBigNumberString(token.unstakeFee)}
          </div>
          <div className='stake-info'>Stake Epoch: {token.stakeEpoch}</div>
        </div>
        {isSelected && isMultiple && handleUpdateQuantity && (
          <div className='card-body qty-picker'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              Select quantity:
              <div className='d-flex justify-content-center align-items-center mt-3'>
                <button
                  type='button'
                  className='btn btn-rounded btn-danger btn-xs me-2'
                  onClick={() => handleQuantityChangeSelector(-1)}
                >
                  -
                </button>
                {selectedQuantity}/{Number(token.amount)}
                <button
                  type='button'
                  className='btn btn-rounded btn-success btn-xs ms-2'
                  onClick={() => handleQuantityChangeSelector(+1)}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
