import React from 'react';
import axios from 'axios';
import {
  ALL_NFT_COLLECTIONS,
  apiAddress,
  OLD_KOSON_TICKER,
  LAND_CHEST_CONTENT_COLLECTIONS,
  LAND_CHEST_TOKEN_IDENTIFIER,
  LAND_CHEST_OPENING_SC_ADDRESS,
  LAND_CHEST_SALE_SC_ADDRESS,
  OLD_LAND_PLOT_TOKEN_IDENTIFIER,
  SOUL_NFT_STAKING_SC_ADDRESS,
  SMARTCONTRACT_ADDRESSES,
  STAKED_KOSON_TOKEN_IDENTIFIERS
} from 'config/dapp-config';

const fetchKosonBalance = (tokenIdentifier: string) =>
  async function getKosonBalance(address: string) {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/tokens?identifier=${tokenIdentifier}`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchActivityInfo = () =>
  async function getActivityInfo(address: string) {
    try {
      const receivers = SMARTCONTRACT_ADDRESSES.join(',');
      const { data } = await axios.get(
        `${apiAddress}/transactions?sender=${address}&status=success&receiver=${receivers}&size=1000`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchAccountNfts = (tokenIdentifiers: string[]) =>
  async function getAccountNfts(address: string) {
    try {
      const collections = tokenIdentifiers.join(',');
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/nfts?collections=${collections}&size=10000`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchAccountNftsCount = (address: string) =>
  async function getAccountNftsCount() {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/nfts/count`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchAccountSmartContracts = () =>
  async function getAccountSmartContracts(address: string) {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/contracts?size=10000`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchSmartContractOwnerAddress = () =>
  async function getSmartContractOwnerAddress(address: string) {
    try {
      const { data } = await axios.get(`${apiAddress}/accounts/${address}`);
      return {
        data: data.ownerAddress,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchSpecificAccountNfts = (address: string, identifiers: string[]) =>
  async function getSpecificAccountNfts() {
    try {
      let collectionsParam = '';
      if (identifiers.length > 0) {
        collectionsParam = '?collections=' + identifiers.join(',');
      }
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/nfts${collectionsParam}`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false,
        data: []
      };
    }
  };

const fetchTransactionInfo = () =>
  async function getTransactionInfo(txHash: string) {
    try {
      const { data } = await axios.get(`${apiAddress}/transactions/${txHash}`);
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false,
        data: []
      };
    }
  };

export const fetchNetworkStats = async () => {
  try {
    const { data } = await axios.get(`${apiAddress}/stats`);
    return {
      data: data,
      success: data !== undefined
    };
  } catch (err) {
    return {
      success: false
    };
  }
};

export const getKosonBalance = fetchKosonBalance(OLD_KOSON_TICKER);
export const getActivityTransactions = fetchActivityInfo();
export const getValidAccountNfts = fetchAccountNfts(ALL_NFT_COLLECTIONS);
export const getTotalStakedSoulNfts = fetchAccountNftsCount(
  SOUL_NFT_STAKING_SC_ADDRESS
);
export const getLandChestNfts = fetchAccountNfts([LAND_CHEST_TOKEN_IDENTIFIER]);
export const getLandPlotNfts = fetchAccountNfts([
  OLD_LAND_PLOT_TOKEN_IDENTIFIER
]);
export const getAccountSmartContracts = fetchAccountSmartContracts();
export const getSmartContractOwnerAddress = fetchSmartContractOwnerAddress();
export const getRemainingLandChests = fetchSpecificAccountNfts(
  LAND_CHEST_SALE_SC_ADDRESS,
  []
);
export const getRemainingChestContent = fetchSpecificAccountNfts(
  LAND_CHEST_OPENING_SC_ADDRESS,
  [OLD_LAND_PLOT_TOKEN_IDENTIFIER]
);
export const getStakedKoson = (address: string) =>
  fetchSpecificAccountNfts(address, STAKED_KOSON_TOKEN_IDENTIFIERS);
export const getTransactionInfo = fetchTransactionInfo();
