import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from 'components/Loading';
import { toLocaleStringOptions } from 'config/dapp-config';
import {
  KOSON_STAKING_POOLS,
  KOSON_STAKING_POOLS_ICONS
} from 'config/koson-staking-pools';
import { routeNames } from 'routes';
import { selectUnbondingKoson } from 'store/slices/kosonv2/account';
import {
  selectKosonStakingPoolById,
  selectStatus
} from 'store/slices/kosonv2/koson-staking-pools';
import './style.css';
import { formatBigNumberString } from 'utils';
import { KosonStakingPoolStatsCard } from './KosonStakingPoolStatsCard';
import { UnbondingKosonBatch } from './UnbondingKosonBatch';

export const KosonStakingPool = () => {
  const { poolId } = useParams();
  const navigate = useNavigate();

  const status = useSelector(selectStatus);

  const selectStakingPool = useSelector(selectKosonStakingPoolById);
  const selectUnbondingKosonBatches = useSelector(selectUnbondingKoson);
  const pool = selectStakingPool(parseInt(poolId ?? '-1'));

  const unbondingKosonBatches = selectUnbondingKosonBatches(
    pool?.unbondingTokenIdentifier ?? ''
  );

  const navigateToPoolSelector = () => {
    navigate(routeNames.kosonStaking);
  };

  useEffect(() => {
    if (poolId === undefined) {
      navigateToPoolSelector();
    }
    const poolIdNumber = parseInt(poolId as string);
    if (poolIdNumber < 0 || poolIdNumber >= KOSON_STAKING_POOLS.length) {
      navigate(routeNames.kosonStaking);
    }

    console.log('Selected pool: ', pool);
  }, []);

  return status !== 'succeeded' ? (
    <Loading />
  ) : (
    <>
      <div className='row mb-4'>
        <div className='col-12 d-flex justify-content-start'>
          <div>
            <button
              className='btn btn-outline-primary btn-sm me-2'
              onClick={navigateToPoolSelector}
            >
              &#8592;
            </button>
          </div>
          <h1>
            {pool.name} Koson staking pool{' '}
            {KOSON_STAKING_POOLS_ICONS[pool.poolId]}
          </h1>
        </div>
      </div>
      <div className='row koson-pool-stats-card-layout'>
        <div className='col-sm-12 col-xl-6 order-xl-1 order-2'>
          <KosonStakingPoolStatsCard
            title='Your Stake'
            data={{
              'Pool index KOSON amount': formatBigNumberString(
                pool.userIndexTokenBalance
              ),
              'Esoteric KOSON share': formatBigNumberString(
                pool.esotericKosonPoolShare
              ),
              'Ancient KOSON share': formatBigNumberString(
                pool.ancientKosonPoolShare
              ),
              'Primordial KOSON share': formatBigNumberString(
                pool.primordialKosonPoolShare
              ),
              'Pool share': `${pool.poolShare
                .multipliedBy(100)
                .toNumber()
                .toLocaleString(undefined, toLocaleStringOptions)}%`,
              'Unbonding KOSON': formatBigNumberString(
                pool.userUnbondingTokenBalance
              )
            }}
            unstakeTokenIdentifier={pool.indexTokenIdentifier}
            poolAddress={pool.scAddress}
          />
        </div>
        <div className='col-sm-12 col-xl-12 order-xl-3 order-3'>
          <div className='card'>
            <div className='card-header'>
              <h4>Your unbonding {pool.name} KOSON</h4>
            </div>
            <div
              className='card-body with-scroll'
              style={{ maxHeight: '450px' }}
            >
              {unbondingKosonBatches.length > 0 &&
                unbondingKosonBatches.map((batch, index) => (
                  <UnbondingKosonBatch
                    key={`unbonding-koson-batch-key-${pool.name}-${index}`}
                    amount={batch.amount}
                    unbondingStartEpoch={batch.unbondingEpoch}
                    maxUnbondingEpochs={pool.unbondingEpochs}
                    maxUnbondingFee={pool.maxClaimFee}
                    poolAddress={pool.scAddress}
                    batchTokenIdentifier={batch.tokenIdentifier}
                    batchNonce={batch.nonce}
                  />
                ))}
              {unbondingKosonBatches.length === 0 && (
                <div className='text-center mt-5 mb-5'>
                  You have no unbonding {pool.name} KOSON
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-xl-6 order-xl-2 order-1'>
          <KosonStakingPoolStatsCard
            title='Pool Details'
            data={{
              'Pool share of daily emission': `${(
                pool.poolShareOfDailyEmission * 100
              ).toLocaleString(undefined, toLocaleStringOptions)}%`,
              'Current reward index': pool.rewardIndex,
              'Total index KOSON supply': formatBigNumberString(
                pool.indexTokenSupply
              ),
              'Total unbonding KOSON': formatBigNumberString(
                pool.totalUnbondingSupply
              ),
              'Total Esoteric KOSON': formatBigNumberString(
                pool.esotericKosonPoolSupply
              ),
              'Total Ancient KOSON': formatBigNumberString(
                pool.ancientKosonPoolSupply
              ),
              'Total Primordial KOSON': formatBigNumberString(
                pool.primordialKosonPoolSupply
              ),
              'Max fast claim fee': `${pool.maxClaimFee * 100}%`,
              'Total unbonding epochs': pool.unbondingEpochs.toString()
            }}
            poolAddress={pool.scAddress}
          />
        </div>
      </div>
    </>
  );
};
