import BigNumber from 'bignumber.js';
import {
  ORIGIN_SOULS_TOKEN_IDENTIFIERS,
  ALL_SOUL_NFT_TOKEN_IDENTIFIERS,
  OLD_STAKED_KOSON_TOKEN_IDS
} from 'config/dapp-config';
import { RootState } from 'store';
import { UnbondingKosonEsdtType } from 'types/koson-staking-pool';
import { EsdtTokenType } from 'types/MultiversX';
import {
  getBigNumberTokenBalanceFromMap,
  formatBigNumberString,
  getBigNumberTokenBalanceSumFromMap
} from 'utils';

export const selectStatus = (state: RootState) =>
  state.kosonv2.accountInfo.status;

export const selectEsdtBalance =
  (state: RootState) =>
  (tokenIdentifier: string): BigNumber => {
    return getBigNumberTokenBalanceFromMap(
      state.kosonv2.accountInfo.data.tokenBalance,
      tokenIdentifier
    );
  };

export const selectFirstEsdtTokenType =
  (state: RootState) =>
  (tokenIdentifier: string): EsdtTokenType | undefined => {
    const balance =
      state.kosonv2.accountInfo.data.tokenBalance[tokenIdentifier];
    if (!balance || balance?.length === 0) {
      return undefined;
    }

    return balance[0];
  };

export const selectManyFirstEsdtTokenType =
  (state: RootState) =>
  (tokenIdentifiers: string[]): EsdtTokenType[] => {
    // return tokenIdentifiers
    //   .map(selectFirstEsdtTokenType(state))
    //   .filter((x) => x !== undefined);
    const result: EsdtTokenType[] = [];

    for (const tokenIdentifier of tokenIdentifiers) {
      const esdtTokenType = selectFirstEsdtTokenType(state)(tokenIdentifier);
      if (esdtTokenType !== undefined) {
        result.push(esdtTokenType);
      }
    }

    return result;
  };

export const selectFormattedEsdtBalance =
  (state: RootState) => (tokenIdentifier: string) => {
    return formatBigNumberString(selectEsdtBalance(state)(tokenIdentifier));
  };

export const selectMetaEsdtTotalBalance =
  (state: RootState) =>
  (tokenIdentifier: string): BigNumber => {
    return getBigNumberTokenBalanceSumFromMap(
      state.kosonv2.accountInfo.data.assetsBalance.unbondingKosons,
      tokenIdentifier
    );
  };

export const selectUnbondingKoson =
  (state: RootState) =>
  (tokenIdentifier: string): UnbondingKosonEsdtType[] => {
    return (
      state.kosonv2.accountInfo.data.assetsBalance.unbondingKosons[
        tokenIdentifier
      ] ?? []
    );
  };

export const selectUnmigratedStakedKosonBatches = (
  state: RootState
): EsdtTokenType[] => {
  return OLD_STAKED_KOSON_TOKEN_IDS.map(
    (tokenIdentifier) =>
      state.kosonv2.accountInfo.data.assetsBalance.unmigratedAssets[
        tokenIdentifier
      ] ?? []
  )
    .flat()
    .filter((v) => new BigNumber(v.amount).gt(new BigNumber(10).shiftedBy(6)));
};

export const selectSoulNfts = (state: RootState) => {
  return (
    Object.entries(state.kosonv2.accountInfo.data.assetsBalance.souls)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => {
        return value;
      })
      .flat()
  );
};

export const selectLandPlots = (state: RootState) => {
  return (
    Object.entries(state.kosonv2.accountInfo.data.assetsBalance.landPlots)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => {
        return value;
      })
      .flat()
  );
};

export const selectLandChests = (state: RootState) => {
  return (
    Object.entries(state.kosonv2.accountInfo.data.assetsBalance.landChests)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => {
        return value;
      })
      .flat()
  );
};

export const selectItems = (state: RootState) => {
  return (
    Object.entries(state.kosonv2.accountInfo.data.assetsBalance.items)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => {
        return value;
      })
      .flat()
  );
};

export const selectInventoryAssets = (state: RootState) => {
  return {
    souls: selectSoulNfts(state),
    landChests: selectLandChests(state),
    landPlots: selectLandPlots(state),
    items: selectItems(state)
  };
};

export const selectUnmigratedAssets = (state: RootState) => {
  return (
    Object.entries(
      state.kosonv2.accountInfo.data.assetsBalance.unmigratedAssets
    )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => {
        return value;
      })
      .flat()
      .filter(
        (asset) => !OLD_STAKED_KOSON_TOKEN_IDS.includes(asset.tokenIdentifier)
      )
  );
};

export const selectCanSummon = (state: RootState) => {
  const walletSouls = selectSoulNfts(state);

  const identifiers = walletSouls
    .filter((nft) => {
      const index = ALL_SOUL_NFT_TOKEN_IDENTIFIERS.indexOf(nft.tokenIdentifier);
      return index > 0 && nft.summoningCounts === 0;
    })
    .map((nft) => nft.tokenIdentifier);
  let hasFullSet = true;
  for (
    let i = 0;
    i < ORIGIN_SOULS_TOKEN_IDENTIFIERS.length && hasFullSet;
    i++
  ) {
    hasFullSet =
      hasFullSet && identifiers.includes(ORIGIN_SOULS_TOKEN_IDENTIFIERS[i]);
  }

  const canSummonRegularSouls =
    walletSouls.filter((nft) => (nft.summoningCounts ?? 6) < 6).length >= 2;

  return {
    canSummonRegular: canSummonRegularSouls,
    canSummonDeath: hasFullSet
  };
};

export const selectKYCStatus = (state: RootState) => {
  return state.kosonv2.accountInfo.data.kycStatus;
};
