import React from 'react';
import { AccountLoader } from './AccountLoader';
import { BlockchainStatsLoader } from './BlockchainStatsLoader';
import { KosonStakingPoolsLoader } from './KosonStakingPoolsLoader';
import { LandPlotStakingPoolLoader } from './LandPlotStakingPoolLoader';
import { MarketplaceLoader } from './MarketplaceLoader';
import { SoulStakingPoolLoader } from './SoulStakingPoolLoader';

export const KosonV2Loader = () => {
  return (
    <>
      <BlockchainStatsLoader />
      <AccountLoader />
      <KosonStakingPoolsLoader />
      <SoulStakingPoolLoader />
      <LandPlotStakingPoolLoader />
      <MarketplaceLoader />
    </>
  );
};
