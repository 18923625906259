import axios from 'axios';
import { backendApiAddress } from 'config/dapp-config';

const fetchCurrentLandChestPrices = () =>
  async function getCurrentLandChestPrices(address: string) {
    try {
      let url = `${backendApiAddress}/order/prices`;
      if (address?.startsWith('erd1')) {
        url += `?address=${address}`;
      }
      const { data } = await axios.get(url);
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false,
        data: []
      };
    }
  };

const fetchAssetPriceTagFromBitmart = (ticker: 'EGLD' | 'KOSON') =>
  async function getPriceFromBitmart() {
    try {
      const { data } = await axios.get(
        `https://api-cloud.bitmart.com/spot/quotation/v3/ticker?symbol=${ticker}_USDT`
      );
      return {
        data: data.data.last,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchKosonPriceTagFromBitmart = (ticker: 'EGLD' | 'KOSON') =>
  async function getKosonPriceFromBitmart() {
    try {
      const { data } = await axios.get(
        `https://api-cloud.bitmart.com/spot/quotation/v3/ticker?symbol=${ticker}_USDT`
      );
      return {
        data: data.data.last,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

export const getCurrentLandChestPrices = fetchCurrentLandChestPrices();
export const getKosonPrice = fetchAssetPriceTagFromBitmart('KOSON');
export const getEgldPrice = fetchAssetPriceTagFromBitmart('EGLD');
