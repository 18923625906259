import React from 'react';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import { Web3Context } from 'contexts/Web3Context';
import { routeNames } from 'routes';

export const VestedKosonCard = () => {
  const context = React.useContext(Web3Context);

  const [totalVested, setTotalVested] = React.useState(
    context.vestingState?.totalVested
  );

  React.useEffect(() => {
    setTotalVested(context.vestingState?.totalVested);
  }, [context.vestingState?.totalVested]);

  if (totalVested === 0) {
    return (
      <SimpleStatsCard
        title={'Vested KOSON'}
        value={(0).toLocaleString()}
        icon={faLock}
        showKosonSymbol
      />
    );
  }
  if (context.vestingState?.canClaim) {
    return (
      <SimpleStatsCard
        title={'Vested KOSON'}
        value={(context.vestingState?.remainingVested || 0).toLocaleString()}
        icon={faLockOpen}
        badgeText={'Ready to claim'}
        navUrl={routeNames.vesting}
        showKosonSymbol
      />
    );
  } else {
    return (
      <SimpleStatsCard
        title={'Vested KOSON'}
        value={(context.vestingState?.remainingVested || 0).toLocaleString()}
        icon={faLock}
        badgeText={`${context.vestingState?.nextClaimTime}`}
        navUrl={routeNames.vesting}
        showKosonSymbol
      />
    );
  }
};
