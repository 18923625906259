import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { RootState } from 'store';
import { EsdtTokenType } from 'types/MultiversX';
import {
  StakedSoulType,
  StoreStateStatusType,
  StoreStateType
} from 'types/store';

export interface SoulStakingPoolState {
  totalStakedNftsCount: number;
  totalStakedScore: number;
  userStakedNftsCount: number;
  userStakedScore: number;
  stakedSouls: StakedSoulType[];
  pendingRewards: EsdtTokenType[];
}

const defaultState: SoulStakingPoolState = {
  totalStakedNftsCount: 0,
  totalStakedScore: 0,
  userStakedNftsCount: 0,
  userStakedScore: 0,
  stakedSouls: [],
  pendingRewards: []
};

const initialState: StoreStateType<SoulStakingPoolState> = {
  data: defaultState,
  status: 'loading',
  error: null
};

const soulNftStakingPoolSlice = createSlice({
  name: 'soulNftStakingPool',
  initialState,
  reducers: {
    setStatus: (
      state: StoreStateType<SoulStakingPoolState>,
      action: PayloadAction<StoreStateStatusType>
    ) => {
      state.status = action.payload;
    },
    setSoulNftStakingPool: (
      state: StoreStateType<SoulStakingPoolState>,
      action: PayloadAction<SoulStakingPoolState>
    ) => {
      state.data = action.payload;
      console.log('setSoulNftStakingPool', state.data);
    }
  }
});

export const { setStatus, setSoulNftStakingPool } =
  soulNftStakingPoolSlice.actions;

export const selectSoulStakingStats = (state: RootState) => {
  const {
    totalStakedNftsCount,
    totalStakedScore,
    userStakedNftsCount,
    userStakedScore,
    stakedSouls
  } = state.kosonv2.soulStakingPool.data;

  const totalUnbondingFee = stakedSouls.reduce(
    (acc, { unstakeFee }) => acc.plus(unstakeFee),
    new BigNumber(0)
  );

  const maxStakedEpoch = Math.max(...stakedSouls.map((ss) => ss.stakeEpoch));
  return {
    totalStakedNftsCount,
    totalStakedScore,
    userStakedNftsCount,
    userStakedScore,
    totalUnbondingFee: totalUnbondingFee.toString(10),
    lastStakedEpoch: maxStakedEpoch
  };
};

export const selectStakedSouls = (state: RootState) => {
  return state.kosonv2.soulStakingPool.data.stakedSouls;
};

export const selectPendingRewards = (state: RootState) => {
  return state.kosonv2.soulStakingPool.data.pendingRewards;
};

export const selectStatus = (state: RootState) =>
  state.kosonv2.soulStakingPool.status;

export default soulNftStakingPoolSlice.reducer;
