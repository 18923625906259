import React from 'react';
import {
  faCoins,
  faGhost as faGhostRegular,
  faTreasureChest
} from '@fortawesome/pro-regular-svg-icons';
import { faGhost as faGhostSolid } from '@fortawesome/pro-solid-svg-icons';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import {
  ORIGIN_SOULS_TOKEN_IDENTIFIERS,
  SUMMONED_SOULS_TOKEN_IDENTIFIERS,
  toLocaleStringOptionsNoDecimals
} from 'config/dapp-config';
import { Web3Context } from 'contexts/Web3Context';

export interface InventoryCardInfoProps {
  soulsCount: number;
  landPlotsCount: number;
  landChestsCount: number;
  itemsCount: number;
}

const InventoryCardInfo = (p: InventoryCardInfoProps) => {
  const context = React.useContext(Web3Context);

  return (
    <>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Origin souls'}
          value={(p.soulsCount || 0).toLocaleString(
            undefined,
            toLocaleStringOptionsNoDecimals
          )}
          icon={faGhostSolid}
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Land plots'}
          value={p.landChestsCount.toLocaleString(
            undefined,
            toLocaleStringOptionsNoDecimals
          )}
          icon={faGhostRegular}
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Land chests'}
          value={p.landChestsCount.toLocaleString(
            undefined,
            toLocaleStringOptionsNoDecimals
          )}
          icon={faTreasureChest}
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Staked Koson Batches'}
          value={p.itemsCount.toLocaleString(
            undefined,
            toLocaleStringOptionsNoDecimals
          )}
          icon={faCoins}
        />
      </div>
    </>
  );
};
export default InventoryCardInfo;
