import { faVault } from '@fortawesome/free-solid-svg-icons';
import { faTreasureChest } from '@fortawesome/pro-light-svg-icons';
import {
  faHouseChimney,
  faMoneyBillTrendUp,
  faShieldCheck,
  faShopLock,
  faTableColumns,
  faWarehouseFull,
  faDna,
  faScrewdriverWrench,
  faShop,
  faMap,
  faGhost,
  faMapLocation,
  faCoins
} from '@fortawesome/pro-regular-svg-icons';
import { dAppName } from 'config/dapp-config';
import Admin from 'pages/Admin';
import Listings from 'pages/Dashboard/Listings';
import Vesting from 'pages/Dashboard/Vesting';
import Earn from 'pages/Earn';
import { InteractiveMap } from 'pages/InteractiveMap';
import Inventory from 'pages/Inventory';
import { KosonStakingPool } from 'pages/KosonStaking';
import KosonStakingPoolSelector from 'pages/KosonStaking/KosonStakingPoolSelector';
import { LandChestOpening } from 'pages/LandChestOpening';
import LandPlotStaking from 'pages/LandPlotStaking';
import Marketplace from 'pages/Marketplace';
import MarketplaceListingPage from 'pages/MarketplaceListing';
import { Migrate } from 'pages/Migrate';
import SoulNftStaking from 'pages/SoulNftStaking';
import Summoning from 'pages/Summoning';
import Verify from 'pages/Verify';
import withPageTitle from './components/PageTitle';
import Overview from './pages/Dashboard/Overview';
import Home from './pages/Home';
import { faSwap } from '@fortawesome/pro-solid-svg-icons';

export const routeNames = {
  home: '/',
  dashboard: '/dashboard',
  transaction: '/transaction',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  earn: '/earn',
  inventory: '/inventory',
  summoning: '/summoning',
  marketplace: '/marketplace',
  ownMarketplaceListings: '/dashboard/listings',
  ownMarketplaceListings2: '/marketplace/my-listings',
  p2pOffers: '/p2p-offers',
  marketplaceListing: '/listings/:listingId',
  vesting: '/dashboard/vesting',
  kosonStaking: '/earn/koson-staking',
  nftStaking: '/earn/nft-staking',
  landchestStaking: '/earn/land-staking',
  verify: '/verify',
  admin: '/admin',
  landChestSale: '/land-sale',
  interactiveMap: '/map',
  landChestOpening: '/land-opening',
  migrate: '/migrate',
  kosonStakingPool: '/koson-staking/:poolId'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home,
    displayInNavbar: true,
    icon: faHouseChimney
  },
  {
    path: routeNames.marketplace,
    title: 'Marketplace',
    component: Marketplace,
    displayInNavbar: true,
    icon: faShop,
    subMenuItems: [
      {
        path: routeNames.ownMarketplaceListings2,
        title: 'My Listings',
        component: Listings,
        displayInNavbar: true,
        icon: faShopLock,
        authenticatedRoute: true
      }
    ]
  },
  {
    path: routeNames.interactiveMap,
    title: 'Map',
    component: InteractiveMap,
    displayInNavbar: true,
    icon: faMap
  },
  {
    path: routeNames.landChestOpening,
    title: 'Land Chests',
    component: LandChestOpening,
    displayInNavbar: true,
    icon: faTreasureChest
    // subMenuItems: [
    //   {
    //     path: routeNames.landChestOpening,
    //     title: 'Open',
    //     component: LandChestOpening,
    //     displayInNavbar: true,
    //     icon: faBlockQuestion
    //   },
    //   // {
    //   //   path: routeNames.landChestSale,
    //   //   title: 'Purchase',
    //   //   component: LandChestSale,
    //   //   displayInNavbar: true,
    //   //   icon: faCartShopping
    //   // }
    //   // {
    //   //   path: routeNames.landchestStaking,
    //   //   title: 'Stake',
    //   //   component: LandPlotStaking,
    //   //   displayInNavbar: true,
    //   //   icon: faMoneyBillTrendUp,
    //   //   authenticatedRoute: true
    //   // }
    // ]
  },
  {
    path: routeNames.dashboard,
    title: 'Dashboard',
    component: Overview,
    authenticatedRoute: true,
    displayInNavbar: true,
    icon: faTableColumns,
    subMenuItems: [
      {
        path: routeNames.ownMarketplaceListings,
        title: 'Listings',
        component: Listings,
        displayInNavbar: true,
        icon: faShopLock,
        authenticatedRoute: true
      },
      {
        path: routeNames.vesting,
        title: 'Vesting',
        component: Vesting,
        displayInNavbar: true,
        icon: faVault,
        authenticatedRoute: true
      }
    ]
  },
  {
    path: routeNames.earn,
    title: 'Earn',
    component: Earn,
    authenticatedRoute: true,
    displayInNavbar: true,
    icon: faMoneyBillTrendUp,
    subMenuItems: [
      {
        path: routeNames.kosonStaking,
        title: 'Koson Staking',
        component: KosonStakingPoolSelector,
        authenticatedRoute: true,
        icon: faCoins
      },
      {
        path: routeNames.nftStaking,
        title: 'Soul Staking',
        component: SoulNftStaking,
        authenticatedRoute: true,
        icon: faGhost
      },
      {
        path: routeNames.landchestStaking,
        title: 'Land Staking',
        component: LandPlotStaking,
        authenticatedRoute: true,
        icon: faMapLocation
      }
    ]
  },
  {
    path: routeNames.inventory,
    title: 'Inventory',
    component: Inventory,
    authenticatedRoute: true,
    displayInNavbar: true,
    icon: faWarehouseFull
  },
  // {
  //   path: routeNames.summoning,
  //   title: 'Summoning',
  //   component: Summoning,
  //   authenticatedRoute: true,
  //   displayInNavbar: true,
  //   icon: faDna
  // },
  {
    path: routeNames.verify,
    title: 'Verify assets',
    component: Verify,
    authenticatedRoute: false,
    displayInNavbar: true,
    icon: faShieldCheck
  },
  {
    path: routeNames.vesting,
    title: 'Vesting',
    component: Vesting,
    authenticatedRoute: true
  },
  {
    path: routeNames.admin,
    title: 'Admin',
    component: Admin,
    authenticatedRoute: true,
    displayInNavbar: true,
    icon: faScrewdriverWrench
  },
  {
    path: routeNames.marketplaceListing,
    title: 'Marketplace Listing',
    component: MarketplaceListingPage
  },
  {
    path: routeNames.migrate,
    title: 'Migration assistant',
    displayInNavbar: true,
    authenticatedRoute: true,
    icon: faSwap,
    component: Migrate
  },
  {
    path: routeNames.kosonStakingPool,
    title: 'Koson Staking Pool',
    component: KosonStakingPool
  }
];

const mappedRoutes = routes
  .concat(
    routes
      .filter((r) => r.subMenuItems !== undefined && r.subMenuItems.length > 0)
      .map((r) => {
        return r.subMenuItems;
      })
      .reduce((prev, crt) => prev.concat(crt), [])
  )
  .map((route) => {
    const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;

    const requiresAuth = Boolean(route.authenticatedRoute);
    const wrappedComponent = withPageTitle(title, route.component);

    return {
      path: route.path,
      component: wrappedComponent,
      authenticatedRoute: requiresAuth
    };
  });

export const NAVBAR_MENU_ITEMS: NavbarMenuItem[] = routes
  .filter((r) => r.displayInNavbar)
  .map((r) => {
    return {
      route: r.path,
      display: r.title,
      isProtectedRoute: r.authenticatedRoute,
      icon: r.icon,
      subMenuItems:
        r.subMenuItems?.length > 0
          ? r.subMenuItems.map((item: any): NavbarMenuItem => {
              return {
                route: item.path,
                display: item.title,
                isProtectedRoute: item.authenticatedRoute,
                icon: item.icon,
                subMenuItems: []
              };
            })
          : undefined
    };
  });

export default mappedRoutes;

export interface NavbarMenuItem {
  route: string;
  display: string;
  isProtectedRoute: boolean;
  icon: any;
  subMenuItems: NavbarMenuItem[];
}
