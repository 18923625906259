import {
  ORIGIN_SOULS_TOKEN_IDENTIFIERS,
  SUMMONED_SOULS_TOKEN_IDENTIFIERS
} from './dapp-config';

export const SOUL_STAKING_SCORES = {
  [ORIGIN_SOULS_TOKEN_IDENTIFIERS[0]]: 7,
  [ORIGIN_SOULS_TOKEN_IDENTIFIERS[1]]: 7,
  [ORIGIN_SOULS_TOKEN_IDENTIFIERS[2]]: 7,
  [ORIGIN_SOULS_TOKEN_IDENTIFIERS[3]]: 7,
  [ORIGIN_SOULS_TOKEN_IDENTIFIERS[4]]: 7,
  [ORIGIN_SOULS_TOKEN_IDENTIFIERS[5]]: 7,
  [SUMMONED_SOULS_TOKEN_IDENTIFIERS[0]]: 1,
  [SUMMONED_SOULS_TOKEN_IDENTIFIERS[1]]: 1,
  [SUMMONED_SOULS_TOKEN_IDENTIFIERS[2]]: 1,
  [SUMMONED_SOULS_TOKEN_IDENTIFIERS[3]]: 1,
  [SUMMONED_SOULS_TOKEN_IDENTIFIERS[4]]: 1,
  [SUMMONED_SOULS_TOKEN_IDENTIFIERS[5]]: 1,
  [SUMMONED_SOULS_TOKEN_IDENTIFIERS[6]]: 49
};

export const NFT_UNBONDING_TIME_PENALTY = 7; // days
