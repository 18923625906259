import { useContext, useState } from 'react';
import {
  useGetAccountInfo,
  useGetIsLoggedIn,
  useTrackTransactionStatus
} from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { useNavigate } from 'react-router-dom';
import { getTransactionInfo } from 'api/apiRequests';
import {
  GAS_MULTIPLY_FACTOR,
  LAND_CHEST_TOKEN_IDENTIFIER,
  LAND_CHEST_OPENING_SC_ADDRESS
} from 'config/dapp-config';
import { Web3Context } from 'contexts/Web3Context';
import {
  addr2Hex,
  int2hex,
  str2hex
} from 'contexts/Web3Context/helpers/generalUtils';
import { routeNames } from 'routes';
import { AccountNft } from 'types';
import { ChestRewardModal } from './ChestRewardModal';
import { ThumbnailCard } from './ThumbnailCard';
import { StakeableAssetType } from 'types/store';
import { EsdtTokenType } from 'types/MultiversX';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';

export const OpeningCard = ({
  landChests,
  refreshDataTrigger
}: {
  landChests: EsdtTokenType[];
  refreshDataTrigger: () => Promise<void>;
}) => {
  // const { accountState } = useContext(Web3Context);

  const { address } = useGetAccountInfoWrapper();

  const [openedRewards, setOpenedRewards] = useState<any[]>([]);
  const loggedIn = useGetIsLoggedIn();
  const navigate = useNavigate();
  const [lsessionId, setSessionId] = useState('');
  const transactionStatus = useTrackTransactionStatus({
    transactionId: lsessionId,
    onSuccess: () => {
      if (transactionStatus.transactions) {
        const hash = transactionStatus.transactions[0].hash;
        getTransactionInfo(hash).then((res) => {
          setOpenedRewards(
            res.data.operations.filter(
              (op: any) => op.collection !== LAND_CHEST_TOKEN_IDENTIFIER
            )
          );
          refreshDataTrigger().then(() => {
            // do nothing
          });
        });
      }
    }
  });

  const noLandChestsFound = () => {
    return (
      <div className='text-center mt-5'>
        {!loggedIn && <h3>Connect your wallet and open some chests</h3>}
        {!loggedIn && (
          <button
            className='btn btn-primary mt-3'
            onClick={() => navigate(routeNames.unlock)}
          >
            Connect
          </button>
        )}
        {loggedIn && <h3>No Land Chests found on your wallet</h3>}
      </div>
    );
  };

  const handleOpening = async (quantity: number, nonce?: number) => {
    if (address === undefined) {
      return;
    }
    const data = `ESDTNFTTransfer@${str2hex(
      LAND_CHEST_TOKEN_IDENTIFIER
    )}@0${nonce}@${int2hex(quantity)}@${addr2Hex(
      LAND_CHEST_OPENING_SC_ADDRESS
    )}@${str2hex('openChests')}`;

    const transaction = {
      receiver: address,
      gasLimit: Math.ceil(
        (20_000_000 + quantity * 1_000_000) * GAS_MULTIPLY_FACTOR
      ),
      data: data,
      value: 0
    };
    const { sessionId } = await sendTransactions({
      transactions: [transaction],
      transactionsDisplayInfo: {
        processingMessage: 'Opening chests',
        errorMessage: 'An error has occured during processing your transaction',
        successMessage: 'Transaction processed successfully',
        transactionDuration: 10000
      }
    });
    setSessionId(sessionId);
  };

  return (
    <div className='card'>
      <div className='card-header'>
        <h2>Open Land Chests</h2>
      </div>
      <div className='card-body container-fluid p-0 flex-grow-1'>
        {(landChests.length ?? 0) === 0 && noLandChestsFound()}
        <div className='row'>
          {landChests.map((chest, idx) => (
            <ThumbnailCard
              chest={chest}
              idx={idx}
              handleOpening={handleOpening}
            />
          ))}
        </div>
      </div>
      <ChestRewardModal
        openedRewards={openedRewards}
        onDismiss={() => setOpenedRewards([])}
      />
    </div>
  );
  return null;
};
