// slices/accountInfo/index.ts
import { combineReducers } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { selectStatus as selectAccountStatus } from './account';
import accountInfoReducer from './account/account';
import blockchainStatsReducer from './blockchain-stats';
import { selectStatus as selectBlockchainStatsStatus } from './blockchain-stats';
import kosonStakingPoolsReducer from './koson-staking-pools';
import { selectStatus as selectKosonStakingPoolsStatus } from './koson-staking-pools';
import landPlotStakingPoolReducer from './land-plot-staking-pool';
import { selectStatus as selectLandPlotStakingStatus } from './land-plot-staking-pool';
import soulStakingPoolReducer from './soul-staking-pool';
// import other reducers
import { selectStatus as selectSoulStakingStatus } from './soul-staking-pool';

const kosonv2Reducer = combineReducers({
  accountInfo: accountInfoReducer,
  kosonStakingPools: kosonStakingPoolsReducer,
  soulStakingPool: soulStakingPoolReducer,
  landPlotStakingPool: landPlotStakingPoolReducer,
  blockchainStats: blockchainStatsReducer
  // other reducers
});

export const selectLoadingStatus =
  (state: RootState) => (vestingLoadingStatus: boolean) => {
    const accountInfoStatus = selectAccountStatus(state);
    const blockchainStatsStatus = selectBlockchainStatsStatus(state);
    const kosonStakingPoolsStatus = selectKosonStakingPoolsStatus(state);
    const landPlotStakingStatus = selectLandPlotStakingStatus(state);
    const soulStakingStatus = selectSoulStakingStatus(state);

    const stillLoading = [];

    if (accountInfoStatus === 'loading') {
      stillLoading.push('Account Info');
    }
    if (blockchainStatsStatus === 'loading') {
      stillLoading.push('Blockchain Stats');
    }
    if (kosonStakingPoolsStatus === 'loading') {
      stillLoading.push('Koson Staking Pools');
    }
    if (landPlotStakingStatus === 'loading') {
      stillLoading.push('Land Plot Staking');
    }
    if (soulStakingStatus === 'loading') {
      stillLoading.push('Soul Staking');
    }
    if (vestingLoadingStatus) {
      stillLoading.push('Vesting');
    }

    return {
      isAnyLoading: stillLoading.length > 0,
      stillLoading
    };
  };

export default kosonv2Reducer;
