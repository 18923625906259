import axios from 'axios';
import { backendApiAddress } from 'config/dapp-config';
import {
  KYC_STATUS,
  KYCStatusType,
  KYCStatusType_DEFAULT
} from 'hooks/useAccountInfo';

const fetchKYCStatus = () =>
  async function getKYCStatus(address: string) {
    try {
      const { data } = await axios.get(`${backendApiAddress}/kyc/${address}`);
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false,
        data: []
      };
    }
  };

const parseKYCStatus = async (address: string): Promise<KYCStatusType> => {
  const res = await fetchKYCStatus()(address);

  if (res.success) {
    const isKycVerified =
      res.data.status === 'VERIFIED' && res.data.state === 'VALIDATED';
    const isKycStarted = !isKycVerified && res.data.status !== null;
    const kycInfo = {
      sessionId: res.data.sessionId,
      status: isKycVerified
        ? KYC_STATUS.VERIFIED
        : isKycStarted
        ? KYC_STATUS.STARTED
        : KYC_STATUS.NOT_STARTED
    };
    return kycInfo;
  }

  return KYCStatusType_DEFAULT;
};

export const getKYCStatus = fetchKYCStatus();
export const getKYCStatusV2 = parseKYCStatus;
