import { useState } from 'react';
import { StakeableAssetType } from 'types/store';
import {
  ImageAssetThumbnailCardHeader,
  VideoAssetThumbnailCardHeader
} from '../ThumbnailCardHeader';
import './style.css';
import { SummoningInfo } from '../ThumbnailCardHeader/types';

export interface AvailableAssetThumbnailProps {
  token: StakeableAssetType;
  isSelected: boolean;
  selectedQuantity: number;
  summoningInfo?: SummoningInfo;
  handleSelect?: () => void;
  handleUpdateQuantity?: (qty: number) => void;
  customColSize?: string;
}

export const AvailableAssetThumbnail = ({
  token,
  isSelected,
  selectedQuantity,
  summoningInfo,
  handleSelect,
  handleUpdateQuantity,
  customColSize
}: AvailableAssetThumbnailProps) => {
  const [isVideo] = useState(
    token.videoUrl !== undefined && token.videoUrl !== 'N/A'
  );
  const [isMultiple] = useState(Number(token.amount) > 1);
  const [isSelectable] = useState(true);

  const toggleSelect = () => {
    if (!isSelectable) {
      return;
    }
    if (handleSelect && !isMultiple) {
      handleSelect();
    }
    if (handleUpdateQuantity && isMultiple) {
      handleUpdateQuantity(0);
    }
  };

  const handleQuantityChangeSelector = (step: number) => {
    if (!handleUpdateQuantity) {
      return;
    }
    let newQty = selectedQuantity + step;
    if (newQty < 0) {
      newQty = 0;
    }
    if (newQty > Number(token.amount)) {
      newQty = Number(token.amount);
      return;
    }
    handleUpdateQuantity(newQty);
  };

  return (
    <div
      className={customColSize ?? 'col-12 col-xl-2 col-lg-4 col-md-6 col-sm-12'}
    >
      <div className='card bg-transparent staked-item p-relative border-0'>
        <div
          onClick={toggleSelect}
          className={`${isSelectable ? 'selectable' : ''} ${
            isSelected ? 'selected' : ''
          }`}
        >
          {isVideo ? (
            <VideoAssetThumbnailCardHeader
              assetName={token.name ?? ''}
              videoUrl={token.videoUrl ?? ''}
              assetNonce={token.nonce}
              assetScore={token.stakeScore}
              quantity={Number(token.amount)}
            />
          ) : (
            <ImageAssetThumbnailCardHeader
              assetName={token.name || ''}
              assetNonce={token.nonce}
              assetScore={token.stakeScore}
              thumbnailUrl={token.thumbnailUrl ?? ''}
              summoningInfo={summoningInfo}
              quantity={Number(token.amount)}
            />
          )}
        </div>
        {isSelected && isMultiple && handleUpdateQuantity && (
          <div className='card-body qty-picker'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              Select quantity:
              <div className='d-flex justify-content-center align-items-center mt-3'>
                <button
                  type='button'
                  className='btn btn-rounded btn-danger btn-xs me-2'
                  onClick={() => handleQuantityChangeSelector(-1)}
                >
                  -
                </button>
                {selectedQuantity}/{Number(token.amount)}
                <button
                  type='button'
                  className='btn btn-rounded btn-success btn-xs ms-2'
                  onClick={() => handleQuantityChangeSelector(+1)}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
