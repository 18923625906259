import {
  ALL_SOUL_NFT_TOKEN_IDENTIFIERS,
  ITEM_NFT_TOKEN_IDENTIFIERS,
  LAND_PLOT_TOKEN_IDENTIFIER
} from 'config/dapp-config';

// souls mappings
export const SOULS_NFT_NAME_MAPPING_CONFIG = {
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[0]]: 'Origin: Air #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[1]]: 'Origin: Earth #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[2]]: 'Origin: Fire #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[3]]: 'Origin: Life #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[4]]: 'Origin: Aether #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[5]]: 'Origin: Water #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[6]]: 'Summoned: Air #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[7]]: 'Summoned: Earth #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[8]]: 'Summoned: Fire #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[9]]: 'Summoned: Life #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[10]]: 'Summoned: Aether #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[11]]: 'Summoned: Water #',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[12]]: 'Origin: Death #'
};

export const LAND_PLOT_NAME_MAPPING_CONFIG = [
  'Alpine Token',
  'Pontic Token',
  'Panonic Token',
  'Steepe Token',
  'Continental Token'
];

export const ITEM_NAME_MAPPING_CONFIG = {
  [ITEM_NFT_TOKEN_IDENTIFIERS[0]]: [
    'Legendary Weapon',
    'Epic Weapon',
    'Rare Weapon',
    'Average Weapon',
    'Inferior Weapon'
  ],
  [ITEM_NFT_TOKEN_IDENTIFIERS[1]]: [
    'Legendary Armour',
    'Epic Armour',
    'Rare Armour',
    'Average Armour',
    'Inferior Armour'
  ],
  [ITEM_NFT_TOKEN_IDENTIFIERS[2]]: [
    'Legendary Misc',
    'Epic Misc',
    'Rare Misc',
    'Average Misc',
    'Inferior Misc'
  ],
  [ITEM_NFT_TOKEN_IDENTIFIERS[3]]: [
    'Legendary Collectible',
    'Epic Collectible',
    'Rare Collectible',
    'Average Collectible',
    'Inferior Collectible'
  ]
};

export const SOULS_NFT_THUMBNAIL_MAPPING_CONFIG = {
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[0]]: 'souls/air.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[1]]: 'souls/earth.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[2]]: 'souls/fire.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[3]]: 'souls/life.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[4]]: 'souls/aether.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[5]]: 'souls/water.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[6]]: 'summoned-souls/air.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[7]]: 'summoned-souls/earth.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[8]]: 'summoned-souls/fire.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[9]]: 'summoned-souls/life.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[10]]: 'summoned-souls/aether.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[11]]: 'summoned-souls/water.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[12]]: 'summoned-souls/death.png'
};

export const SOULS_NFT_IPFS_URL_MAPPING = {
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[0]]:
    'QmSkiEotTxx1f75yr9oWFPyKVSqwaiUJvfKRcn2scuEb5g/Origin-Air-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[1]]:
    'QmNwAMvP3AjAFknoaELM537ShKVHkQS5BEYNZtWmdkRWyr/Origin-Earth-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[2]]:
    'QmUVQprYBqXQmm5gtvqFCjwhGuAXTc1rouCSKK21y2Meyf/Origin-Fire-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[3]]:
    'QmRb8Ptz7Wprn1mtQeKdEQrSPEN5CEZ3dhM9vdVY3dKwVi/Origin-Life%20Soul%20Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[4]]:
    'Qmf3KBEMzhqyp4bDadK62BbA64iFBNhF2tz9bWK6VJHuKM/Origin-Aether-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[5]]:
    'QmSdpbzWt6kUk2fPQFweN94z6CvgSTSZzMZ2JqyrCzwCEk/Origin-Water-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[6]]:
    'QmdJek7DhZhkqoznYZHbYemiRR8CHuzoFXEcAABG9FZAC8/Summoned-Air-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[7]]:
    'QmUELf8NF2KvtMzmG69GMj47YQxjcP4NqxvqseZ53x2R1D/Summoned-Earth-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[8]]:
    'QmWzLjFArH2qVABWPkPJGnxHUT1uH3kWBXJ8RVNcc9eMrU/Summoned-Fire-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[9]]:
    'Qmct9YaEdv8HBjCyXgg3cWSK1i2k5zpM7LEVoAMbrd6oD7/Summoned-Life-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[10]]:
    'QmcKdBCeDMDZz9FRFJJ8wB8ao349RZAV3LJCus9S9ryjR2/Summoned-Aether-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[11]]:
    'QmQnsTvC6UTxdGuUpaub7RzsW1D8nAqP2bGU9F6WHaAv2a/Summoned-Water-Soul-Element.png',
  [ALL_SOUL_NFT_TOKEN_IDENTIFIERS[12]]:
    'QmNxi8QXb5GbGRPU2GfzG1veXniS1Lvc9MXiyb8FXaExut/Origin-Death-Soul-Element.png'
};

export const LAND_CHEST_CONTENT_VIDEO_URL_MAPPING: { [key: string]: string } = {
  [`${LAND_PLOT_TOKEN_IDENTIFIER}-05`]:
    'https://media.elrond.com/nfts/asset/QmTtmkCvEktF2gDjsCwJj8ti82UCuzSuQQFSys4AxnPwLp',
  [`${LAND_PLOT_TOKEN_IDENTIFIER}-04`]:
    'https://media.elrond.com/nfts/asset/QmdmHroUw4U8nknn3wGrUy3aePYsqJ4XZwndhuftfJiQWg',
  [`${LAND_PLOT_TOKEN_IDENTIFIER}-03`]:
    'https://media.elrond.com/nfts/asset/QmQVFRWd5RYYrXD3kxbtM7Y3T8HWCt5vuJdtQye9KWi4ZP',
  [`${LAND_PLOT_TOKEN_IDENTIFIER}-02`]:
    'https://media.elrond.com/nfts/asset/QmfU5Ku6giqd3YsJSiQJvdbhpQA3xxtMDkyYPxXXFYYjLy',
  [`${LAND_PLOT_TOKEN_IDENTIFIER}-01`]:
    'https://media.elrond.com/nfts/asset/QmRjVA2k43GiP33RAkeCsFyMr6Hwk1q5VKHUuvMQFDeDCG',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[0]}-05`]:
    'https://media.elrond.com/nfts/asset/QmfZ1dg2GTeyWf5wYhYiJAcZkVXZDwgFtrnRhGMSHCSeT7',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[0]}-04`]:
    'https://media.elrond.com/nfts/asset/QmSK32W6E9qYG5k6hmoPD7DGQbdT5RJ34fwTUjciu7H5rn',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[0]}-03`]:
    'https://media.elrond.com/nfts/asset/QmUoZoWugBoQYMF2sinJDuRj4pyYMkuSTt2Npa6DFpt4rd',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[0]}-02`]:
    'https://media.elrond.com/nfts/asset/QmV7ugTvjCmnDU1yCQbcEEvAArnsEZBGrnojtv6GXPqcKF',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[0]}-01`]:
    'https://media.elrond.com/nfts/asset/QmPnBUaWxJQa4jXP85MF4jugXraVYZpwxQkpUa85QJ7DJa',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[1]}-05`]:
    'https://media.elrond.com/nfts/asset/QmXyQpFfMyVjccPzyXVA4ApLhedYJMxrgWrqk4YrYvdQHw',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[1]}-04`]:
    'https://media.elrond.com/nfts/asset/QmQ2j3JUUANWNW8QnF4iEvrx3EpBnfU6fHBJ5X7WgXYyVf',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[1]}-03`]:
    'https://media.elrond.com/nfts/asset/QmaBYRf6dhrxThQqKMf1fVCg34h2zyNFL8iQkK9JaWLUbd',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[1]}-02`]:
    'https://media.elrond.com/nfts/asset/QmRCq7dKyDTRkpWADCzaLJ28VxdkGuk449H4aYzQ9dUrXs',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[1]}-01`]:
    'https://media.elrond.com/nfts/asset/QmVt4CBmFLZUzPGDPSzUEEEjrRFDkHrCZCv2njAEAZscBV',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[2]}-05`]:
    'https://media.elrond.com/nfts/asset/QmTQMjZnFSw6DPJAknWHPxMspLdmKwdjyTzVk1opSz3PK7',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[2]}-04`]:
    'https://media.elrond.com/nfts/asset/QmPLDG3MUVzCkJ4tSwdJC6PZ1AdY2jJmBkdRLbxNPXwh7S',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[2]}-03`]:
    'https://media.elrond.com/nfts/asset/QmbBFFzQM9bErrdN4ZJTzggwxwBNtTvnJmCMk3BMmLPuTV',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[2]}-02`]:
    'https://media.elrond.com/nfts/asset/QmYMmfzLjA5VVZ8Yop86rf7Ktxe1CcPgkL5FAMaDwmX6vX',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[2]}-01`]:
    'https://media.elrond.com/nfts/asset/QmZQeLzWtTaDWzY7daU8FLqNHvDQdvdMcbLB7oKiSqDjwP',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[3]}-05`]:
    'https://media.elrond.com/nfts/asset/QmTz6JkK4BaKatq2d5tBREuDPTAB6dXaUhSuKezMryKzM1',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[3]}-04`]:
    'https://media.elrond.com/nfts/asset/QmQ7UdYZfKVESUSiQ8cSeXVD1APe7VBzu5CJ2pNuPybmEG',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[3]}-03`]:
    'https://media.elrond.com/nfts/asset/QmTfn6MVsQ6dQacrW74tzFmigDoQJx5LPMQYirZh3LbbaA',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[3]}-02`]:
    'https://media.elrond.com/nfts/asset/QmZ1wh5XxXogo9qYGxJNPTzp5VufDv9pBYuRePbQGBAmKE',
  [`${ITEM_NFT_TOKEN_IDENTIFIERS[3]}-01`]:
    'https://media.elrond.com/nfts/asset/QmfDPLpaw3sPaLXiLivZVruQtsqnjrD7uiTZgrxsAGe8cq'
};

export const KOSON_TOKEN_NAMES = [
  'Primordial Koson',
  'Ancient Koson',
  'Esoteric Koson'
];
