import React from 'react';
import { faHourglassStart } from '@fortawesome/free-solid-svg-icons';
import { faVault } from '@fortawesome/pro-regular-svg-icons';
import {
  faFaceTongueMoney,
  faHandsHoldingDollar
} from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Activity from 'components/Activity';
import ActionAlert from 'components/Alerts/ActionAlert';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import { toLocaleStringOptions, VESTING_SC_ADDRESS } from 'config/dapp-config';
import { Web3Context } from 'contexts/Web3Context';
import { routeNames } from 'routes';
import { ACTIVITY_INTERACTION_TYPES } from 'types';

const Vesting = () => {
  const context = React.useContext(Web3Context);
  const navigate = useNavigate();
  const handleClaimVesting = async () => {
    await context.sendTransaction(VESTING_SC_ADDRESS, 35_000_000, 'claimKoson');
  };

  const noVestedKosonComponent = () => {
    return (
      <div className='row mt-5'>
        <div className='col-xl-12 col-lg-12 col-sm-12 text-center mb-5'>
          <h2>You don&apos;t have any locked KOSON.</h2>
        </div>
        <div className='col-xl-12 col-lg-12 col-sm-12 text-center'>
          <button
            className='btn btn-primary'
            onClick={handleNavigateToDashboard}
          >
            Back to dashboard
          </button>
        </div>
      </div>
    );
  };

  const handleNavigateToDashboard = () => {
    navigate(routeNames.dashboard);
  };

  return context.vestingState?.totalVested === 0 ? (
    noVestedKosonComponent()
  ) : (
    <>
      {context.vestingState?.canClaim && (
        <ActionAlert
          alertText='You have unclaimed vesting rounds.'
          actionText='Claim now!'
          action={handleClaimVesting}
        />
      )}
      <div className='row'>
        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
          <SimpleStatsCard
            title={'Total Vested Koson'}
            value={(context.vestingState?.totalVested || 0).toLocaleString(
              undefined,
              toLocaleStringOptions
            )}
            icon={faVault}
            showKosonSymbol
          />
        </div>
        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
          <SimpleStatsCard
            title={'Remaining vested'}
            value={(context.vestingState?.remainingVested || 0).toLocaleString(
              undefined,
              toLocaleStringOptions
            )}
            icon={faHandsHoldingDollar}
            showKosonSymbol
          />
        </div>
        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
          <SimpleStatsCard
            title={'Total claimed so far'}
            value={(
              (context.vestingState?.totalVested || 0) -
              (context.vestingState?.remainingVested || 0)
            ).toLocaleString(undefined, toLocaleStringOptions)}
            icon={faFaceTongueMoney}
            badgeText={`${
              context.vestingState?.claimedRounds || 0
            }/10 claimed rounds`}
            showKosonSymbol
          />
        </div>
        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
          <SimpleStatsCard
            title={'Time until next claim'}
            value={
              context.vestingState?.canClaim
                ? 'Now'
                : context.vestingState?.nextClaimTime || 'N/A'
            }
            icon={faHourglassStart}
          />
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col-xl-12 col-lg-12 col-sm-12'>
          <Activity
            interaction={ACTIVITY_INTERACTION_TYPES.Vesting}
            customHeader={'Vesting Activity'}
          />
        </div>
      </div>
    </>
  );
};

export default Vesting;
