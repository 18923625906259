import React from 'react';
import { useSelector } from 'react-redux';
import { toLocaleStringOptionsNoDecimals } from 'config/dapp-config';
import { useAssetRemapper } from 'hooks/contracts/assetRemapper/useAssetRemapper';
import { selectUnmigratedAssets } from 'store/slices/kosonv2/account';

export const LandChestContentMigration = () => {
  const unmigratedAssets = useSelector(selectUnmigratedAssets);
  const { sendMigrateTransaction } = useAssetRemapper();

  const unmigratedAssetsCount = unmigratedAssets.reduce((acc, curr) => {
    return acc + parseInt(curr.amount);
  }, 0);

  const handleChestAssetsMigrate = async () => {
    const assets = unmigratedAssets.map((asset) => ({
      tokenIdentifier: asset.tokenIdentifier,
      nonce: asset.nonce,
      amount: asset.amount
    }));
    sendMigrateTransaction(assets);
  };

  return (
    <div>
      <h3>Migrate Land Chest related assets</h3>
      <span>
        Land Chest assets consist of Land Plots, Land Chests and Equipment
        Items. Land content migration is a one-way process.
        <br />
        The migration is required for technical reasons and the only change you
        will notice is the ticker of the SFT token (and verification status in
        xPortal and explorer - process that is currently in progress).
      </span>
      <div className='mt-3 mb-2'>
        <span className='ms-3' style={{ fontSize: 'larger' }}>
          You have{' '}
          <strong className='important-text'>
            {unmigratedAssetsCount.toLocaleString(
              undefined,
              toLocaleStringOptionsNoDecimals
            )}
          </strong>{' '}
          Land Chest Assets to migrate:
        </span>
      </div>
      <div className='mb-2'>
        {unmigratedAssets.map((asset, index) => (
          <div key={index} className='ms-4'>
            <strong className='important-text'>{asset.amount}</strong> x{' '}
            {asset.tokenIdentifier}
          </div>
        ))}
      </div>
      <div className='mb-5'>
        <button
          className='ms-3 btn btn-outline-primary'
          onClick={handleChestAssetsMigrate}
        >
          Migrate
        </button>
      </div>
    </div>
  );
};
