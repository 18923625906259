import axios from 'axios';
import { apiAddress } from 'config/dapp-config';
import {
  NonFungibleTokenBalanceApiType,
  TokenBalanceType
} from 'types/MultiversX';

export const useMultiversXApi = () => {
  const createTokenTicker = (id: string): string => {
    return id.split('-')[0];
  };

  const getTokenBalanceFromApi = async (
    address: string,
    tokenId: string
  ): Promise<TokenBalanceType | undefined> => {
    try {
      const queryUrl = `${apiAddress}/accounts/${address}/tokens/${tokenId}`;
      const { data } = await axios.get<TokenBalanceType>(queryUrl);

      return data;
    } catch (err) {
      console.error('getTokenBalanceFromApi failed:', err);
    }

    return undefined;
  };

  const getTokenBalances = async (
    address: string,
    tokenIds: string[]
  ): Promise<TokenBalanceType[]> => {
    try {
      const queryUrl = `${apiAddress}/accounts/${address}/tokens?size=10000&identifiers=${tokenIds.join(
        ','
      )}`;
      const { data } = await axios.get<TokenBalanceType[]>(queryUrl);
      // data.reverse(); // because api returns array in reverse order

      const items: TokenBalanceType[] = [];
      for (const tokenId of tokenIds) {
        let j;
        for (j = 0; j < data.length; j++) {
          if (data[j].identifier === tokenId) {
            items.push(data[j]);
            break;
          }
        }
        if (j === data.length) {
          items.push({
            identifier: tokenId,
            ticker: createTokenTicker(tokenId),
            decimals: -1, // intentional
            balance: '0'
          });
        }
      }

      return items;
    } catch (err) {
      console.error('getTokenBalances failed:', err);
    }

    return [];
  };

  const getNFTBalances = async (
    address: string,
    tokenIds: string[]
  ): Promise<NonFungibleTokenBalanceApiType[]> => {
    try {
      const queryUrl = `${apiAddress}/accounts/${address}/nfts?size=10000&collections=${tokenIds.join(
        ','
      )}`;
      const { data } = await axios.get<NonFungibleTokenBalanceApiType[]>(
        queryUrl
      );

      let items: NonFungibleTokenBalanceApiType[] = [];
      for (const tokenId of tokenIds) {
        const sameToken = data.filter((d) => d.collection === tokenId);
        if (sameToken.length > 0) {
          items = items.concat(sameToken);
        }
      }
      return items;
    } catch (err) {
      console.error('getNFTBalances failed:', err);
    }

    return [];
  };

  const getNftDetailsByIdentifiers = async (
    identifiers: string[]
  ): Promise<NonFungibleTokenBalanceApiType[]> => {
    try {
      const queryUrl = `${apiAddress}/nfts?size=10000&identifiers=${identifiers.join(
        ','
      )}`;
      const { data } = await axios.get<NonFungibleTokenBalanceApiType[]>(
        queryUrl
      );
      return data;
    } catch (err) {
      console.error('getNftDetailsByIdentifiers failed:', err);
    }

    return [];
  };

  const getAddressNftsCount = async (address: string): Promise<number> => {
    try {
      const queryUrl = `${apiAddress}/accounts/${address}/nfts/count`;
      const { data } = await axios.get<number>(queryUrl);
      return data;
    } catch (err) {
      console.error('getAddressNftsCount failed:', err);
    }

    return 0;
  };

  return {
    getTokenBalanceFromApi,
    getTokenBalances,
    getNFTBalances,
    getNftDetailsByIdentifiers,
    getAddressNftsCount
  };
};
