import React, { useState } from 'react';
import { AccountNft } from 'types';
import { EsdtTokenType } from 'types/MultiversX';
import { StakeableAssetType } from 'types/store';

export const OpenChestTypeModal = ({
  chest,
  handleOpenAction
}: {
  chest?: EsdtTokenType;
  handleOpenAction: (quantity: number, nonce?: number) => Promise<void>;
}) => {
  const mp4Mapping: { [key: string]: string } = {
    'LANDCHEST-1f6b76-01':
      'https://nft.ageofzalmoxis.com/ipfs/QmT1J6L5QztvLNHiBzfMnLf6MGpqvnDqVCmvx9eGLfkoru/SFT_Continental.mp4',
    'LANDCHEST-1f6b76-02':
      'https://nft.ageofzalmoxis.com/ipfs/QmT1J6L5QztvLNHiBzfMnLf6MGpqvnDqVCmvx9eGLfkoru/SFT_Steepe.mp4',
    'LANDCHEST-1f6b76-03':
      'https://nft.ageofzalmoxis.com/ipfs/QmT1J6L5QztvLNHiBzfMnLf6MGpqvnDqVCmvx9eGLfkoru/SFT_Panonic.mp4',
    'LANDCHEST-1f6b76-04':
      'https://nft.ageofzalmoxis.com/ipfs/QmT1J6L5QztvLNHiBzfMnLf6MGpqvnDqVCmvx9eGLfkoru/SFT_Pontic.mp4'
  };
  const [selectedQty, setSelectedQty] = useState(1);
  const [upperBound] = useState(Math.min(parseInt(chest?.amount ?? '1'), 100));
  const toggleQty = (by: number) => {
    let newQty = selectedQty + by;
    if (newQty < 1) {
      newQty = 1;
    }

    if (newQty > upperBound) {
      newQty = upperBound;
    }
    setSelectedQty(newQty);
  };
  const handleOpenChests = async (isMax?: boolean) => {
    await handleOpenAction(isMax ? upperBound : selectedQty, chest?.nonce);
  };

  return chest === undefined ? null : (
    <div className='modal-dialog modal-dialog-centered' role='document'>
      <div className='modal-content'>
        <div className='modal-header border-0'>
          <h2 className='modal-title'>
            Open {chest.name}
            {parseInt(chest.amount) > 1 ? 's' : ''}
          </h2>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
          ></button>
        </div>
        <div className='modal-body bg-black'>
          <div className='row'>
            <div className='col-12 text-center'>
              <video autoPlay muted loop className='img-responsive'>
                <source src={mp4Mapping[chest.fullIdentifier]} />
              </video>
            </div>
            <div className='col-12 d-flex justify-content-between align-items-center p-5'>
              <button
                className='btn btn-outline-danger'
                onClick={() => toggleQty(-1)}
              >
                -
              </button>
              <h3 className='text-white'>
                {selectedQty}/{upperBound}
              </h3>
              <button
                className='btn btn-outline-success'
                onClick={() => toggleQty(1)}
              >
                +
              </button>
            </div>
            <div className='col-12 text-center text-primary'>
              <small>
                You have {chest.amount} {chest.name}
                {parseInt(chest.amount) > 1 ? 's' : ''} to open. The maximum
                number of chests that can be opened in one transaction is 100.
              </small>
            </div>
          </div>
        </div>
        <div className='modal-footer w-100 d-flex justify-content-between border-0'>
          <button
            type='button'
            className='btn btn-danger light'
            data-bs-dismiss='modal'
          >
            Close
          </button>
          <div>
            <button
              type='button'
              className='btn btn-primary me-2'
              data-bs-dismiss='modal'
              onClick={() => handleOpenChests()}
            >
              Open {selectedQty}
            </button>
            {upperBound === 100 ? (
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={() => handleOpenChests(true)}
              >
                Open 100
              </button>
            ) : (
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={() => handleOpenChests(true)}
              >
                Open All
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
