import { useEffect } from 'react';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  LAND_PLOT_STAKING_SC_ADDRESS,
  LAND_PLOT_TOKEN_IDENTIFIER
} from 'config/dapp-config';
import { useLandPlotStaking } from 'hooks/contracts/landPlotStaking/useLandPlotStaking';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';
import { useMultiversXApi } from 'hooks/MultiversX/useMultiversXApi';
import {
  LandPlotStakingPoolState,
  selectStatus,
  setLandPlotStakingPool,
  setStatus
} from 'store/slices/kosonv2/land-plot-staking-pool';
import { StakedAssetType } from 'types/store';
import { parseStakedLandPlotTokenBalanceType } from 'utils';

export const LandPlotStakingPoolLoader = () => {
  const { address } = useGetAccountInfoWrapper();
  const { hasPendingTransactions } = useGetPendingTransactions();

  const { getLandPlotStakingContext } = useLandPlotStaking();
  const { getNftDetailsByIdentifiers, getNFTBalances } = useMultiversXApi();
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);

  const loadLandPlotStakingPool =
    async (): Promise<LandPlotStakingPoolState> => {
      const scContext = await getLandPlotStakingContext(address);
      const scPlotsBalance = await getNFTBalances(
        LAND_PLOT_STAKING_SC_ADDRESS,
        [LAND_PLOT_TOKEN_IDENTIFIER]
      );

      const userStakedApiNfts = await getNftDetailsByIdentifiers(
        scContext.stakedAssets.map(
          (stakedAsset: any) => stakedAsset.asset.fullIdentifier
        )
      );

      const stakedLandPlots: StakedAssetType[] = userStakedApiNfts
        .map((apiLandPlotNft) => {
          const stakedAsset = scContext.stakedAssets.find(
            (asset: any) =>
              asset.asset.fullIdentifier === apiLandPlotNft.identifier
          );

          if (!stakedAsset) {
            return undefined;
          }

          return parseStakedLandPlotTokenBalanceType(
            {
              ...apiLandPlotNft,
              balance: stakedAsset.asset.amount
            },
            stakedAsset?.stakeEpoch ?? -1,
            stakedAsset?.unstakeFee ?? '-1'
          );
        })
        .filter(
          (stakedLandPlot) => stakedLandPlot !== undefined
        ) as StakedAssetType[];

      return {
        totalStakedSftsCount: scPlotsBalance.reduce(
          (acc, crt) => acc + parseInt(crt.balance),
          0
        ),
        totalStakedScore: scContext.aggregatedScore,
        userStakedSftsCount: scContext.stakedAssets.reduce(
          (acc, crt) => acc + parseInt(crt.asset.amount),
          0
        ),
        userStakedScore: scContext.userScore,
        stakedLandPlots,
        pendingRewards: scContext.pendingRewards
      };
    };

  // load soul nft staking pool
  useEffect(() => {
    if (status !== 'loading' && hasPendingTransactions) {
      return;
    }

    loadLandPlotStakingPool()
      .then((state) => {
        dispatch(setLandPlotStakingPool(state));
        dispatch(setStatus('succeeded'));
      })
      .catch((err) => {
        console.error(
          'Something went wrong when loading land plots staking pool: ',
          err
        );
        dispatch(setStatus('failed'));
      });
  }, [hasPendingTransactions, address]);

  return <></>;
};
