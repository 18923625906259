import { getKosonBalance, getValidAccountNfts } from 'api/apiRequests';
import {
  LAND_CHEST_CONTENT_COLLECTIONS,
  OLD_LAND_PLOT_TOKEN_IDENTIFIER,
  ORIGIN_SOULS_TOKEN_IDENTIFIERS,
  ALL_SOUL_NFT_TOKEN_IDENTIFIERS
} from 'config/dapp-config';
import {
  fillPendingRewards,
  fillSummoningInfo,
  transformApiNft
} from 'contexts/Web3Context/helpers/nftUtils';
import {
  DefaultInventoryData,
  InventoryData
} from 'store/slices/accountInfo/inventory';
import { AccountNft } from 'types';

export const getAccountInventory = async (
  address: string
): Promise<InventoryData> => {
  const validNfts = await getValidAccountNfts(address);
  if (!validNfts.success) {
    return DefaultInventoryData;
  }
  let parsedAccountNfts = validNfts.data.map((nft: any) =>
    transformApiNft(nft)
  );
  parsedAccountNfts = await fillSummoningInfo(parsedAccountNfts);
  parsedAccountNfts = await fillPendingRewards(parsedAccountNfts);

  const soulsNftData = getSoulsData(parsedAccountNfts);
  const chestNftData = getLandChestData(parsedAccountNfts);
  const plotsNftData = getLandPlotsData(parsedAccountNfts);
  const itemsNftData = getItemsData(parsedAccountNfts);
  const kosonBalanceResult = await getKosonBalance(address);
  const kosonBalance =
    kosonBalanceResult.success && kosonBalanceResult.data.length > 0
      ? kosonBalanceResult.data[0].balance
      : '0';

  return {
    souls: soulsNftData.nfts,
    landChests: chestNftData,
    landPlots: plotsNftData,
    items: itemsNftData,
    koson: kosonBalance,
    hasFullOriginSet: soulsNftData.hasFullSet,
    canSummonDeathSoul: soulsNftData.canSummonDeathSoul,
    canSummonRegularSoul: soulsNftData.canSummonRegularSoul
  };
};

const getSoulsData = (nfts: AccountNft[]) => {
  const soulNfts = nfts.filter((nft) =>
    ALL_SOUL_NFT_TOKEN_IDENTIFIERS.includes(nft.collection)
  );
  const identifiers = soulNfts
    .filter((nft) => {
      const index = ALL_SOUL_NFT_TOKEN_IDENTIFIERS.indexOf(nft.collection);
      return index > 0 && nft.specialSummoningCount === 0;
    })
    .map((nft) => nft.collection);
  let hasFullSet = true;
  for (
    let i = 1;
    i < ORIGIN_SOULS_TOKEN_IDENTIFIERS.length && hasFullSet;
    i++
  ) {
    hasFullSet =
      hasFullSet && identifiers.includes(ORIGIN_SOULS_TOKEN_IDENTIFIERS[i]);
  }
  return {
    nfts: soulNfts,
    hasFullSet,
    //TODO: change this as it cannot be correct. Must account for 1/1 summonings
    canSummonDeathSoul: hasFullSet,
    canSummonRegularSoul:
      soulNfts.filter((nft) => (nft.regularSummoningCount ?? 6) < 6).length >= 2
  };
};

const getLandChestData = (nfts: AccountNft[]) => {
  const chests = nfts.filter((nft) => nft.isLandChest);
  return chests;
};

const getLandPlotsData = (nfts: AccountNft[]) => {
  const plots = nfts.filter(
    (nft) => nft.collection === OLD_LAND_PLOT_TOKEN_IDENTIFIER
  );
  return plots;
};

const getItemsData = (nfts: AccountNft[]) => {
  const items = nfts.filter(
    (nft) =>
      nft.isTokenNft &&
      LAND_CHEST_CONTENT_COLLECTIONS.includes(nft.collection) &&
      nft.collection !== OLD_LAND_PLOT_TOKEN_IDENTIFIER
  );
  return items;
};
