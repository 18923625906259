import { faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import { toLocaleStringOptionsNoDecimals } from 'config/dapp-config';
import { formatBigNumberStringMaxPrecision } from 'utils';

export const StakedNftsScoreCard = ({
  userStakedScore,
  totalStakedScore
}: {
  userStakedScore: number;
  totalStakedScore: number;
}) => {
  return (
    <SimpleStatsCard
      title={'Stake score'}
      value={`${userStakedScore.toLocaleString(
        undefined,
        toLocaleStringOptionsNoDecimals
      )}/${totalStakedScore.toLocaleString(
        undefined,
        toLocaleStringOptionsNoDecimals
      )}`}
      icon={faMoneyBillTrendUp}
    />
  );
};

export const StakedNftsCountCard = ({
  userStakedCount,
  totalStakedCount
}: {
  userStakedCount: number;
  totalStakedCount: number;
}) => {
  return (
    <SimpleStatsCard
      title={'Staked NFTs'}
      value={`${userStakedCount.toLocaleString(
        undefined,
        toLocaleStringOptionsNoDecimals
      )}/${totalStakedCount.toLocaleString(
        undefined,
        toLocaleStringOptionsNoDecimals
      )}`}
      icon={faMoneyBillTrendUp}
    />
  );
};

export const UnbondingFeeCard = ({
  totalUnbondingFee,
  epochsUntilFreeUnbonding,
  userStakedScore
}: {
  totalUnbondingFee: string;
  epochsUntilFreeUnbonding: number;
  userStakedScore: number;
}) => {
  const value =
    userStakedScore === 0
      ? 'N/A'
      : totalUnbondingFee === '0' || epochsUntilFreeUnbonding <= 0
      ? 'None, you can unstake your assets for free at any moment'
      : `${formatBigNumberStringMaxPrecision(
          totalUnbondingFee
        )} $KOSON, or free in ${epochsUntilFreeUnbonding} epochs`;
  return (
    <SimpleStatsCard
      title={'Unbonding fee'}
      value={value}
      icon={faMoneyBillTrendUp}
    />
  );
};
