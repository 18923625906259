import React from 'react';
import './style.css';
import { useSelector } from 'react-redux';
import { selectKosonStakingPools } from 'store/slices/kosonv2/koson-staking-pools';
import { KosonStakingPoolRow } from './KosonStakingPoolRow';

const KosonStakingPoolSelector = () => {
  const pools = useSelector(selectKosonStakingPools);
  return (
    <>
      <div className='row mb-4'>
        <div className='col-12'>
          <h1>Koson staking</h1>
          <h6>
            Stake any $KOSON variant (Primordial, Esoteric, Ancient) and earn
            extra $KOSONs, each day, autocompounded
          </h6>
        </div>
      </div>
      {Object.keys(pools).map((poolKey: string, index: number) => (
        <KosonStakingPoolRow
          pool={pools[Number(poolKey)]}
          key={`koson-staking-pools-${index}`}
        />
      ))}
    </>
  );
};

export default KosonStakingPoolSelector;
