import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchNetworkStats } from 'api/apiRequests';
import { setEpoch, setStatus } from 'store/slices/kosonv2/blockchain-stats';

export const BlockchainStatsLoader = () => {
  const dispatch = useDispatch();

  const loadCurrentEpoch = async (): Promise<number> => {
    const bcStats = await fetchNetworkStats();

    if (bcStats.success) {
      return bcStats.data.epoch;
    } else {
      return 0;
    }
  };

  // load staking pool contexts
  useEffect(() => {
    loadCurrentEpoch()
      .then((currentEpoch) => {
        dispatch(setEpoch(currentEpoch));
        dispatch(setStatus('succeeded'));
      })
      .catch((err) => {
        console.error('Failed to load blockchain stats', err);
        dispatch(setStatus('failed'));
      });
  }, []);

  return <></>;
};
