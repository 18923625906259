import React from 'react';
import { faWind } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { Loading } from 'components/Loading';
import InventoryFilterModalContent from 'components/Modals/InventoryFilterModalContent';
import ListNftModal from 'components/Modals/ListNftModal';
import { InventoryThumbnail } from 'components/NFT/InventoryThumbnail';
import {
  LAND_CHEST_CONTENT_COLLECTIONS,
  LAND_CHEST_TOKEN_IDENTIFIER,
  ALL_SOUL_NFT_TOKEN_IDENTIFIERS,
  STAKED_KOSON_TOKEN_IDENTIFIERS
} from 'config/dapp-config';
import {
  selectInventoryAssets,
  selectStatus
} from 'store/slices/kosonv2/account';
import { AccountNft } from 'types';
import InventoryCardInfo from './CardInfo';

const Inventory = () => {
  const status = useSelector(selectStatus);
  const { souls, landChests, landPlots, items } = useSelector(
    selectInventoryAssets
  );

  const [collectionsToDisplay, setCollectionsToDisplay] = React.useState(
    ALL_SOUL_NFT_TOKEN_IDENTIFIERS.concat([LAND_CHEST_TOKEN_IDENTIFIER])
      .concat(STAKED_KOSON_TOKEN_IDENTIFIERS)
      .concat(LAND_CHEST_CONTENT_COLLECTIONS)
  );
  const [selectedNft] = React.useState<AccountNft>();
  const updateFilters = (identifiers: string[]) => {
    setCollectionsToDisplay(identifiers);
  };

  return status === 'loading' ? (
    <Loading coverFullPage={true} />
  ) : (
    <>
      <div className='row'>
        <InventoryCardInfo
          soulsCount={souls.length}
          landPlotsCount={landPlots.reduce(
            (acc, nft) => acc + parseInt(nft.amount),
            0
          )}
          landChestsCount={landChests.reduce(
            (acc, nft) => acc + parseInt(nft.amount),
            0
          )}
          itemsCount={items.reduce((acc, nft) => acc + parseInt(nft.amount), 0)}
        />
      </div>
      <div className='row'>
        <div className='col-lg-12 d-flex justify-content-between'>
          <h3>Inventory</h3>
          <button
            className='btn btn-outline-primary text-white'
            style={{ minWidth: '175px' }}
            data-bs-toggle='modal'
            data-bs-target='#inventoryFiltersModal'
          >
            Filter
          </button>
          <div className='modal fade' id='inventoryFiltersModal'>
            <InventoryFilterModalContent onApply={updateFilters} />
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        {souls
          .filter((nft) => collectionsToDisplay.includes(nft.tokenIdentifier))
          .map((nft, i) => (
            <InventoryThumbnail
              nft={nft}
              key={`inventory-soul-nfts-item-key-${i}`}
            />
          ))}
        {landChests
          .filter((nft) => collectionsToDisplay.includes(nft.tokenIdentifier))
          .map((nft, i) => (
            <InventoryThumbnail
              nft={nft}
              key={`inventory-land-chest-nfts-item-key-${i}`}
            />
          ))}
        {items
          .concat(landPlots)
          .filter((nft) => collectionsToDisplay.includes(nft.tokenIdentifier))
          .map((nft, i) => (
            <InventoryThumbnail
              nft={nft}
              key={`inventory-token-nft-item-key-${i}`}
            />
          ))}

        {souls.length === 0 &&
          landChests.length === 0 &&
          landPlots.length === 0 &&
          items.length === 0 && (
            <div className='text-center mt-5'>
              <FontAwesomeIcon icon={faWind} className='mt-3 mb-5' size='10x' />
              <h3 className='mt-2'>No items to display in your inventory</h3>
            </div>
          )}
        <div className='modal fade' id='random-test-modal'>
          <ListNftModal nft={selectedNft} />
        </div>
      </div>
    </>
  );
};

export default Inventory;
