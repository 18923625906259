import {
  ANCIENT_KOSON_TOKEN_IDENTIFIER,
  ESOTERIC_KOSON_TOKEN_IDENTIFIER,
  PRIMORDIAL_KOSON_TOKEN_IDENTIFIER
} from 'config/dapp-config';
import { ReactComponent as AncientKosonLogo } from '../../assets/images/aoz/Ancient.svg';
import { ReactComponent as EsotericKosonLogo } from '../../assets/images/aoz/Esoteric.svg';
import { ReactComponent as PrimordialKosonLogo } from '../../assets/images/aoz/Primordial.svg';

export const KosonSymbol = ({
  tokenIdentifier
}: {
  tokenIdentifier: string;
}) => {
  return tokenIdentifier === ANCIENT_KOSON_TOKEN_IDENTIFIER ? (
    <AncientKosonLogo
      className='mb-1 me-2'
      style={{ width: '20px', height: '34px', marginLeft: '5px' }}
    />
  ) : tokenIdentifier === ESOTERIC_KOSON_TOKEN_IDENTIFIER ? (
    <EsotericKosonLogo
      className='mb-1 me-2'
      style={{ width: '20px', height: '34px', marginLeft: '5px' }}
    />
  ) : tokenIdentifier === PRIMORDIAL_KOSON_TOKEN_IDENTIFIER ? (
    <PrimordialKosonLogo
      className='mb-1 me-2'
      style={{ width: '20px', height: '34px', marginLeft: '5px' }}
    />
  ) : null;
};
