import React, { useEffect, useState } from 'react';
import { BigNumber } from 'bignumber.js';
import { formatBigNumberString } from 'utils';

export const SafeNumberInput = ({
  max,
  onChange,
  hideMaxBalance,
  ...restProps
}: {
  max?: string;
  onChange: (value: string) => void;
  [key: string]: any;
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
  }, [max]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  function isNumber(text: string) {
    return /^-?\d*\.?\d+$/.test(text);
  }

  const handleInputSelect = (e: string) => {
    const lvalue = e.replace(',', '.');
    const separatorCount = (lvalue.match(/[.]/g) || []).length;

    if (separatorCount > 1) {
      return;
    }

    if (lvalue.endsWith('.') || lvalue.endsWith(',')) {
      setValue(lvalue);
      return;
    }
    if (
      lvalue === '' ||
      lvalue === '.' ||
      lvalue === ',' ||
      !isNumber(lvalue)
    ) {
      setValue('0');
      return;
    }

    if (parseFloat(lvalue) < 0) {
      setValue('0');
      return;
    }

    if (lvalue.includes('.')) {
      const parts = lvalue.split('.');
      if (parts[1].length > 18 || parts.length > 2) return;
    }

    const targetAmount = new BigNumber(lvalue).shiftedBy(18);
    if (max && targetAmount.isGreaterThan(new BigNumber(max))) {
      setValue(new BigNumber(max).shiftedBy(-18).toString());
    } else {
      setValue(lvalue);
    }
  };

  return (
    <div style={{ position: 'relative', marginBottom: '20px' }}>
      <input
        type='text'
        value={value}
        onChange={(e) => handleInputSelect(e.target.value)}
        {...restProps}
      />
      {max && !hideMaxBalance && (
        <small
          className='ms-2'
          style={{
            position: 'absolute',
            left: '0',
            bottom: '-20px',
            cursor: 'pointer'
          }}
          onClick={() => {
            setValue(new BigNumber(max).shiftedBy(-18).toString());
          }}
        >
          Max Balance:{' '}
          <strong className='important-text'>
            {formatBigNumberString(max)}
          </strong>
        </small>
      )}
    </div>
  );
};
