import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KYCStatusType, KYCStatusType_DEFAULT } from 'hooks/useAccountInfo';
import { UnbondingKosonEsdtType } from 'types/koson-staking-pool';
import { EsdtTokenType, TokenInfoMapType } from 'types/MultiversX';
import {
  SoulNftType,
  StakeableAssetType,
  StoreStateStatusType,
  StoreStateType
} from 'types/store';

interface AssetsBalanceType {
  souls: TokenInfoMapType<SoulNftType>;
  landPlots: TokenInfoMapType<StakeableAssetType>;
  landChests: TokenInfoMapType<EsdtTokenType>;
  items: TokenInfoMapType<EsdtTokenType>;
  unbondingKosons: TokenInfoMapType<UnbondingKosonEsdtType>;
  unmigratedAssets: TokenInfoMapType<EsdtTokenType>;
}

export interface AccountInfo {
  address: string;
  herotag: string;
  kycStatus: KYCStatusType;
  tokenBalance: TokenInfoMapType<EsdtTokenType>;
  assetsBalance: AssetsBalanceType;
}

const defaultState: AccountInfo = {
  address: '',
  herotag: '',
  kycStatus: KYCStatusType_DEFAULT,
  tokenBalance: {},
  assetsBalance: {
    souls: {},
    landPlots: {},
    landChests: {},
    items: {},
    unbondingKosons: {},
    unmigratedAssets: {}
  }
};

const initialState: StoreStateType<AccountInfo> = {
  data: defaultState,
  status: 'loading',
  error: null
};

const accountInfoSlice = createSlice({
  name: 'accountInfo',
  initialState,
  reducers: {
    setStatus: (
      state: StoreStateType<AccountInfo>,
      action: PayloadAction<StoreStateStatusType>
    ) => {
      state.status = action.payload;
    },
    setAccount: (
      state: StoreStateType<AccountInfo>,
      action: PayloadAction<any>
    ) => {
      state.data.address = action.payload.address;
      state.data.herotag = action.payload.herotag;
      console.log('setAccount->address', state.data.address);
      console.log('setAccount->herotag', state.data.herotag);
    },
    setKYCStatus: (
      state: StoreStateType<AccountInfo>,
      action: PayloadAction<KYCStatusType>
    ) => {
      state.data.kycStatus = action.payload;
      console.log('setAccount->KYCStatus', state.data.kycStatus);
    },
    setTokenBalances: (
      state: StoreStateType<AccountInfo>,
      action: PayloadAction<EsdtTokenType[]>
    ) => {
      const map: TokenInfoMapType<EsdtTokenType> = {};
      for (const token of action.payload) {
        if (map[token.tokenIdentifier] === undefined) {
          map[token.tokenIdentifier] = [];
        }
        map[token.tokenIdentifier].push(token);
      }

      state.data.tokenBalance = map;
      console.log('setTokenBalances->tokenBalances', state.data.tokenBalance);
    },
    setSoulsBalance: (
      state: StoreStateType<AccountInfo>,
      action: PayloadAction<SoulNftType[]>
    ) => {
      const map: TokenInfoMapType<SoulNftType> = {};
      for (const token of action.payload) {
        if (map[token.tokenIdentifier] === undefined) {
          map[token.tokenIdentifier] = [];
        }
        map[token.tokenIdentifier].push(token);
      }

      state.data.assetsBalance.souls = map;
      console.log('setSoulsBalance->souls', state.data.assetsBalance.souls);
    },
    setLandPlotsBalance: (
      state: StoreStateType<AccountInfo>,
      action: PayloadAction<StakeableAssetType[]>
    ) => {
      const map: TokenInfoMapType<StakeableAssetType> = {};
      for (const token of action.payload) {
        if (map[token.tokenIdentifier] === undefined) {
          map[token.tokenIdentifier] = [];
        }
        map[token.tokenIdentifier].push(token);
      }

      state.data.assetsBalance.landPlots = map;
      console.log(
        'setLandPlotsBalance->landPlots',
        state.data.assetsBalance.landPlots
      );
    },
    setLandChestsBalance: (
      state: StoreStateType<AccountInfo>,
      action: PayloadAction<EsdtTokenType[]>
    ) => {
      const map: TokenInfoMapType<EsdtTokenType> = {};
      for (const token of action.payload) {
        if (map[token.tokenIdentifier] === undefined) {
          map[token.tokenIdentifier] = [];
        }
        map[token.tokenIdentifier].push(token);
      }

      state.data.assetsBalance.landChests = map;
      console.log(
        'setLandChestsBalance->landChests',
        state.data.assetsBalance.landChests
      );
    },
    setItemsBalance: (
      state: StoreStateType<AccountInfo>,
      action: PayloadAction<EsdtTokenType[]>
    ) => {
      const map: TokenInfoMapType<EsdtTokenType> = {};
      for (const token of action.payload) {
        if (map[token.tokenIdentifier] === undefined) {
          map[token.tokenIdentifier] = [];
        }
        map[token.tokenIdentifier].push(token);
      }

      state.data.assetsBalance.items = map;
      console.log('setItemsBalance->items', state.data.assetsBalance.items);
    },
    setUnbondingKosonsBalance: (
      state: StoreStateType<AccountInfo>,
      action: PayloadAction<UnbondingKosonEsdtType[]>
    ) => {
      const map: TokenInfoMapType<UnbondingKosonEsdtType> = {};
      for (const token of action.payload) {
        if (map[token.tokenIdentifier] === undefined) {
          map[token.tokenIdentifier] = [];
        }
        map[token.tokenIdentifier].push(token);
      }

      state.data.assetsBalance.unbondingKosons = map;
      console.log(
        'setUnbondingKosonsBalance->unbondingKosons',
        state.data.assetsBalance.unbondingKosons
      );
    },
    setUnmigratedAssets: (
      state: StoreStateType<AccountInfo>,
      action: PayloadAction<EsdtTokenType[]>
    ) => {
      const map: TokenInfoMapType<EsdtTokenType> = {};
      for (const token of action.payload) {
        if (map[token.tokenIdentifier] === undefined) {
          map[token.tokenIdentifier] = [];
        }
        map[token.tokenIdentifier].push(token);
      }

      state.data.assetsBalance.unmigratedAssets = map;
      console.log(
        'setUnmigratedAssets->unmigratedAssets',
        state.data.assetsBalance.unmigratedAssets
      );
    }
  }
});

export const {
  setStatus,
  setAccount,
  setKYCStatus,
  setTokenBalances,
  setSoulsBalance,
  setLandPlotsBalance,
  setLandChestsBalance,
  setItemsBalance,
  setUnbondingKosonsBalance,
  setUnmigratedAssets
} = accountInfoSlice.actions;

export default accountInfoSlice.reducer;
