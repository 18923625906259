import { U64Value, BigUIntValue } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { getSummoningCounts } from 'api/backendRequests';
import {
  KOSON_STAKING_POOL_SC_ADDRESSES,
  LAND_PLOT_TOKEN_IDENTIFIER,
  ORIGIN_SOULS_TOKEN_IDENTIFIERS,
  SUMMONED_SOULS_TOKEN_IDENTIFIERS,
  STAKED_KOSON_TOKEN_IDENTIFIERS
} from 'config/dapp-config';
import { AccountNft, MarketplaceListing } from 'types';
import { getNftThumbnailUrl } from '../../../utils';
import { getTimeUntil, getTimeUntilShort, int2hex } from './generalUtils';
import { getSmartContract, Provider, Parser } from './getScObj';

export const transformStakedNft = (
  collection: string,
  nonce: number,
  amount: number,
  claimDate?: number
) => {
  let nonceHex = nonce.toString(16);
  if (nonceHex.length % 2 === 1) {
    nonceHex = '0' + nonceHex;
  }
  const identifier = `${collection}-${nonceHex}`;
  const item: AccountNft = {
    collection: collection,
    identifier: identifier,
    nonce: nonce,
    amount: amount,
    unparsedAmount: amount.toString(),
    isOrigin: ORIGIN_SOULS_TOKEN_IDENTIFIERS.includes(collection),
    isSoul: true, //SoulsNFTCollections.includes(collection),
    isNotMigratedOrigin: ORIGIN_SOULS_TOKEN_IDENTIFIERS[0] === collection,
    isLandChest: false, //LandChestNFTCollection === collection,
    isStakedKosonBatch: STAKED_KOSON_TOKEN_IDENTIFIERS.includes(collection),
    name: getNftName(collection, nonce),
    thumbnailUrl: getThumbnailUrl(collection, nonce),
    fullResourceUrl: getNftUrl(collection, nonce),
    description: '',
    ETAUntilClaim: claimDate ? getTimeUntil(claimDate) : undefined,
    canBeClaimed: claimDate
      ? claimDate * 1000 < new Date().getTime()
      : undefined,
    isTokenNft: false, //LAND_CHEST_CONTENT_COLLECTIONS.includes(collection),
    videoResourceUrl: getVideoUrl({ collection, nonce })
  };
  return item;
};

export const transformApiNft = (nft: any) => {
  const isStakedKosonBatch = STAKED_KOSON_TOKEN_IDENTIFIERS.includes(
    nft.collection
  );
  const isTokenNft = false; //LAND_CHEST_CONTENT_COLLECTIONS.includes(nft.collection);
  const item: AccountNft = {
    collection: nft.collection,
    identifier: nft.identifier,
    nonce: nft.nonce,
    amount: isStakedKosonBatch
      ? new BigNumber(nft.balance)
          .dividedBy(new BigNumber(10).pow(18))
          .toNumber()
      : parseInt(nft.balance),
    unparsedAmount: nft.balance,
    isOrigin: ORIGIN_SOULS_TOKEN_IDENTIFIERS.includes(nft.collection),
    isSoul: false, //SoulsNFTCollections.includes(nft.collection),
    isNotMigratedOrigin: ORIGIN_SOULS_TOKEN_IDENTIFIERS[0] === nft.collection,
    isLandChest: false, //LandChestNFTCollection === nft.collection,
    isStakedKosonBatch: isStakedKosonBatch,
    name: nft.name,
    thumbnailUrl: getThumbnailUrl(nft.collection, nft.nonce),
    fullResourceUrl: getNftUrl(nft.collection, nft.nonce),
    videoResourceUrl: getVideoUrl(nft),
    description: '',
    stakeDay: isStakedKosonBatch
      ? parseInt(Buffer.from(nft.attributes, 'base64').toString('hex'), 16)
      : undefined,
    isTokenNft: isTokenNft,
    rarity: isTokenNft ? nft.name.split(' ')[0] : undefined
  };

  return item;
};

export const transformMarketplaceListing = (nft: any) => {
  // const listingDetails: NFTListingDetails = {
  //   listingId: nft.listingId,
  //   tokenIdentifier: nft.tokenIdentifier,
  //   nonce: nft.nonce,
  //   nftName: nameSplit[0],
  //   royalties: nft.royalties,
  //   minBid: nft.minBid,
  //   maxBid: nft.maxBid,
  //   currentBid: nft.currentBid,
  //   originalOwner: nft.ownerAddress,
  //   winnerAddress: nft.winnerAddress,
  //   startTime: new Date(Date.parse(`${nft.startTime}Z`)),
  //   endTime: new Date(Date.parse(`${nft.endTime}Z`)),
  //   listingType: nft.listingType == 0 ? 'buyout' : 'auction',
  //   soulType: getSoulType(nft.tokenIdentifier),
  //   imageUrl: getNftUrl(nft.tokenIdentifier),
  //   thumbnailImageUrl: getThumbnailUrl(nft.tokenIdentifier),
  //   description: nft.description,
  //   sixthSummons: `${nft.sixthSummons}`,
  //   seventhSummon: `${nft.seventhSummon}`,
  //   specialAbility: getSpecialAbility(nft.tokenIdentifier)
  // };
  const endTime = new Date(Date.parse(`${nft.endTime}Z`)).getTime() / 1000;
  const regularSummoningCount = parseInt(nft.sixthSummons.split('/')[0]);
  const specialSummoningCount =
    nft.seventhSummon === 'N/A' || nft.seventhSummon === null
      ? undefined
      : parseInt(nft.seventhSummon.split('/')[0]);
  const isBuyout = nft.denominatedMinBid === nft.denominatedMaxBid;
  const minBid = isBuyout
    ? new BigNumber(nft.denominatedMinBid)
    : nft.currentBid === 0
    ? new BigNumber(nft.denominatedMinBid)
    : new BigNumber(nft.denominatedCurrentBid);
  const item: MarketplaceListing = {
    listingId: nft.listingId,
    collection: nft.tokenIdentifier,
    identifier: `${nft.tokenIdentifier}-${int2hex(nft.nonce)}`,
    nonce: nft.nonce,
    isOrigin: ORIGIN_SOULS_TOKEN_IDENTIFIERS.includes(nft.tokenIdentifier),
    name: nft.nftName,
    thumbnailUrl: getThumbnailUrl(nft.tokenIdentifier, nft.nonce),
    fullResourceUrl: getNftUrl(nft.tokenIdentifier, nft.nonce),
    isBuyout: isBuyout,
    minBid: minBid,
    maxBid: new BigNumber(nft.denominatedMaxBid),
    originalOwnerAddress: nft.ownerAddress,
    currentWinnerAddress:
      nft.winnerAddress ===
      'erd1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq6gq4hu'
        ? 'N/A'
        : nft.winnerAddress,
    specialAbility: getSpecialAbility(nft.tokenIdentifier),
    startTime: Math.floor(
      new Date(Date.parse(`${nft.startTime}Z`)).getTime() / 1000
    ),
    endTime: Math.floor(endTime),
    regularSummoningCount: regularSummoningCount,
    specialSummoningCount: specialSummoningCount,
    hasExpired: getTimeUntilShort(endTime).startsWith('-'),
    description: nft.description
  };

  return item;
};

export const transformMarketplaceListing2 = (nft: any) => {
  // const listingDetails: NFTListingDetails = {
  //   listingId: nft.listingId,
  //   tokenIdentifier: nft.tokenIdentifier,
  //   nonce: nft.nonce,
  //   nftName: nameSplit[0],
  //   royalties: nft.royalties,
  //   minBid: nft.minBid,
  //   maxBid: nft.maxBid,
  //   currentBid: nft.currentBid,
  //   originalOwner: nft.ownerAddress,
  //   winnerAddress: nft.winnerAddress,
  //   startTime: new Date(Date.parse(`${nft.startTime}Z`)),
  //   endTime: new Date(Date.parse(`${nft.endTime}Z`)),
  //   listingType: nft.listingType == 0 ? 'buyout' : 'auction',
  //   soulType: getSoulType(nft.tokenIdentifier),
  //   imageUrl: getNftUrl(nft.tokenIdentifier),
  //   thumbnailImageUrl: getThumbnailUrl(nft.tokenIdentifier),
  //   description: nft.description,
  //   sixthSummons: `${nft.sixthSummons}`,
  //   seventhSummon: `${nft.seventhSummon}`,
  //   specialAbility: getSpecialAbility(nft.tokenIdentifier)
  // };
  const endTime = new Date(Date.parse(`${nft.endTime}Z`)).getTime() / 1000;
  const regularSummoningCount = parseInt(nft.sixthSummons.split('/')[0]);
  const specialSummoningCount =
    nft.seventhSummon === 'N/A' || nft.seventhSummon === null
      ? undefined
      : parseInt(nft.seventhSummon.split('/')[0]);
  const isBuyout = nft.denominatedMinBid === nft.denominatedMaxBid;
  const minBid = isBuyout
    ? nft.denominatedMinBid
    : nft.currentBid === '0'
    ? nft.denominatedMinBid
    : nft.denominatedCurrentBid;
  const item: MarketplaceListing = {
    listingId: nft.listingId,
    collection: nft.tokenIdentifier,
    identifier: `${nft.tokenIdentifier}-${int2hex(nft.nonce)}`,
    nonce: nft.nonce,
    isOrigin: ORIGIN_SOULS_TOKEN_IDENTIFIERS.includes(nft.tokenIdentifier),
    name: nft.nftName,
    thumbnailUrl: getThumbnailUrl(nft.tokenIdentifier, nft.nonce),
    fullResourceUrl: getNftUrl(nft.tokenIdentifier, nft.nonce),
    isBuyout: isBuyout,
    minBid: minBid,
    maxBid: nft.denominatedMaxBid,
    originalOwnerAddress: nft.ownerAddress,
    currentWinnerAddress:
      nft.winnerAddress ===
      'erd1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq6gq4hu'
        ? 'N/A'
        : nft.winnerAddress,
    specialAbility: getSpecialAbility(nft.tokenIdentifier),
    startTime: Math.floor(
      new Date(Date.parse(`${nft.startTime}Z`)).getTime() / 1000
    ),
    endTime: Math.floor(endTime),
    regularSummoningCount: regularSummoningCount,
    specialSummoningCount: specialSummoningCount,
    hasExpired: getTimeUntilShort(endTime).startsWith('-'),
    description: nft.description
  };

  return item;
};

export const fillSummoningInfo = async (nfts: AccountNft[]) => {
  const identifiers = nfts.filter((n) => n.isSoul).map((n) => n.identifier);
  const fullSummoningInfo = await getSummoningCounts(identifiers);
  if (!fullSummoningInfo.success) {
    return nfts;
  }
  for (let i = 0; i < nfts.length; i++) {
    const nftSummoningInfo = fullSummoningInfo.data[nfts[i].identifier];
    if (nftSummoningInfo.length > 0) {
      nfts[i].regularSummoningCount = nftSummoningInfo[0];
    }
    if (nftSummoningInfo.length > 1 && nftSummoningInfo[1] !== null) {
      nfts[i].specialSummoningCount = nftSummoningInfo[1];
    }
  }
  return nfts;
};

export const fillPendingRewards = async (batches: AccountNft[]) => {
  for (let i = 0; i < batches.length; i++) {
    const identifierIndex = STAKED_KOSON_TOKEN_IDENTIFIERS.indexOf(
      batches[i].collection
    );
    if (identifierIndex === -1) {
      continue;
    }
    const contract = KOSON_STAKING_POOL_SC_ADDRESSES[identifierIndex];
    const rewards = await fetchPendingRewards(
      contract,
      batches[i].stakeDay || 0,
      new BigNumber(batches[i].unparsedAmount)
    );
    batches[i].pendingReward = rewards;
  }
  return batches;
};

const fetchPendingRewards = async (
  scAddress: string,
  stakeDay: number,
  stakeBalance: BigNumber
) => {
  const stakeDayArg = new U64Value(stakeDay);
  const stakeBalanceArg = new BigUIntValue(stakeBalance);

  const contract = await getSmartContract(scAddress);
  const interaction = contract.methodsExplicit.getPendingRewards([
    stakeDayArg,
    stakeBalanceArg
  ]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value.div(new BigNumber(10).pow(18)).toNumber();
  }
  return 0;
};

const getNftUrl = (tokenIdentifier: string, nonce: number) => {
  const baseUrl = 'https://nft.ageofzalmoxis.com/ipfs/';
  return baseUrl;
};

const getThumbnailUrl = (tokenIdentifier: string, nonce?: number) => {
  return getNftThumbnailUrl(tokenIdentifier) ?? 'N/A';
};

const getVideoUrl = (nft: any) => {
  return '';
};

export const getSpecialAbility = (token: string) => {
  return '';
};

export const getNftName = (tokenIdentifier: string, nonce: number) => {
  return '';
};
