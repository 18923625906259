import React from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { KosonInputSelector } from 'components/Inputs/KosonInputSelector';
import { ALL_KOSON_TOKEN_IDENTIFIERS } from 'config/dapp-config';
import { Web3Context } from 'contexts/Web3Context';
import { selectManyFirstEsdtTokenType } from 'store/slices/kosonv2/account';
import BalanceCard from './BalanceCard';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';

const AccountInfo = () => {
  const { account } = useGetAccountInfoWrapper();

  const kosonBalances = useSelector(selectManyFirstEsdtTokenType)(
    ALL_KOSON_TOKEN_IDENTIFIERS
  );

  const context = React.useContext(Web3Context);
  const [showSendKosonInput, setShowSendKosonInput] = React.useState(false);
  const [sendKosonInputAddress, setSendKosonInputAddress] = React.useState('');

  const handleSendKoson = async (
    selectedTokenIdentifier: string,
    amount: BigNumber
  ) => {
    let hexAmount = amount.toString(16);
    if (hexAmount.length % 2 === 1) {
      hexAmount = '0' + hexAmount;
    }
    context.sendTransaction(
      sendKosonInputAddress,
      1000000,
      `ESDTTransfer@${Buffer.from(selectedTokenIdentifier).toString(
        'hex'
      )}@${hexAmount}`
    );
  };

  return (
    <div className='card overflow-hidden'>
      <div className='text-center p-5 overlay-box'>
        <img
          src='images/profile/default.png'
          width='100'
          className='img-fluid rounded-circle'
          alt=''
        />
        <div className='mt-3'>
          <small>Welcome back,</small>
          <h4 className='mb-0 text-white'>
            {account.username ? `@${account.username}` : 'Dacian'}
          </h4>
          <small>{account.address}</small>
          {/* {context.accountState?.heroTag === '' && (
            <small>
              <a href='#' onClick={handleAssignHeroTag}>
                Don&apos;t have your herotag? Assign one now!
              </a>
            </small>
          )} */}
        </div>
      </div>
      <div className='card-body'>
        <div className='row text-center'>
          <div className='col-sm-12 col-md-6'>
            <BalanceCard
              balances={[
                {
                  amount: account?.balance ?? '0',
                  tokenIdentifier: 'EGLD',
                  fullIdentifier: 'EGLD',
                  nonce: 0
                }
              ]}
              text={'EGLD Balance'}
            />
          </div>
          <div className='col-sm-12 col-md-6'>
            <BalanceCard
              balances={kosonBalances}
              text={'Liquid $KOSON Balance'}
            />
          </div>
        </div>
      </div>
      <div className='card-footer mt-0'>
        {showSendKosonInput && (
          <div className='mb-4'>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <input
                    type='text'
                    className='form-control form-control-lg mb-2'
                    placeholder='erd1..'
                    onChange={(e) => setSendKosonInputAddress(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <KosonInputSelector
              buttonText='Send'
              buttonAction={handleSendKoson}
            />
          </div>
        )}
        {!showSendKosonInput && (
          <div>
            <button
              className='btn btn-primary btn-lg btn-block'
              onClick={() => setShowSendKosonInput(true)}
            >
              Send $KOSON
            </button>
          </div>
        )}
        {showSendKosonInput && (
          <div className='d-flex justify-content-center mt-2'>
            <button
              className='btn btn-primary'
              onClick={() => setShowSendKosonInput(false)}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountInfo;
