import React, { useState } from 'react';
import {
  LAND_CHEST_CONTENT_COLLECTIONS,
  LAND_CHEST_TOKEN_IDENTIFIER,
  ORIGIN_SOULS_TOKEN_IDENTIFIERS,
  ALL_SOUL_NFT_TOKEN_IDENTIFIERS,
  STAKED_KOSON_TOKEN_IDENTIFIERS,
  SUMMONED_SOULS_TOKEN_IDENTIFIERS
} from 'config/dapp-config';

type FilterCategory = 'itemType' | 'soulType' | 'soulElement';
const FilterTitle: Record<FilterCategory, string> = {
  itemType: 'Item Type',
  soulType: 'Soul Type',
  soulElement: 'Soul Element'
};

const FILTER_CATEGORIES: Record<FilterCategory, string[]> = {
  itemType: ['Souls', 'Land Chests', 'Staked Koson', 'Token NFTs'],
  soulType: ['Origin', 'Summoned'],
  soulElement: ['Earth', 'Fire', 'Aether', 'Water', 'Life', 'Air', 'Death']
};

const ELEMENT_PREFIXES: Record<string, string[]> = {
  Earth: ['EARTHSOUL-', 'SEARTHSOUL-'],
  Fire: ['FIRESOUL-', 'SFIRESOUL-'],
  Aether: ['AETHERSOUL-', 'SAETHSOUL-'],
  Water: ['WATERSOUL-', 'SWATERSOUL-'],
  Life: ['LIFESOUL-', 'SLIFESOUL-'],
  Air: ['AIRSOUL-', 'SAIRSOUL-'],
  Death: ['DEATHSOUL-']
};

interface InventoryFilterModalContentProps {
  onApply: (identifiers: string[]) => void;
}

const InventoryFilterModalContent: React.FC<
  InventoryFilterModalContentProps
> = ({ onApply }) => {
  const [filters, setFilters] = useState<string[]>(
    Object.values(FILTER_CATEGORIES).flat()
  );

  const handleFilterChange = (filterName: string): void => {
    setFilters((prev) =>
      prev.includes(filterName)
        ? prev.filter((f) => f !== filterName)
        : [...prev, filterName]
    );
  };

  const handleApply = (): void => {
    const collectionsToDisplay = ALL_SOUL_NFT_TOKEN_IDENTIFIERS.concat([
      LAND_CHEST_TOKEN_IDENTIFIER
    ])
      .concat(STAKED_KOSON_TOKEN_IDENTIFIERS)
      .concat(LAND_CHEST_CONTENT_COLLECTIONS)
      .filter((identifier) => {
        if (
          !filters.includes('Souls') &&
          ALL_SOUL_NFT_TOKEN_IDENTIFIERS.includes(identifier)
        )
          return false;
        if (
          !filters.includes('Land Chests') &&
          identifier === LAND_CHEST_TOKEN_IDENTIFIER
        )
          return false;
        if (
          !filters.includes('Staked Koson') &&
          STAKED_KOSON_TOKEN_IDENTIFIERS.includes(identifier)
        )
          return false;
        if (
          !filters.includes('Token NFTs') &&
          LAND_CHEST_CONTENT_COLLECTIONS.includes(identifier)
        )
          return false;

        if (
          !filters.includes('Origin') &&
          ORIGIN_SOULS_TOKEN_IDENTIFIERS.includes(identifier)
        )
          return false;
        if (
          !filters.includes('Summoned') &&
          SUMMONED_SOULS_TOKEN_IDENTIFIERS.includes(identifier)
        )
          return false;

        for (const [element, prefixes] of Object.entries(ELEMENT_PREFIXES)) {
          if (
            !filters.includes(element) &&
            prefixes.some((prefix) => identifier.startsWith(prefix))
          ) {
            return false;
          }
        }

        return true;
      });
    onApply(collectionsToDisplay);
  };

  const renderCheckboxes = (category: FilterCategory): JSX.Element => (
    <>
      <small>{FilterTitle[category]}</small>
      {FILTER_CATEGORIES[category].map((filter) => (
        <div className='form-check' key={filter}>
          <input
            type='checkbox'
            className='form-check-input'
            id={`${filter}-toggle`}
            checked={filters.includes(filter)}
            onChange={() => handleFilterChange(filter)}
          />
          <label className='form-check-label mt-1' htmlFor={`${filter}-toggle`}>
            Show {filter}
          </label>
        </div>
      ))}
    </>
  );

  return (
    <div className='modal-dialog modal-dialog-centered' role='document'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Filters</h5>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
          ></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left'>
              {renderCheckboxes('itemType')}
              <br />
              {renderCheckboxes('soulType')}
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left'>
              {renderCheckboxes('soulElement')}
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-danger light'
            data-bs-dismiss='modal'
          >
            Close
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => setFilters(Object.values(FILTER_CATEGORIES).flat())}
          >
            Reset
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={handleApply}
            data-bs-dismiss='modal'
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryFilterModalContent;
