import { useSelector } from 'react-redux';
import StakingRewardsCard from 'components/Cards/StakingRewardsCard';
import { NFT_UNBONDING_TIME_PENALTY } from 'config/land-plot-staking';
import { useLandPlotStaking } from 'hooks/contracts/landPlotStaking/useLandPlotStaking';
import { selectEpoch } from 'store/slices/kosonv2/blockchain-stats';
import {
  selectLandPlotStakingStats,
  selectPendingRewards
} from 'store/slices/kosonv2/land-plot-staking-pool';
import {
  StakedPlotsCountCard,
  StakedPlotsScoreCard,
  UnbondingFeeCard
} from './PlotStakingCards';

const LandPlotStakingCardInfo = () => {
  const {
    totalStakedSftsCount,
    totalStakedScore,
    userStakedSftsCount,
    userStakedScore,
    totalUnbondingFee,
    lastStakedEpoch
  } = useSelector(selectLandPlotStakingStats);
  const currentEpoch = useSelector(selectEpoch);
  const pendingRewardsArr = useSelector(selectPendingRewards);

  const { claimRewards } = useLandPlotStaking();

  const epochsUntilFreeUnbonding =
    NFT_UNBONDING_TIME_PENALTY + lastStakedEpoch - currentEpoch;

  return (
    <>
      <div className='col-md-6 col-sm-12'>
        <div className='container p-0'>
          <div className='row p-0'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <StakedPlotsScoreCard
                userStakedScore={userStakedScore}
                totalStakedScore={totalStakedScore}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <StakedPlotsCountCard
                userStakedCount={userStakedSftsCount}
                totalStakedCount={totalStakedSftsCount}
              />
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <UnbondingFeeCard
                totalUnbondingFee={totalUnbondingFee}
                epochsUntilFreeUnbonding={epochsUntilFreeUnbonding}
                userStakedScore={userStakedScore}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-6 col-sm-12'>
        <StakingRewardsCard
          rewards={pendingRewardsArr}
          claimRewards={claimRewards}
        />
      </div>
    </>
  );
};
export default LandPlotStakingCardInfo;
