import { TokenTransfer } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import {
  ALL_KOSON_TOKEN_IDENTIFIERS,
  ALL_SOUL_NFT_TOKEN_IDENTIFIERS,
  DEFAULT_TOKEN_DECIMALS,
  ITEM_NFT_TOKEN_IDENTIFIERS,
  LAND_CHEST_TOKEN_IDENTIFIER,
  LAND_PLOT_TOKEN_IDENTIFIER,
  ORIGIN_SOULS_TOKEN_IDENTIFIERS,
  UNBONDING_KOSON_TOKEN_IDENTIFIERS,
  toLocaleStringOptions,
  ALL_OLD_LAND_SFT_TOKEN_IDENTIFIERS,
  OLD_STAKED_KOSON_TOKEN_IDS
} from 'config/dapp-config';
import {
  ITEM_NAME_MAPPING_CONFIG,
  KOSON_TOKEN_NAMES,
  LAND_CHEST_CONTENT_VIDEO_URL_MAPPING,
  LAND_PLOT_NAME_MAPPING_CONFIG,
  SOULS_NFT_IPFS_URL_MAPPING,
  SOULS_NFT_NAME_MAPPING_CONFIG,
  SOULS_NFT_THUMBNAIL_MAPPING_CONFIG
} from 'config/mapping-config';
import { SOUL_STAKING_SCORES } from 'config/soul-staking-config';
import { int2hex } from 'contexts/Web3Context/helpers/generalUtils';
import { decodeUnbondingBatchAttributes } from 'hooks/contracts/kosonStakingPool/utils';
import { LAND_PLOT_STAKING_SCORES } from 'store/slices/kosonv2/land-plot-staking-pool';
import { AccountNft, PaymentType } from 'types';
import { UnbondingKosonEsdtType } from 'types/koson-staking-pool';
import {
  EsdtTokenType,
  NonFungibleTokenBalanceApiType,
  TokenBalanceType,
  TokenInfoMapType
} from 'types/MultiversX';
import {
  StakeableAssetType,
  SoulNftType,
  StakedSoulType,
  StakedAssetType
} from 'types/store';

export const parseTokenBalanceType = (
  tokenBalance: TokenBalanceType
): EsdtTokenType => {
  return {
    tokenIdentifier: tokenBalance.identifier,
    fullIdentifier: tokenBalance.identifier,
    nonce: 0,
    amount: tokenBalance.balance,
    logoUrl: 'TODO'
  };
};

export const parseNFTTokenBalanceType = (
  tokenBalance: NonFungibleTokenBalanceApiType
): EsdtTokenType => {
  return {
    tokenIdentifier: tokenBalance.collection,
    fullIdentifier: `${tokenBalance.collection}-${int2hex(tokenBalance.nonce)}`,
    nonce: tokenBalance.nonce,
    amount: tokenBalance.balance ?? '1',
    decimals: tokenBalance.decimals,
    imgUrl: isSoulNftToken(tokenBalance.collection)
      ? getOriginalAssetUrl(tokenBalance.collection, tokenBalance.nonce)
      : 'N/A',
    videoUrl: !isSoulNftToken(tokenBalance.collection)
      ? getOriginalAssetUrl(tokenBalance.collection, tokenBalance.nonce)
      : 'N/A',
    logoUrl: 'TODO',
    thumbnailUrl: getNftThumbnailUrl(tokenBalance.collection),
    name: getNftName(tokenBalance.collection, tokenBalance.nonce),
    originalAssetUrl: getOriginalAssetUrl(
      tokenBalance.collection,
      tokenBalance.nonce
    ),
    attributes: tokenBalance.attributes
  };
};

export const parseSoulTokenBalanceType = (
  tokenBalance: NonFungibleTokenBalanceApiType
): SoulNftType => {
  const esdtToken = parseNFTTokenBalanceType(tokenBalance);

  const { summoningCounts, deathSoulSummoning } =
    extractSummoningDataFromAttributes(tokenBalance.attributes);
  if (summoningCounts === -1 || deathSoulSummoning === -1) {
    console.log(
      `Failed to extract summoning data for token ${tokenBalance.collection} from attributes: ${tokenBalance.attributes}`
    );
  }

  return {
    ...esdtToken,
    summoningCounts,
    deathSoulSummoning,
    stakeScore: SOUL_STAKING_SCORES[tokenBalance.collection]
  };
};

export const parseLandPlotTokenBalanceType = (
  tokenBalance: NonFungibleTokenBalanceApiType
): StakeableAssetType => {
  const esdtToken = parseNFTTokenBalanceType(tokenBalance);
  return {
    ...esdtToken,
    stakeScore: LAND_PLOT_STAKING_SCORES[esdtToken.nonce - 1] ?? 0
  };
};

export const parseStakedSoulTokenBalanceType = (
  tokenBalance: NonFungibleTokenBalanceApiType,
  stakeEpoch: number,
  unstakeFee: string
): StakedSoulType => {
  const soulToken = parseSoulTokenBalanceType(tokenBalance);
  return {
    ...soulToken,
    stakeEpoch,
    unstakeFee
  };
};

export const parseStakedLandPlotTokenBalanceType = (
  tokenBalance: NonFungibleTokenBalanceApiType,
  stakeEpoch: number,
  unstakeFee: string
): StakedAssetType => {
  const landPlotToken = parseLandPlotTokenBalanceType(tokenBalance);
  return {
    ...landPlotToken,
    stakeEpoch,
    unstakeFee
  };
};

export const parseUnbondingKosonTokenBalanceType = (
  tokenBalance: NonFungibleTokenBalanceApiType
): UnbondingKosonEsdtType => {
  const esdtToken = parseNFTTokenBalanceType(tokenBalance);
  const batchAttributes = decodeUnbondingBatchAttributes(
    tokenBalance.attributes
  );
  return {
    ...esdtToken,
    unbondingEpoch: batchAttributes.mintEpoch
  };
};

export const isSoulNftToken = (collection: string) => {
  return ALL_SOUL_NFT_TOKEN_IDENTIFIERS.includes(collection);
};
export const isLandPlotNftToken = (collection: string) => {
  return collection === LAND_PLOT_TOKEN_IDENTIFIER;
};
export const isLandChestNftToken = (collection: string) => {
  return collection === LAND_CHEST_TOKEN_IDENTIFIER;
};
export const isItemNftToken = (collection: string) => {
  return ITEM_NFT_TOKEN_IDENTIFIERS.includes(collection);
};
export const isUnbondingKosonToken = (collection: string) => {
  return UNBONDING_KOSON_TOKEN_IDENTIFIERS.includes(collection);
};
export const isOriginSoul = (collection: string) => {
  return ORIGIN_SOULS_TOKEN_IDENTIFIERS.includes(collection);
};
export const isUnmigratedAsset = (collection: string) => {
  return (
    ALL_OLD_LAND_SFT_TOKEN_IDENTIFIERS.includes(collection) ||
    OLD_STAKED_KOSON_TOKEN_IDS.includes(collection)
  );
};

const getNftName = (collection: string, nonce: number) => {
  if (isSoulNftToken(collection)) {
    return `${SOULS_NFT_NAME_MAPPING_CONFIG[collection]}${[nonce]}`;
  }
  if (isLandPlotNftToken(collection)) {
    return LAND_PLOT_NAME_MAPPING_CONFIG[nonce - 1];
  }
  if (isItemNftToken(collection)) {
    return ITEM_NAME_MAPPING_CONFIG[collection][nonce - 1];
  }
};

export const getNftThumbnailUrl = (collection: string) => {
  if (isSoulNftToken(collection)) {
    return `/images/aoz/thumbnails/${SOULS_NFT_THUMBNAIL_MAPPING_CONFIG[collection]}`;
  }
};

const getOriginalAssetUrl = (collection: string, nonce: number) => {
  if (isSoulNftToken(collection)) {
    return `https://nft.ageofzalmoxis.com/ipfs/${SOULS_NFT_IPFS_URL_MAPPING[collection]}`;
  }
  return LAND_CHEST_CONTENT_VIDEO_URL_MAPPING[`${collection}-0${nonce}`];
};

export const formatBigNumberString = (bigNumberString: string | BigNumber) => {
  return new BigNumber(bigNumberString)
    .shiftedBy(-DEFAULT_TOKEN_DECIMALS)
    .toNumber()
    .toLocaleString(undefined, toLocaleStringOptions);
};

export const formatBigNumberStringMaxPrecision = (
  value: string | BigNumber
) => {
  return new BigNumber(value).shiftedBy(-DEFAULT_TOKEN_DECIMALS).toString(10);
};

export const parseDenominatedBalanceAsNumber = (
  balance: string | BigNumber,
  decimals?: number
) => {
  return new BigNumber(balance)
    .shiftedBy(-(decimals ?? DEFAULT_TOKEN_DECIMALS))
    .toNumber();
};

export const getBigNumberTokenBalanceFromMap = (
  map: TokenInfoMapType<EsdtTokenType>,
  tokenIdentifier: string
): BigNumber => {
  if (
    map !== undefined &&
    map[tokenIdentifier] !== undefined &&
    map[tokenIdentifier]?.length > 0
  ) {
    return new BigNumber(map[tokenIdentifier][0]?.amount);
  }
  return new BigNumber(0);
};

export const getBigNumberTokenBalanceSumFromMap = (
  map: TokenInfoMapType<EsdtTokenType>,
  tokenIdentifier: string
): BigNumber => {
  const tokenMap = map[tokenIdentifier]?.length > 0 ? map[tokenIdentifier] : [];
  return tokenMap.reduce(
    (acc, curr) => acc.plus(new BigNumber(curr.amount)),
    new BigNumber(0)
  );
};

export const mapNftToAccountNftType = (input: EsdtTokenType): AccountNft => {
  const soulTokenData = input as SoulNftType;
  return {
    collection: input.tokenIdentifier,
    identifier: input.tokenIdentifier,
    nonce: input.nonce,
    amount: parseInt(input.amount),
    unparsedAmount: input.amount,
    regularSummoningCount: soulTokenData?.summoningCounts,
    specialSummoningCount: soulTokenData?.deathSoulSummoning,
    isOrigin: ORIGIN_SOULS_TOKEN_IDENTIFIERS.includes(input.tokenIdentifier),
    isSoul: isSoulNftToken(input.tokenIdentifier),
    isNotMigratedOrigin: false, // TODO: handle this
    isLandChest: isLandChestNftToken(input.tokenIdentifier),
    thumbnailUrl: input.thumbnailUrl ?? 'N/A',
    fullResourceUrl: input.originalAssetUrl ?? 'N/A',
    videoResourceUrl:
      input.videoUrl === 'N/A' || input.videoUrl === '' ? '' : input.videoUrl,
    name: input.name ?? 'N/A',
    isStakedKosonBatch: false, // TODO: check if this is still needed, it shouldn't be
    isTokenNft: false, // TODO: same as above, applies only to items that come out of chests
    description: 'N/A', // TODO: check if still needed
    ETAUntilClaim: undefined,
    canBeClaimed: undefined,
    stakeDay: undefined,
    daysUntilClaimDay: undefined,
    pendingReward: undefined,
    rarity: undefined
  };
};

export const parseEsdtTokenPayment = (payment: any): EsdtTokenType => {
  const nonce = payment.token_nonce.toNumber();
  const fullIdentifier =
    nonce === 0
      ? payment.token_identifier
      : `${payment.token_identifier}-${int2hex(nonce)}`;

  return {
    tokenIdentifier: payment.token_identifier,
    nonce,
    amount: payment.amount.toString(),
    fullIdentifier
  };
};

export const getTokenTransfer = ({
  tokenIdentifier,
  nonce,
  amount,
  decimals
}: PaymentType) => {
  if (nonce === undefined || nonce === 0) {
    return TokenTransfer.fungibleFromAmount(
      tokenIdentifier,
      amount,
      decimals ?? 0
    );
  }

  return TokenTransfer.metaEsdtFromAmount(
    tokenIdentifier,
    nonce,
    amount,
    decimals ?? 0
  );
};

const extractSummoningDataFromAttributes = (base64Attributes?: string) => {
  if (!base64Attributes) {
    return {
      summoningCounts: -1,
      deathSoulSummoning: -1
    };
  }

  // parsedAttributes = 'metadata:QmRb8Ptz7Wprn1mtQeKdEQrSPEN5CEZ3dhM9vdVY3dKwVi/Origin_Life_1_1.json;tags:Age Of Zalmoxis, Ancient, MMORPG, Origin, Life'
  const parsedAttributes = Buffer.from(base64Attributes, 'base64').toString();

  // metadataArg = 'metadata:QmRb8Ptz7Wprn1mtQeKdEQrSPEN5CEZ3dhM9vdVY3dKwVi/Origin_Life_1_1.json'
  const args = parsedAttributes.split(';');
  const metadataArg = args.find((arg) => arg.startsWith('metadata:'));
  if (metadataArg === undefined) {
    return {
      summoningCounts: -1,
      deathSoulSummoning: -1
    };
  }

  // fileNameWithExtension = 'Origin_Life_1_1.json'
  const fileNameWithExtension = metadataArg.split('/')[1];
  if (fileNameWithExtension === undefined) {
    return {
      summoningCounts: -1,
      deathSoulSummoning: -1
    };
  }
  // fileName = 'Origin_Life_1_1'
  const fileName = fileNameWithExtension.split('.')[0];

  // nameBreakdown = ['Origin', 'Life', '1', '1']
  const nameBreakdown = fileName.split('_');
  const regularSummoningCount = parseInt(nameBreakdown[2]);
  const specialSummoningCount =
    nameBreakdown.length > 3 ? parseInt(nameBreakdown[3]) : undefined;

  return {
    summoningCounts: regularSummoningCount,
    deathSoulSummoning: specialSummoningCount
  };
};

export const getKosonTokenNameByIdentifier = (identifier: string) => {
  return KOSON_TOKEN_NAMES[ALL_KOSON_TOKEN_IDENTIFIERS.indexOf(identifier)];
};
