import { Loading } from 'components/Loading';
import { useGetDropChances } from 'hooks/LandChestOpening/useGetDropChances';
import { ChestContentExplorer } from './ChestContentExplorer';
import './style.css';
import { ChestPrizeDistributionTable } from './ChestPrizeDistributionTable';
import { OpeningCard } from './OpeningCard';
import { useSelector } from 'react-redux';
import { selectStatus } from 'store/slices/kosonv2/land-plot-staking-pool';
import { selectLandChests } from 'store/slices/kosonv2/account';

export const LandChestOpening = () => {
  // const { nftState, accountState } = useContext(Web3Context);
  const { isLoading, dropsRemaining, refreshState } = useGetDropChances();
  const status = useSelector(selectStatus);
  const landChests = useSelector(selectLandChests);

  const loadingCard = () => {
    return (
      <div className='card'>
        <div className='card-header'>
          <h2>Open Land Chests</h2>
        </div>
        <div className='card-body p-5'>
          <Loading loadingText='Land Chest Data' />
        </div>
      </div>
    );
  };

  const refreshData = async () => {
    // await nftState?.refreshEntireState();
    // await accountState?.refreshState();
    await refreshState();
  };

  return (
    <>
      <div className='row d-flex'>
        <div className='col-lg-7 col-md-12 col-sm-12'>
          {/* {status === 'loading' && loadingCard()} */}
          {landChests !== undefined && (
            <OpeningCard
              landChests={landChests}
              refreshDataTrigger={refreshData}
            />
          )}
        </div>
        <div className='col-lg-5 col-md-12 col-sm-12'>
          <ChestContentExplorer />
        </div>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='card-body text-center'>
              <ChestPrizeDistributionTable
                isLoading={isLoading}
                dropsRemaining={dropsRemaining}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
