import React from 'react';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toLocaleStringOptions } from 'config/dapp-config';
import { SummoningInfo, AssetPrice } from './types';

export const VideoAssetThumbnailCardHeader = ({
  assetName,
  assetScore,
  videoUrl,
  summoningInfo,
  assetPrice,
  quantity,
  onClick
}: {
  assetName: string;
  assetNonce?: number;
  assetScore?: number;
  videoUrl: string;
  summoningInfo?: SummoningInfo;
  assetPrice?: AssetPrice;
  quantity?: number;
  onClick?: () => void;
}) => {
  return (
    <div className='asset-thumbnail card-header m-0' onClick={onClick}>
      <video autoPlay loop muted playsInline className='img-responsive p-0 m-0'>
        <source src={videoUrl} />
      </video>
      {summoningInfo !== undefined && (
        <>
          <div className='summoning-info'>
            <div className='regular-summoning'>
              {summoningInfo.regularSummons}/6{' '}
              <div className='summoning-text'>soul summons</div>
            </div>
          </div>
          {summoningInfo.specialSummons !== undefined && (
            <div className='summoning-info'>
              <div className='special-summoning'>
                {summoningInfo.specialSummons ?? -1}/1{' '}
                <div className='summoning-text'>Death Soul summon</div>
              </div>
            </div>
          )}
        </>
      )}
      {assetScore !== undefined && (
        <div className='nft-nonce'>#{assetScore} pts</div>
      )}
      <div className='nft-type'>{assetName.split('#')[0]}</div>
      {quantity !== undefined && (
        <div
          className={`nft-quantity ${
            assetPrice !== undefined && 'with-nft-price'
          }`}
        >
          x{quantity}
        </div>
      )}
      {assetPrice !== undefined && (
        <>
          <div className='nft-price-native'>
            <FontAwesomeIcon icon={faGavel as any} className='hammer-icon' />{' '}
            {assetPrice.currentPrice.toLocaleString(
              undefined,
              toLocaleStringOptions
            )}{' '}
            EGLD
            <img
              src='/images/mvx/x-mint.png'
              className='img-responsive chain-logo'
            />
          </div>
          <div className='nft-price-usd'>
            <FontAwesomeIcon icon={faGavel as any} className='hammer-icon' />{' '}
            {assetPrice.currentUsdPrice.toLocaleString(
              undefined,
              toLocaleStringOptions
            )}{' '}
            USD
            <img
              src='/images/mvx/x-mint.png'
              className='img-responsive chain-logo'
            />
          </div>
        </>
      )}
    </div>
  );
};
