import { useSelector } from 'react-redux';
import StakingRewardsCard from 'components/Cards/StakingRewardsCard';
import { NFT_UNBONDING_TIME_PENALTY } from 'config/soul-staking-config';
import { useSoulNftStaking } from 'hooks/contracts/soulNftStaking/useSoulNftStaking';
import { selectEpoch } from 'store/slices/kosonv2/blockchain-stats';
import {
  selectPendingRewards,
  selectSoulStakingStats
} from 'store/slices/kosonv2/soul-staking-pool';
import {
  StakedNftsCountCard,
  StakedNftsScoreCard,
  UnbondingFeeCard
} from './NftStakingCards';

const NftStakingCardInfo = () => {
  const {
    totalStakedNftsCount,
    totalStakedScore,
    userStakedNftsCount,
    userStakedScore,
    totalUnbondingFee,
    lastStakedEpoch
  } = useSelector(selectSoulStakingStats);
  const { claimRewards } = useSoulNftStaking();

  const currentEpoch = useSelector(selectEpoch);
  const pendingRewardsArr = useSelector(selectPendingRewards);

  const epochsUntilFreeUnbonding =
    NFT_UNBONDING_TIME_PENALTY + lastStakedEpoch - currentEpoch;

  return (
    <>
      <div className='col-md-6 col-sm-12'>
        <div className='container p-0'>
          <div className='row p-0'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <StakedNftsScoreCard
                userStakedScore={userStakedScore}
                totalStakedScore={totalStakedScore}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <StakedNftsCountCard
                userStakedCount={userStakedNftsCount}
                totalStakedCount={totalStakedNftsCount}
              />
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <UnbondingFeeCard
                totalUnbondingFee={totalUnbondingFee}
                epochsUntilFreeUnbonding={epochsUntilFreeUnbonding}
                userStakedScore={userStakedScore}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-6 col-sm-12'>
        <StakingRewardsCard
          rewards={pendingRewardsArr}
          claimRewards={claimRewards}
        />
      </div>
    </>
  );
};
export default NftStakingCardInfo;
