import {
  OLD_LAND_CHEST_TOKEN_IDENTIFIER,
  OLD_LAND_PLOT_TOKEN_IDENTIFIER
} from 'config/dapp-config';
import { int2hex } from 'contexts/Web3Context/helpers/generalUtils';
import { formatBigNumberString } from 'utils';
import { Land } from './types';

export const LandMigrationLogs = ({
  landMigrationLog
}: {
  landMigrationLog: Land;
}) => {
  return (
    <>
      <div
        className='col-12 mt-3'
        style={{ fontSize: '22px', fontWeight: 'bolder' }}
      >
        Land Chests/Plots{' '}
        <small style={{ fontWeight: 'normal' }}>
          {landMigrationLog.stakedChests.length} Staked chests;{' '}
          {landMigrationLog.stakedPlots.length} Staked plots;{' '}
          {landMigrationLog.stakedChests.length} Unbonding chests;{' '}
          {landMigrationLog.unbondingPlots.length} Unbonding plots;{' '}
          {formatBigNumberString(landMigrationLog.pendingRewards)} KOSON v1
          Pending rewards
        </small>
      </div>
      {landMigrationLog.stakedChests.length > 0 && (
        <>
          <div className='col-12 mt-3' style={{ fontSize: '20px' }}>
            Staked Chests
          </div>
          <div className='col-12 mt-1'>
            {landMigrationLog.stakedChests.map((stakedNft, index) => (
              <div key={`staked-chests-logs-${index}`} className='ms-3'>
                {OLD_LAND_CHEST_TOKEN_IDENTIFIER}-
                {int2hex(parseInt(stakedNft.nonce))}
              </div>
            ))}
          </div>
        </>
      )}
      {landMigrationLog.stakedPlots.length > 0 && (
        <>
          <div className='col-12 mt-3' style={{ fontSize: '20px' }}>
            Staked Plots
          </div>
          <div className='col-12 mt-1'>
            {landMigrationLog.stakedPlots.map((stakedNft, index) => (
              <div key={`staked-plots-logs-${index}`} className='ms-3'>
                {OLD_LAND_PLOT_TOKEN_IDENTIFIER}-
                {int2hex(parseInt(stakedNft.nonce))}
              </div>
            ))}
          </div>
        </>
      )}
      {landMigrationLog.unbondingChests.length > 0 && (
        <>
          <div className='col-12 mt-3' style={{ fontSize: '20px' }}>
            Unbonding Chests
          </div>
          <div className='col-12 mt-1'>
            {landMigrationLog.unbondingChests.map((unbondingNft, index) => (
              <div key={`unbonding-chests-logs-${index}`} className='ms-3'>
                {unbondingNft.field1.map((stakedNft, index2) => (
                  <div
                    key={`unbonding-chests-logs-batch-${index}-${index2}`}
                    className='ms-3'
                  >
                    {OLD_LAND_CHEST_TOKEN_IDENTIFIER}-
                    {int2hex(parseInt(stakedNft.nonce))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {landMigrationLog.unbondingPlots.length > 0 && (
        <>
          <div className='col-12 mt-3' style={{ fontSize: '20px' }}>
            Unbonding Chests
          </div>
          <div className='col-12 mt-1'>
            {landMigrationLog.unbondingPlots.map((unbondingNft, index) => (
              <div key={`unbonding-plots-logs-${index}`} className='ms-3'>
                {unbondingNft.field1.map((stakedNft, index2) => (
                  <div
                    key={`unbonding-plots-logs-batch-${index}-${index2}`}
                    className='ms-3'
                  >
                    {OLD_LAND_PLOT_TOKEN_IDENTIFIER}-
                    {int2hex(parseInt(stakedNft.nonce))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
