import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { fetchInventory } from './slices/accountInfo/inventory';
import { fetchUserMarketplaceListings } from './slices/accountInfo/marketplaceListings';
import { fetchPendingRewards } from './slices/accountInfo/pendingRewards';
import { fetchStakedInventory } from './slices/accountInfo/stakedInventory';
import { fetchVesting } from './slices/accountInfo/vesting';
import { fetchStakedAssets } from './slices/generalStats/stakedAssets';
// import { fetchMarketplaceListings } from './slices/marketplace/marketplaceListings';

export const useCustomDispatch = () => {
  const dispatch = useDispatch<AppDispatch>();
  const dispatchAllAccountInfo = (address: string) => {
    dispatch(fetchInventory(address));
    dispatch(fetchUserMarketplaceListings(address));
    dispatch(fetchPendingRewards(address));
    dispatch(fetchStakedInventory(address));
    dispatch(fetchVesting());
    // dispatch(fetchMarketplaceListings());
  };

  const dispatchAllStats = () => {
    dispatch(fetchStakedAssets());
  };

  return {
    dispatchAllAccountInfo,
    dispatchAllStats
  };
};
