import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { MarketplaceListingV2 } from 'types';
import { StoreStateStatusType, StoreStateType } from 'types/store';

interface MarketplaceListingsState {
  freshListings: MarketplaceListingV2[];
  allListings: MarketplaceListingV2[];
}

const defaultState: MarketplaceListingsState = {
  freshListings: [],
  allListings: []
};

const initialState: StoreStateType<MarketplaceListingsState> = {
  data: defaultState,
  status: 'loading',
  error: null
};

const marketplaceListingsSlice = createSlice({
  name: 'marketplaceListings',
  initialState,
  reducers: {
    setStatus: (
      state: StoreStateType<MarketplaceListingsState>,
      action: PayloadAction<StoreStateStatusType>
    ) => {
      state.status = action.payload;
    },
    setFreshListings: (
      state: StoreStateType<MarketplaceListingsState>,
      action: PayloadAction<MarketplaceListingV2[]>
    ) => {
      state.data.freshListings = action.payload;
      console.log(
        'MarketplaceListingsState->setFreshListings:',
        state.data.freshListings
      );
    },
    setAllListings: (
      state: StoreStateType<MarketplaceListingsState>,
      action: PayloadAction<MarketplaceListingV2[]>
    ) => {
      state.data.allListings = action.payload;
      console.log(
        'MarketplaceListingsState->setAllListings:',
        state.data.allListings
      );
    }
  }
});

export const { setStatus, setFreshListings, setAllListings } =
  marketplaceListingsSlice.actions;

export const selectFreshListings = (state: RootState): MarketplaceListingV2[] =>
  state.marketplace.marketplaceListings.data.freshListings;

export const selectAllListings = (state: RootState): MarketplaceListingV2[] =>
  state.marketplace.marketplaceListings.data.allListings;

export default marketplaceListingsSlice.reducer;
