import { useEffect } from 'react';
import {
  useGetAccountInfo,
  useGetPendingTransactions
} from '@multiversx/sdk-dapp/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { KOSON_STAKING_POOL_SC_ADDRESSES } from 'config/dapp-config';
import { KOSON_STAKING_POOLS } from 'config/koson-staking-pools';
import { useKosonStakingPool } from 'hooks/contracts/kosonStakingPool/useKosonStakingPool';
import {
  SingleKosonStakingPoolState,
  selectStatus,
  setKosonStakingPool,
  setStatus
} from 'store/slices/kosonv2/koson-staking-pools';
import { KosonStakingPoolType } from 'types/koson-staking-pool';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';

export const KosonStakingPoolsLoader = () => {
  const { address } = useGetAccountInfoWrapper();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const { getStakingPoolContext } = useKosonStakingPool();

  const status = useSelector(selectStatus);
  const dispatch = useDispatch();

  const loadStakingPoolContext = async (
    poolType: KosonStakingPoolType
  ): Promise<SingleKosonStakingPoolState> => {
    const poolAddress = KOSON_STAKING_POOL_SC_ADDRESSES[poolType.poolId];
    const result = await getStakingPoolContext(poolAddress);

    return {
      ...poolType,
      ...result
    };
  };

  const loadContextForAllStakingPools = async () => {
    for (const stakingPool of KOSON_STAKING_POOLS) {
      const state = await loadStakingPoolContext(stakingPool);
      dispatch(setKosonStakingPool(state));
    }
  };

  // load staking pool contexts
  useEffect(() => {
    if (status !== 'loading' && hasPendingTransactions) {
      return;
    }

    loadContextForAllStakingPools()
      .then(() => {
        dispatch(setStatus('succeeded'));
      })
      .catch((err) => {
        console.log(
          'Something went wrong when loading koson staking pool: ',
          err
        );
        dispatch(setStatus('failed'));
      });
  }, [hasPendingTransactions, address]);

  return <></>;
};
