import axios from 'axios';
import { backendApiAddress } from 'config/dapp-config';
import {
  transformMarketplaceListing,
  transformMarketplaceListing2
} from 'contexts/Web3Context/helpers/nftUtils';

const fetchAllNftListings = () =>
  async function getAllNftListings() {
    try {
      const { data } = await axios.get(`${backendApiAddress}/marketplace/all`);
      return {
        data: data.map((d: any) => transformMarketplaceListing(d)),
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchAllNftListingsV2 = () =>
  async function getAllNftListings() {
    try {
      const { data } = await axios.get(`${backendApiAddress}/marketplace/all`);
      return {
        data: data.map((d: any) => transformMarketplaceListing2(d)),
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchNftListingById = () =>
  async function getNftListingById(listingId: number) {
    try {
      const { data } = await axios.get(
        `${backendApiAddress}/marketplace/${listingId}`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchNftListingsByAddress = () =>
  async function getNftListingById(ownerAddress: string) {
    try {
      const { data } = await axios.get(
        `${backendApiAddress}/marketplace/${ownerAddress}/listings`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchLatestNftListings = () =>
  async function getLatestListings() {
    try {
      const { data } = await axios.get(
        `${backendApiAddress}/marketplace/latest`
      );
      return {
        data: data.map((d: any) => transformMarketplaceListing(d)),
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchLatestNftListingsV2 = () =>
  async function getLatestListings() {
    try {
      const { data } = await axios.get(
        `${backendApiAddress}/marketplace/latest`
      );
      return {
        data: data.map((d: any) => transformMarketplaceListing2(d)),
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchUserListings = () =>
  async function getUserListings(address: string) {
    try {
      const { data } = await axios.get(
        `${backendApiAddress}/marketplace/${address}/listings`
      );
      return {
        data: data.map((d: any) => transformMarketplaceListing(d)),
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchUserListingsV2 = () =>
  async function getUserListings2(address: string) {
    try {
      const { data } = await axios.get(
        `${backendApiAddress}/marketplace/${address}/listings`
      );
      return {
        data: data.map((d: any) => transformMarketplaceListing2(d)),
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchUserOngoingBids = () =>
  async function getUserOngoingBids(address: string) {
    try {
      const { data } = await axios.get(
        `${backendApiAddress}/marketplace/${address}/bids`
      );
      return {
        data: data.map((d: any) => transformMarketplaceListing(d)),
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

export const getAllNftListings = fetchAllNftListings();
export const getNftListingById = fetchNftListingById();
export const getNftListingsByAddress = fetchNftListingsByAddress();
export const getLatestNftListings = fetchLatestNftListings();
export const getUserListings = fetchUserListings();
export const getUserOngoingBids = fetchUserOngoingBids();

export const getUserListings2 = fetchUserListingsV2();
export const getAllNftListings2 = fetchAllNftListingsV2();
export const getLatestNftListings2 = fetchLatestNftListingsV2();
