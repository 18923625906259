import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import {
  ANCIENT_KOSON_TOKEN_IDENTIFIER,
  ESOTERIC_KOSON_TOKEN_IDENTIFIER,
  KOSON_STAKING_POOL_SC_ADDRESSES,
  PRIMORDIAL_KOSON_TOKEN_IDENTIFIER
} from 'config/dapp-config';
import { KOSON_STAKING_POOLS } from 'config/koson-staking-pools';
import { RootState } from 'store';
import { KosonStakingPoolUIContext } from 'types/koson-staking-pool';
import { EsdtTokenType, TokenInfoMapType } from 'types/MultiversX';
import { StoreStateStatusType, StoreStateType } from 'types/store';
import { getBigNumberTokenBalanceFromMap } from 'utils';
import { selectEsdtBalance, selectMetaEsdtTotalBalance } from './account';

export interface SingleKosonStakingPoolState {
  poolId: number;
  poolShareOfDailyEmission: number;
  rewardIndex: string;
  tokenBalances: TokenInfoMapType<EsdtTokenType>;
  maxClaimFee: number;
  unbondingEpochs: number;
  indexTokenIdentifier: string;
  unbondingTokenIdentifier: string;
}

export interface KosonStakingPoolsState {
  pools: Record<number, SingleKosonStakingPoolState>;
}

const defaultState: KosonStakingPoolsState = {
  pools: {}
};

const initialState: StoreStateType<KosonStakingPoolsState> = {
  data: defaultState,
  status: 'loading',
  error: null
};

const kosonStakingPoolsSlice = createSlice({
  name: 'kosonStakingPools',
  initialState,
  reducers: {
    setStatus: (
      state: StoreStateType<KosonStakingPoolsState>,
      action: PayloadAction<StoreStateStatusType>
    ) => {
      state.status = action.payload;
    },
    setKosonStakingPool: (
      state: StoreStateType<KosonStakingPoolsState>,
      action: PayloadAction<SingleKosonStakingPoolState>
    ) => {
      state.data.pools[action.payload.poolId] = action.payload;
      console.log(
        'setKosonStakingPool->poolId',
        state.data.pools[action.payload.poolId]
      );
    }
  }
});

export const { setStatus, setKosonStakingPool } =
  kosonStakingPoolsSlice.actions;

export const selectKosonStakingPoolById =
  (state: RootState) =>
  (poolId: number): KosonStakingPoolUIContext => {
    const pool = state.kosonv2.kosonStakingPools.data.pools[poolId];
    const poolConfig = KOSON_STAKING_POOLS[poolId];

    const poolContext = {
      ...pool,
      ...poolConfig
    };

    const userIndexTokenBalance = selectEsdtBalance(state)(
      poolContext?.indexTokenIdentifier ?? ''
    );
    const userUnbondingTokenBalance =
      selectMetaEsdtTotalBalance(state)(
        poolContext?.unbondingTokenIdentifier ?? ''
      ) ?? 0;

    const totalUnbondingSupply = getBigNumberTokenBalanceFromMap(
      poolContext.tokenBalances,
      poolContext.unbondingTokenIdentifier
    );
    const indexTokenSupply = getBigNumberTokenBalanceFromMap(
      poolContext.tokenBalances,
      poolContext.indexTokenIdentifier
    );

    const contractEsotericKosonBalance = getBigNumberTokenBalanceFromMap(
      poolContext.tokenBalances,
      ESOTERIC_KOSON_TOKEN_IDENTIFIER
    );

    const contractAncientKosonBalance = getBigNumberTokenBalanceFromMap(
      poolContext.tokenBalances,
      ANCIENT_KOSON_TOKEN_IDENTIFIER
    );

    const contractPrimordialKosonBalance = getBigNumberTokenBalanceFromMap(
      poolContext.tokenBalances,
      PRIMORDIAL_KOSON_TOKEN_IDENTIFIER
    );

    const totalStakedKoson = indexTokenSupply.plus(totalUnbondingSupply);

    const poolShare = totalStakedKoson.eq(0)
      ? new BigNumber(0)
      : userIndexTokenBalance
          .plus(userUnbondingTokenBalance)
          .div(totalStakedKoson);

    const esotericKosonPoolShare = poolShare.times(
      contractEsotericKosonBalance
    );
    const ancientKosonPoolShare = poolShare.times(contractAncientKosonBalance);
    const primordialKosonPoolShare = poolShare.times(
      contractPrimordialKosonBalance
    );

    return {
      ...pool,
      ...poolConfig,
      userIndexTokenBalance,
      userUnbondingTokenBalance,
      poolShare,
      esotericKosonPoolShare,
      ancientKosonPoolShare,
      primordialKosonPoolShare,
      indexTokenSupply,
      esotericKosonPoolSupply: contractEsotericKosonBalance,
      ancientKosonPoolSupply: contractAncientKosonBalance,
      primordialKosonPoolSupply: contractPrimordialKosonBalance,
      totalUnbondingSupply,
      scAddress: KOSON_STAKING_POOL_SC_ADDRESSES[poolId]
    };
  };

export const selectKosonStakingPools = (
  state: RootState
): SingleKosonStakingPoolState[] => {
  return Object.values(state.kosonv2.kosonStakingPools.data.pools);
};

export const getPoolStakedKosonByUser =
  (state: RootState) => (poolId: number) => {
    const pool = state.kosonv2.kosonStakingPools.data.pools[poolId];
    const indexTokenBalance = selectEsdtBalance(state)(
      pool.indexTokenIdentifier
    );

    const unbondingTokenBalance = selectMetaEsdtTotalBalance(state)(
      pool.unbondingTokenIdentifier
    );

    return indexTokenBalance.plus(unbondingTokenBalance);
  };

export const selectTotalStakedKosonByUser = (state: RootState) => {
  let totalStaked = new BigNumber(0);

  for (let poolId = 0; poolId < KOSON_STAKING_POOLS.length; poolId++) {
    totalStaked = totalStaked.plus(getPoolStakedKosonByUser(state)(poolId));
  }

  return totalStaked;
};

export const selectStatus = (state: RootState) =>
  state.kosonv2.kosonStakingPools.status;

export default kosonStakingPoolsSlice.reducer;
