import React, { useEffect } from 'react';
import {
  useGetAccountInfo,
  useGetPendingTransactions
} from '@multiversx/sdk-dapp/hooks';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getKYCStatusV2 } from 'api/backendRequests';
import {
  ALL_ESDT_ASSETS_TOKEN_IDENTIFIERS,
  ALL_NFT_ASSET_TOKEN_IDENTIFIERS,
  ALL_OLD_LAND_SFT_TOKEN_IDENTIFIERS,
  OLD_KOSON_TICKER,
  OLD_STAKED_KOSON_TOKEN_IDS
} from 'config/dapp-config';
import { useMultiversXApi } from 'hooks/MultiversX/useMultiversXApi';
import {
  isItemNftToken,
  isLandChestNftToken,
  isLandPlotNftToken,
  isSoulNftToken,
  isUnbondingKosonToken,
  isUnmigratedAsset,
  parseLandPlotTokenBalanceType,
  parseNFTTokenBalanceType,
  parseSoulTokenBalanceType,
  parseTokenBalanceType,
  parseUnbondingKosonTokenBalanceType
} from 'utils';
import {
  selectStatus,
  setAccount,
  setItemsBalance,
  setKYCStatus,
  setLandChestsBalance,
  setLandPlotsBalance,
  setSoulsBalance,
  setStatus,
  setTokenBalances,
  setUnbondingKosonsBalance,
  setUnmigratedAssets
} from '../slices/kosonv2/account';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';

export const AccountLoader = () => {
  const { address, account } = useGetAccountInfoWrapper();
  const { hasPendingTransactions } = useGetPendingTransactions();

  const dispatch = useDispatch();
  const status = useSelector(selectStatus);

  const { getTokenBalances, getNFTBalances } = useMultiversXApi();

  const loadAccountData = async () => {
    const esdtBalances = await getTokenBalances(
      address,
      ALL_ESDT_ASSETS_TOKEN_IDENTIFIERS.concat(OLD_KOSON_TICKER)
    );
    const tokenBalances = await getNFTBalances(
      address,
      ALL_NFT_ASSET_TOKEN_IDENTIFIERS.concat(
        ...ALL_OLD_LAND_SFT_TOKEN_IDENTIFIERS
      ).concat(...OLD_STAKED_KOSON_TOKEN_IDS)
    );

    console.log('API token balances', tokenBalances);
    const kycStatus = await getKYCStatusV2(address);

    dispatch(setAccount({ address, herotag: account.username }));
    dispatch(setTokenBalances(esdtBalances.map(parseTokenBalanceType)));
    dispatch(setKYCStatus(kycStatus));

    const soulBalances = tokenBalances.filter((tb) =>
      isSoulNftToken(tb.collection)
    );
    const landPlots = tokenBalances.filter((tb) =>
      isLandPlotNftToken(tb.collection)
    );
    const landChests = tokenBalances.filter((tb) =>
      isLandChestNftToken(tb.collection)
    );
    const items = tokenBalances.filter((tb) => isItemNftToken(tb.collection));
    const unbondingKosons = tokenBalances.filter((tb) =>
      isUnbondingKosonToken(tb.collection)
    );

    const unmigratedAssets = tokenBalances.filter((tb) =>
      isUnmigratedAsset(tb.collection)
    );

    dispatch(setSoulsBalance(soulBalances.map(parseSoulTokenBalanceType)));
    dispatch(setLandPlotsBalance(landPlots.map(parseLandPlotTokenBalanceType)));
    dispatch(setLandChestsBalance(landChests.map(parseNFTTokenBalanceType)));
    dispatch(setItemsBalance(items.map(parseNFTTokenBalanceType)));
    dispatch(
      setUnbondingKosonsBalance(
        unbondingKosons.map(parseUnbondingKosonTokenBalanceType)
      )
    );
    dispatch(
      setUnmigratedAssets(unmigratedAssets.map(parseLandPlotTokenBalanceType))
    );
  };

  useEffect(() => {
    if (status !== 'loading' && hasPendingTransactions) {
      return;
    }

    if (!address || !address.startsWith('erd1')) {
      dispatch(setStatus('succeeded'));
      return;
    }

    loadAccountData()
      .then(() => {
        dispatch(setStatus('succeeded'));
      })
      .catch((err) => {
        console.log('Something went wrong when loading account data: ', err);
        dispatch(setStatus('failed'));
      });
  }, [address, hasPendingTransactions]);

  return <></>;
};
