import { SmartContract, U64Value } from '@multiversx/sdk-core/out';
import { getRemainingChestContent } from 'api/apiRequests';
import {
  LAND_CHEST_CONTENT_COLLECTIONS,
  LAND_CHEST_OPENING_SC_ADDRESS,
  OLD_LAND_PLOT_TOKEN_IDENTIFIER
} from 'config/dapp-config';
import {
  getSmartContract,
  Parser,
  Provider
} from 'contexts/Web3Context/helpers/getScObj';
import React, { useEffect, useState } from 'react';

export const useGetDropChances = () => {
  const nameMapping = ['Continental', 'Steepe', 'Panonic', 'Pontic'];
  const initialTotal = [15579, 10386, 6924, 1731];
  const initialRare = [33, 133, 200, 300];
  const initialLegendary = [11, 19, 26, 40];
  const [isLoading, setIsLoading] = useState(true);
  const [dropsRemaining, setDropsRemaining] = useState<ChestChance[]>([]);

  const loadRemainingItems = async () => {
    const contract = await getSmartContract(LAND_CHEST_OPENING_SC_ADDRESS);
    const remainingChestContent = await getRemainingChestContent();
    const chances = [];
    for (let i = 1; i < 5; i++) {
      const remainingRareForNonce = await loadRemainingItemsForNonce(
        contract,
        i
      );
      const guaranteedForNonce = remainingChestContent.data.filter(
        (rcc: any) => rcc.nonce === 6 - i
      )[0];
      chances.push(
        await buildChestChance(guaranteedForNonce, remainingRareForNonce, i)
      );
    }
    setDropsRemaining(chances);
    setIsLoading(false);
  };

  const loadRemainingItemsForNonce = async (
    contract: SmartContract,
    nonce: number
  ) => {
    const nonceArg = new U64Value(nonce);
    const interaction = contract.methodsExplicit.getRemainingChanceBasedItems([
      nonceArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      return parsedResponse.firstValue?.valueOf().map((val: any) => {
        const token_id = val[0].token_identifier;
        const amount_left = val[1].toNumber();
        return {
          token_id,
          amount_left
        };
      });
    }
    return [];
  };

  const buildChestChance = async (
    regularDropsRemaining: any,
    remainingRareItemsForNonce: any[],
    chestNonce: number
  ) => {
    const rareDrops = remainingRareItemsForNonce.filter(
      (item: any) =>
        item.token_id === LAND_CHEST_CONTENT_COLLECTIONS[0] ||
        item.token_id === OLD_LAND_PLOT_TOKEN_IDENTIFIER
    );
    const legendaryDrops = remainingRareItemsForNonce.filter(
      (item: any) =>
        item.token_id !== LAND_CHEST_CONTENT_COLLECTIONS[0] &&
        item.token_id !== OLD_LAND_PLOT_TOKEN_IDENTIFIER
    );

    const totalRareDrops =
      rareDrops.length === 0 ? 0 : rareDrops[0].amount_left;
    const totalLegendaryDrops =
      legendaryDrops.length === 0
        ? 0
        : legendaryDrops.reduce((prev, crt) => (prev += crt.amount_left), 0);
    const totalRegularDrops =
      parseInt(regularDropsRemaining.balance) -
      totalRareDrops -
      totalLegendaryDrops;

    const chance: ChestChance = {
      nonce: chestNonce,
      name: nameMapping[chestNonce - 1],
      regular: totalRegularDrops,
      rare: totalRareDrops,
      legendary: totalLegendaryDrops,
      initialTotal: initialTotal[chestNonce - 1],
      initialRegular:
        initialTotal[chestNonce - 1] -
        initialRare[chestNonce - 1] -
        initialLegendary[chestNonce - 1],
      initialRare: initialRare[chestNonce - 1],
      initialLegendary: initialLegendary[chestNonce - 1]
    };
    return chance;
  };

  useEffect(() => {
    loadRemainingItems();
  }, []);

  return {
    isLoading,
    dropsRemaining,
    refreshState: loadRemainingItems
  };
};

export interface ChestChance {
  nonce: number;
  name: string;
  regular: number;
  rare: number;
  legendary: number;
  initialTotal: number;
  initialRegular: number;
  initialRare: number;
  initialLegendary: number;
}
