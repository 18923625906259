import {
  ResultsParser,
  AbiRegistry,
  SmartContract,
  Address
} from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers';
import kosonStakingJson from 'abi/kosonStaking.abi.json';
import landChestOpeningJson from 'abi/landChestOpening.abi.json';
import landChestSaleJson from 'abi/landChestSale.abi.json';
import landChestStakingJson from 'abi/landChestStaking.abi.json';
import nftStakingJson from 'abi/nftStaking.abi.json';
import summoningJson from 'abi/summoning.abi.json';
import vestingJson from 'abi/vesting.abi.json';
import {
  gatewayAddress,
  KOSON_STAKING_POOL_SC_ADDRESSES,
  LAND_CHEST_OPENING_SC_ADDRESS,
  LAND_CHEST_SALE_SC_ADDRESS,
  LAND_PLOT_STAKING_SC_ADDRESS,
  SOUL_NFT_STAKING_SC_ADDRESS,
  SUMMONING_SC_ADDRESS,
  VESTING_SC_ADDRESS
} from 'config/dapp-config';

const abiMapping = (address: string) => {
  switch (address) {
    case VESTING_SC_ADDRESS:
      return vestingJson;
    case SOUL_NFT_STAKING_SC_ADDRESS:
      return nftStakingJson;
    case LAND_PLOT_STAKING_SC_ADDRESS:
      return landChestStakingJson;
    case SUMMONING_SC_ADDRESS:
      return summoningJson;
    case LAND_CHEST_SALE_SC_ADDRESS:
      return landChestSaleJson;
    case LAND_CHEST_OPENING_SC_ADDRESS:
      return landChestOpeningJson;
    default:
      if (KOSON_STAKING_POOL_SC_ADDRESSES.includes(address)) {
        return kosonStakingJson;
      }
      return undefined;
  }
};

export const Provider = new ProxyNetworkProvider(gatewayAddress, {
  timeout: 10000
});
export const Parser = new ResultsParser();

export const getSmartContract = (scAddress: string) => {
  const abi = loadAbiByAddress(scAddress);
  return new SmartContract({
    address: new Address(scAddress),
    abi
  });
};

const loadAbiByAddress = (address: string): AbiRegistry | undefined => {
  const json = abiMapping(address);
  if (json === undefined) {
    return undefined;
  }
  const abiRegistry = AbiRegistry.create(formatAbiJson(json));
  return abiRegistry;
};

const formatAbiJson = (abi: any) => {
  return {
    name: abi.name,
    endpoints: abi.endpoints,
    types: abi.types
  };
};
