import BigNumber from 'bignumber.js';
import { formatBigNumberString } from 'utils';

export const TokenMigrationAmountMessage = ({
  totalAmountIn,
  exchangeRate,
  tokenIn
}: {
  totalAmountIn: BigNumber;
  exchangeRate: number;
  tokenIn: string;
}) => {
  return (
    <span className='ms-3' style={{ fontSize: 'larger' }}>
      You will exchange{' '}
      <strong className='important-text'>
        {formatBigNumberString(totalAmountIn)}
      </strong>{' '}
      {tokenIn} for{' '}
      <strong className='important-text'>
        {formatBigNumberString(totalAmountIn.div(exchangeRate))}
      </strong>{' '}
      ESOTERIC KOSON
    </span>
  );
};
