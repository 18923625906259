import React from 'react';
import { Modal } from 'react-bootstrap';
import { LAND_CHEST_CONTENT_VIDEO_URL_MAPPING } from 'config/mapping-config';

export const ChestRewardModal = ({
  openedRewards,
  onDismiss
}: {
  openedRewards: any[];
  onDismiss: () => void;
}) => {
  const getDropCssClass = (identifier: string) => {
    const nonce = identifier.split('-')[2];
    switch (nonce) {
      case '01':
        return 'legendary';
      case '02':
        return 'pontic';
      case '03':
        return 'panonic';
      case '04':
        return 'steepe';
      default:
        return 'continental';
    }
  };

  return (
    <Modal show={openedRewards.length > 0} centered size='lg'>
      <Modal.Header>
        <h2>Chest Content</h2>
      </Modal.Header>
      <Modal.Body>
        <div className='container-fluid w-100'>
          <div className='row'>
            {openedRewards.map((rew, idx) => {
              const cssClass = getDropCssClass(rew.identifier);
              return (
                <div
                  className='col-lg-4 col-md-6 col-sm-12'
                  key={`land-chest-opening-reward-item-${idx}`}
                >
                  <div className={`card bg-black chest-drop ${cssClass}`}>
                    <div className='card-header d-flex justify-content-between'>
                      <div>{rew.name}</div>
                      <small>x{rew.value}</small>
                    </div>
                    <div className='card-body text-center'>
                      <video autoPlay muted loop className='img-responsive'>
                        <source
                          src={
                            LAND_CHEST_CONTENT_VIDEO_URL_MAPPING[rew.identifier]
                          }
                        />
                      </video>
                    </div>
                    <div className='card-footer text-center'>
                      <small className='text-primary'>{cssClass}</small>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-danger light' onClick={onDismiss}>
          Dismiss
        </button>
      </Modal.Footer>
    </Modal>
  );
};
