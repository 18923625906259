import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { SafeNumberInput } from 'components/Inputs/SafeNumberInput';
import { DEFAULT_TOKEN_DECIMALS } from 'config/dapp-config';
import { useKosonStakingPool } from 'hooks/contracts/kosonStakingPool/useKosonStakingPool';
import { selectEsdtBalance } from 'store/slices/kosonv2/account';
import { KosonInputSelector } from '../../../components/Inputs/KosonInputSelector';

export interface KosonStakingPoolStatsCardProps {
  title: string;
  data: Record<string, string>;
  unstakeTokenIdentifier?: string;
  poolAddress: string;
}

export const KosonStakingPoolStatsCard = ({
  title,
  data,
  unstakeTokenIdentifier,
  poolAddress
}: KosonStakingPoolStatsCardProps) => {
  const [unstakeAmount, setUnstakeAmount] = useState<BigNumber>(
    new BigNumber(0)
  );
  const selectTokenBalance = useSelector(selectEsdtBalance);
  const maxUnstakeBalance = selectTokenBalance(unstakeTokenIdentifier ?? '');

  const { sendStakeTransaction, sendUnstakeTransaction } =
    useKosonStakingPool();

  const handleStake = async (tokenId: string, amount: BigNumber) => {
    await sendStakeTransaction(poolAddress, tokenId, amount.toString(10));
  };

  const handleUnstake = async (tokenId: string, amount: BigNumber) => {
    await sendUnstakeTransaction(poolAddress, tokenId, amount.toString(10));
  };

  return (
    <div className='card koson-stats-card'>
      <div className='card-header'>{title}</div>
      <div className='card-body d-flex align-items-center'>
        <div className='w-100'>
          {Object.keys(data).map((key, index) => (
            <div
              className='stat-entry'
              key={`koson-staking-pool-stats-card-entry-${index}`}
            >
              <div>{key}</div>
              <div className='important-text' style={{ fontWeight: 'bolder' }}>
                {data[key]}
              </div>
            </div>
          ))}
          {unstakeTokenIdentifier && (
            <>
              <div className='mt-3'>
                <KosonInputSelector
                  buttonText={'Stake'}
                  buttonAction={handleStake}
                  inputPlaceHolder='Enter stake amount'
                />
              </div>
              <hr />
              <div className='container mt-3'>
                <div className='row'>
                  <div className='col-12 col-md-9'>
                    <SafeNumberInput
                      className='form-control form-control-lg mt-3'
                      placeholder='Enter unstake amount'
                      max={maxUnstakeBalance?.toString(10) ?? '0'}
                      onChange={(value) =>
                        setUnstakeAmount(
                          new BigNumber(value).shiftedBy(DEFAULT_TOKEN_DECIMALS)
                        )
                      }
                    />
                  </div>
                  <div className='col-12 col-md-3 mt-3'>
                    <button
                      className='btn btn-primary w-100'
                      onClick={() =>
                        handleUnstake(unstakeTokenIdentifier, unstakeAmount)
                      }
                    >
                      Unstake
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
