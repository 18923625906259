import { faCoins } from '@fortawesome/free-solid-svg-icons';
import {
  faGhost as faGhostLight,
  faMapLocation as faMapLocationLight
} from '@fortawesome/pro-light-svg-icons';
import {
  faGhost as faGhostSolid,
  faSword,
  faMapLocation
} from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import { toLocaleStringOptionsNoDecimals } from 'config/dapp-config';
import { routeNames } from 'routes';
import { selectInventoryAssets } from 'store/slices/kosonv2/account';
import { selectTotalStakedKosonByUser } from 'store/slices/kosonv2/koson-staking-pools';
import { selectLandPlotStakingStats } from 'store/slices/kosonv2/land-plot-staking-pool';
import { selectSoulStakingStats } from 'store/slices/kosonv2/soul-staking-pool';
import { formatBigNumberString } from 'utils';
import { KYCStatusCard } from './KYCStatusCard';
import { VestedKosonCard } from './VestedKosonCard';

const AccountStatsCards = () => {
  const { souls, landPlots, items } = useSelector(selectInventoryAssets);

  const soulStakingStats = useSelector(selectSoulStakingStats);
  const landPlotStakingStats = useSelector(selectLandPlotStakingStats);

  const totalStakedKosonByUser = useSelector(selectTotalStakedKosonByUser);

  const StakedSoulsCard = () => {
    return (
      <SimpleStatsCard
        title={'Staked Souls'}
        value={(soulStakingStats.userStakedNftsCount ?? 0).toLocaleString(
          undefined,
          toLocaleStringOptionsNoDecimals
        )}
        navUrl={routeNames.nftStaking}
        icon={faGhostSolid}
      />
    );
  };

  const StakedLandChestsCard = () => {
    return (
      <SimpleStatsCard
        title={'Staked Land Plots'}
        value={landPlotStakingStats.userStakedSftsCount.toLocaleString(
          undefined,
          toLocaleStringOptionsNoDecimals
        )}
        icon={faMapLocation}
        navUrl={routeNames.landchestStaking}
      />
    );
  };

  const StakedKosonCard = () => {
    return (
      <SimpleStatsCard
        title={'Staked KOSON'}
        value={formatBigNumberString(totalStakedKosonByUser)}
        icon={faCoins}
        navUrl={routeNames.kosonStaking}
        showKosonSymbol
      />
    );
  };

  return (
    <div>
      <div className='row'>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <VestedKosonCard />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>{StakedKosonCard()}</div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <SimpleStatsCard
            title={'Souls'}
            value={souls.length.toString() || 'N/A'}
            icon={faGhostLight}
          />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>{StakedSoulsCard()}</div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <SimpleStatsCard
            title={'Land plots'}
            value={
              landPlots
                .reduce((acc, crt) => (acc += parseInt(crt.amount)), 0)
                .toString() || 'N/A'
            }
            icon={faMapLocationLight}
            badgeText={(landPlots.length || 0) > 0 ? 'Stake now' : undefined}
            navUrl={
              (landPlots.length || 0) > 0
                ? routeNames.landchestStaking
                : undefined
            }
          />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          {StakedLandChestsCard()}
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <KYCStatusCard />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <SimpleStatsCard
            title={'Inventory items'}
            value={items
              .reduce((acc, crt) => (acc += parseInt(crt.amount)), 0)
              .toLocaleString(undefined, toLocaleStringOptionsNoDecimals)}
            icon={faSword}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountStatsCards;
