import {
  ContractFunction,
  Interaction,
  Transaction,
  U64Value
} from '@multiversx/sdk-core';
import { getChainID } from '@multiversx/sdk-dapp/utils';
import { MARKETPLACE_SC_ADDRESS } from 'config/dapp-config';
import { getSmartContract } from 'contexts/Web3Context/helpers/getScObj';

export const useGetOwnerTransaction = () => {
  const smartContract = getSmartContract(MARKETPLACE_SC_ADDRESS);

  const getTransaction = (
    functionName: 'endAuction' | 'withdraw',
    listingId: number
  ): Transaction => {
    const args = [new U64Value(listingId)];
    const interaction = new Interaction(
      smartContract,
      new ContractFunction(functionName),
      args
    );

    return interaction
      .withGasLimit(25_000_000)
      .withChainID(getChainID())
      .buildTransaction();
  };

  return getTransaction;
};
