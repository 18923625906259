import {
  StructType,
  FieldDefinition,
  U64Type,
  BinaryCodec
} from '@multiversx/sdk-core/out';
import { WrappedPaymentType } from './types';

export const decodeUnbondingBatchAttributes = (
  attributes: string
): WrappedPaymentType => {
  const wrappedPaymentType = new StructType('WrappedPayment', [
    new FieldDefinition('mint_epoch', '', new U64Type())
  ]);

  const decodedAttributes = new BinaryCodec()
    .decodeTopLevel(Buffer.from(attributes, 'base64'), wrappedPaymentType)
    .valueOf();

  return {
    mintEpoch: decodedAttributes.mint_epoch.toNumber()
  };
};
