import BigNumber from 'bignumber.js';
import { OLD_KOSON_STAKING_POOL_SC_ADDRESSES } from 'config/dapp-config';
import { KOSON_STAKING_POOLS } from 'config/koson-staking-pools';
import { int2hex } from 'contexts/Web3Context/helpers/generalUtils';
import { formatBigNumberString } from 'utils';
import { KosonStaking } from './types';

export const KosonStakingMigrationLogs = ({
  kosonStakingLogs
}: {
  kosonStakingLogs: { [key: string]: KosonStaking[] };
}) => {
  return (
    <>
      <div
        className='col-12 mt-3'
        style={{ fontSize: '22px', fontWeight: 'bolder' }}
      >
        Staked KOSON
      </div>
      {Object.keys(kosonStakingLogs).map((key, index) => (
        <div
          className='col-12 mt-3'
          key={`koson-staking-migration-logs-${key}-${index}`}
          style={{ fontSize: '20px' }}
        >
          Old{' '}
          {
            KOSON_STAKING_POOLS[
              OLD_KOSON_STAKING_POOL_SC_ADDRESSES.indexOf(key)
            ].name
          }{' '}
          Pool:{' '}
          <small style={{ fontWeight: 'normal' }}>
            {kosonStakingLogs[key].length} batches, Total:{' '}
            {formatBigNumberString(
              kosonStakingLogs[key].reduce(
                (acc, cur) => acc.plus(new BigNumber(cur.balance)),
                new BigNumber(0)
              )
            )}{' '}
            Staked KOSON; ~
            {formatBigNumberString(
              kosonStakingLogs[key].reduce(
                (acc, cur) => acc.plus(new BigNumber(cur.pendingRewards)),
                new BigNumber(0)
              )
            )}{' '}
            KOSON v1 pending rewards
          </small>
          <div>
            {kosonStakingLogs[key].map((stakedKoson, index2) => (
              <div
                key={`staked-koson-logs-${key}-batch-${index}-${index2}`}
                className='ms-3'
                style={{ fontSize: 'smaller' }}
              >
                <b className='important-text'>
                  {stakedKoson.collection}-{int2hex(stakedKoson.nonce)}
                </b>
                : {formatBigNumberString(stakedKoson.balance)}, Pending rewards:{' '}
                {formatBigNumberString(stakedKoson.pendingRewards)}
              </div>
            ))}
          </div>
        </div>
      ))}
      {/* <div
        className='col-12 mt-3'
        style={{ fontSize: '22px', fontWeight: 'bolder' }}
      >
        Souls{' '}
        <small style={{ fontWeight: 'normal' }}>
          {soulMigrationLog.stakedNfts.length} Staked souls;{' '}
          {soulMigrationLog.unbondingNfts.length} Unbonding souls;{' '}
          {formatBigNumberString(soulMigrationLog.pendingRewards)} KOSON v1
          Pending rewards
        </small>
      </div>
      {soulMigrationLog.stakedNfts.length > 0 && (
        <>
          <div className='col-12 mt-3' style={{ fontSize: '20px' }}>
            Staked Souls
          </div>
          <div className='col-12 mt-1'>
            {soulMigrationLog.stakedNfts.map((stakedNft, index) => (
              <div key={`staked-nfts-logs-${index}`} className='ms-3'>
                {stakedNft.token_id}-{int2hex(parseInt(stakedNft.nonce))}
              </div>
            ))}
          </div>
        </>
      )}
      {soulMigrationLog.unbondingNfts.length > 0 && (
        <>
          <div className='col-12 mt-3' style={{ fontSize: '20px' }}>
            Unbonding Souls
          </div>
          <div className='col-12 mt-1'>
            {soulMigrationLog.unbondingNfts.map((unbondingNft, index) => (
              <div key={`unbonding-nfts-logs-${index}`} className='ms-3'>
                {unbondingNft.field1.map((stakedNft, index2) => (
                  <div
                    key={`unbonding-nfts-logs-batch-${index}-${index2}`}
                    className='ms-3'
                  >
                    {stakedNft.token_id}-{int2hex(parseInt(stakedNft.nonce))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )} */}
    </>
  );
};
