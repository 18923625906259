import Synaps from '@synaps-io/react-verify';

const KycStatusModalContent = ({ sessionId }: { sessionId: string }) => {
  return (
    <div className='modal-dialog modal-lg' style={{ zIndex: '2050!important' }}>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Know Your Customer</h5>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
          ></button>
        </div>
        <div className='modal-body with-scroll d-flex justify-content-center'>
          <Synaps sessionId={sessionId} service={'individual'} />
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-danger light'
            data-bs-dismiss='modal'
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default KycStatusModalContent;
