import { faSackDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SimpleStatsCard/style.css';
import { useSoulNftStaking } from 'hooks/contracts/soulNftStaking/useSoulNftStaking';
import { EsdtTokenType } from 'types/MultiversX';
import { formatBigNumberString, getKosonTokenNameByIdentifier } from 'utils';
import { KosonSymbol } from 'components/KosonSymbol';

const StakingRewardsCard = ({
  rewards,
  icon,
  badgeText,
  valueFontSize,
  iconColor,
  claimRewards
}: {
  rewards: EsdtTokenType[];
  icon?: any;
  badgeText?: string;
  kosonStakingIcon?: number;
  valueFontSize?: string;
  iconColor?: string;
  claimRewards: () => Promise<void>;
}) => {
  const canClaim =
    rewards.find((reward) => reward.amount !== '0') !== undefined;

  const handleClaimRewards = async () => {
    if (!canClaim) {
      return;
    }

    await claimRewards();
  };

  return (
    <div className='stats-card card'>
      <div className='card-header bgl-warning text-warning'>
        <span className='text-white'>Claimable Rewards</span>
        {icon && iconColor && (
          <FontAwesomeIcon icon={icon} style={{ color: iconColor }} />
        )}
        <FontAwesomeIcon icon={faSackDollar} />
      </div>
      <div className='card-body ms-2 d-flex justify-content-between align-items-end'>
        <h4 className='mb-0' style={{ fontSize: valueFontSize ?? '' }}>
          {rewards.map((reward, index) => (
            <div key={`soul-staking-reward-${index}`}>
              {formatBigNumberString(reward.amount)}{' '}
              {/* <KosonLogo
                className='mb-1 me-2'
                style={{ width: '20px', height: '34px', marginLeft: '5px' }}
              /> */}
              <KosonSymbol tokenIdentifier={reward.tokenIdentifier} />
              {getKosonTokenNameByIdentifier(reward.tokenIdentifier)}
            </div>
          ))}
        </h4>
        <button
          className='btn btn-primary'
          onClick={handleClaimRewards}
          disabled={!canClaim}
        >
          Claim Rewards
        </button>
      </div>
      <div className='card-footer p-1 border-0 mb-1'>
        {badgeText && (
          <span className='badge bg-warning ms-4 mb-2'>{badgeText}</span>
        )}
      </div>
    </div>
  );
};

export default StakingRewardsCard;
