import { useEffect, useState } from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { KosonStakingMigrationLogs } from './KosonStakingMigrationLogs';
import { LandMigrationLogs } from './LandMigrationLogs';
import { SoulMigrationLogs } from './SoulMigrationLogs';
import { MigrationLog } from './types';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';

export const AutomatedMigrationLogs = () => {
  const { address } = useGetAccountInfoWrapper();
  const [migrationLog, setMigrationLog] = useState<MigrationLog>({});

  const getMigrationLog = async () => {
    if (!address) return;

    const basePath = window.location.origin;
    const path = `${basePath}/migrationLogs/${address}.json`;
    try {
      const { data } = await axios.get(path);
      if (!data) {
        return undefined;
      }

      return data;
    } catch (err) {
      return undefined;
    }
  };

  useEffect(() => {
    getMigrationLog().then((data) => setMigrationLog(data));
  }, []);

  return !migrationLog ? null : (
    <div>
      <h5>Staked Souls and Staked Land Plots</h5>
      <span>
        All your staked Souls and Land Plots have been upgraded and migrated on
        your behalf for a better user experience.
      </span>
      <br />
      <span>
        Below you can see the list of all the migrations that have been
        automatically been applied to your account.
      </span>
      <br />
      <br />
      <a
        className='btn btn-primary'
        href={`${window.location.origin}/migrationLogs/${address}.json`}
        target='_blank'
        rel='noreferrer'
      >
        See raw migration log
      </a>
      {/* Add the list of automated migrations here */}
      <div className='row'>
        {migrationLog.soul && (
          <SoulMigrationLogs soulMigrationLog={migrationLog.soul} />
        )}
        {migrationLog.land && (
          <LandMigrationLogs landMigrationLog={migrationLog.land} />
        )}
        {migrationLog.kosonStaking && (
          <KosonStakingMigrationLogs
            kosonStakingLogs={migrationLog.kosonStaking}
          />
        )}
      </div>
    </div>
  );
};
