import React from 'react';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { useAssetRemapper } from 'hooks/contracts/assetRemapper/useAssetRemapper';
import { selectUnmigratedStakedKosonBatches } from 'store/slices/kosonv2/account';
import { formatBigNumberString } from 'utils';
import { TokenMigrationAmountMessage } from './TokenMigrationAmountMessage';

export const StakedKosonMigration = () => {
  const { sendMigrateTransaction } = useAssetRemapper();

  const unmigratedStakedKosonBatches = useSelector(
    selectUnmigratedStakedKosonBatches
  );
  const totalUnmigratedStakedKosonAmount = unmigratedStakedKosonBatches.reduce(
    (acc, curr) => {
      return acc.plus(new BigNumber(curr.amount));
    },
    new BigNumber(0)
  );
  const kosonSwapRate = 516.619;

  const handleMigrateStakedKoson = async () => {
    const payments = unmigratedStakedKosonBatches.map((asset) => ({
      tokenIdentifier: asset.tokenIdentifier,
      amount: asset.amount,
      nonce: asset.nonce
    }));

    await sendMigrateTransaction(payments);
  };

  return (
    <div>
      <h3>Migrate Previously Staked Assets</h3>
      <div className='mt-3'>
        <h5>Migrate Staked Koson Batches</h5>
        <span>
          Staked KOSON is replaced by the new PRIMORDIAL KOSON token. <br />
          The pending staking rewards have been airdropped to your wallet and
          the remaining staked KOSON batches must be manually migrated.
          <br />
          The migration is a one-way process and the exchange rate applied is
          the same as for the $KOSON token:{' '}
          <strong className='important-text'>
            {' '}
            {kosonSwapRate} STAKED KOSON : 1 PRIMORDIAL KOSON
          </strong>
          .
        </span>
      </div>
      <div className='mt-3 mb-2'>
        <TokenMigrationAmountMessage
          totalAmountIn={totalUnmigratedStakedKosonAmount}
          exchangeRate={kosonSwapRate}
          tokenIn={'Staked KOSON'}
        />
      </div>
      <div className='mb-2'>
        {unmigratedStakedKosonBatches.map((asset, index) => (
          <div key={index} className='ms-4'>
            <strong className='important-text'>
              {formatBigNumberString(asset.amount)}
            </strong>{' '}
            x {asset.fullIdentifier}
          </div>
        ))}
      </div>
      <div className='mb-5'>
        <button
          className='ms-3 btn btn-outline-primary'
          onClick={handleMigrateStakedKoson}
        >
          Migrate
        </button>
      </div>
    </div>
  );
};
