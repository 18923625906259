import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { StoreStateStatusType, StoreStateType } from 'types/store';

export interface BlockchainStatsState {
  epoch: number;
}

const defaultState: BlockchainStatsState = {
  epoch: 0
};

const initialState: StoreStateType<BlockchainStatsState> = {
  data: defaultState,
  status: 'loading',
  error: null
};

const blockchainStatsSlice = createSlice({
  name: 'blockchainStats',
  initialState,
  reducers: {
    setStatus: (
      state: StoreStateType<BlockchainStatsState>,
      action: PayloadAction<StoreStateStatusType>
    ) => {
      state.status = action.payload;
    },
    setEpoch: (
      state: StoreStateType<BlockchainStatsState>,
      action: PayloadAction<number>
    ) => {
      state.data.epoch = action.payload;
      console.log('BlockchainStats->setEpoch:', state.data.epoch);
    }
  }
});

export const { setStatus, setEpoch } = blockchainStatsSlice.actions;

export const selectEpoch = (state: RootState): number =>
  state.kosonv2.blockchainStats.data.epoch;

export const selectStatus = (state: RootState) =>
  state.kosonv2.blockchainStats.status;

export default blockchainStatsSlice.reducer;
