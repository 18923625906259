import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AllMarketplaceListings from 'components/Containers/AllMarketplaceListings';
import { Loading } from 'components/Loading';
import MarketplaceListingPage from 'pages/MarketplaceListing';
import { RootState } from 'store';
import { MarketplaceListingV2 } from 'types';

const Marketplace = () => {
  const { data, status } = useSelector(
    (state: RootState) => state.marketplace.marketplaceListings
  );
  const { allListings } = data;
  const [listing, setListing] = React.useState<MarketplaceListingV2>();
  const { results } = useParams();
  React.useEffect(() => {
    const paramListingId = document.location.search.replace('?listingId=', '');
    const listing = allListings.find(
      (l) => l.listingId === parseInt(paramListingId)
    );
    setListing(listing);
  }, [window.location.search, allListings, results]);

  return status === 'loading' ? (
    <Loading coverFullPage={true} loadingText='Marketplace Listings' />
  ) : listing ? (
    <MarketplaceListingPage listing={listing} />
  ) : (
    <>
      <div className='row'>
        <AllMarketplaceListings listings={allListings} hasLimit={false} />
      </div>
    </>
  );
};

export default Marketplace;
