import React from 'react';
import {
  faCoins,
  faGhost,
  faMapLocation
} from '@fortawesome/pro-solid-svg-icons';
import { toLocaleStringOptions } from 'config/dapp-config';
import { Web3Context } from 'contexts/Web3Context';
import { routeNames } from 'routes';
import EarnCard from './EarnCard';

const Earn = () => {
  const context = React.useContext(Web3Context);
  // const [landChestStakingRewardMsg, setLandChestStakingRewardMsg] =
  //   React.useState('');

  // React.useEffect(() => {
  //   setLandChestStakingRewardMsg(getLandChestStakingMessage());
  // }, [context.stakedLandChestsState]);

  // const getLandChestStakingMessage = () => {
  //   if ((context.stakedLandChestsState?.dailyRewards || []).length === 4) {
  //     return `Earn up to ${context.stakedLandChestsState?.dailyRewards[3].toLocaleString(
  //       undefined,
  //       toLocaleStringOptions
  //     )} $KOSON per land chest/plot on a daily basis`;
  //   } else {
  //     return '';
  //   }
  // };

  return (
    <div className='row'>
      <div className='col-lg-6 col-md-12 col-sm-12'>
        <EarnCard
          title='Soul staking'
          titleIcon={faGhost}
          navRoute={routeNames.nftStaking}
          description={
            'Lock your Soul NFTs and get a share of the daily $KOSON reward.'
          }
          backgroundImageUrl={'images/aoz/death.png'}
          rewardMessage='Earn 5/70 of daily $ESOKOSON emission'
          // rewardMessage={`Earn ~${context.nftStakingState?.singleNftDailyReward.toLocaleString(
          //   undefined,
          //   toLocaleStringOptions
          // )} $KOSON per NFT on a daily basis`}
        />
      </div>
      <div className='col-lg-6 col-md-12 col-sm-12'>
        <EarnCard
          title='Land plot staking'
          titleIcon={faMapLocation}
          navRoute={routeNames.landchestStaking}
          description={
            'Earn a share of the daily $KOSON reward by staking your Land Plots'
          }
          backgroundImageUrl={
            // 'images/aoz/landPlots/combinedcard-land-chests.png'
            'images/aoz/landPlots/Alpine.png'
          }
          rewardMessage={'Earn 5/70 of daily $ESOKOSON emission'}
        />
      </div>
      <div
        className='col-lg-12 col-md-12 col-sm-12'
        style={{ minHeight: '250px' }}
      >
        <EarnCard
          title='Koson staking'
          titleIcon={faCoins}
          navRoute={routeNames.kosonStaking}
          description={
            'Lock your $KOSON tokens for different time frames and earn a share of the daily $KOSON reward.'
          }
          backgroundImageUrl={'images/aoz/money.jpg'}
          rewardMessage='Earn between 5/70 and 10/70 of daily $ESOKOSON emission'
        />
      </div>
    </div>
  );
};

export default Earn;
