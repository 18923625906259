import React from 'react';
import { useSelector } from 'react-redux';
import { OLD_KOSON_TICKER } from 'config/dapp-config';
import {
  selectEsdtBalance,
  selectUnmigratedAssets,
  selectUnmigratedStakedKosonBatches
} from 'store/slices/kosonv2/account';
import { AutomatedMigrationLogs } from './AutomatedMigrationLogs';
import { KosonTokenMigration } from './KosonTokenMigration';
import { LandChestContentMigration } from './LandChestContentMigration';
import { StakedKosonMigration } from './StakedKosonMigration';

export const Migrate = () => {
  const kosonV1Balance = useSelector(selectEsdtBalance)(OLD_KOSON_TICKER);
  const unmigratedAssets = useSelector(selectUnmigratedAssets);
  const unmigratedStakedKosonBatches = useSelector(
    selectUnmigratedStakedKosonBatches
  );

  const mustMigrateKosonV1 = kosonV1Balance.gt(0);
  const mustMigrateLandChestAssets = unmigratedAssets.length > 0;
  const mustMigrateStakedKoson = unmigratedStakedKosonBatches.length > 0;

  const hasNothingToMigrate = !(
    mustMigrateKosonV1 ||
    mustMigrateLandChestAssets ||
    mustMigrateStakedKoson
  );

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h1>Migration Assistant</h1>
          <h6>
            Here you can manage all your assets that need to be migrated to the
            new tokenomics version
          </h6>
        </div>
      </div>
      <div className='row mt-5'>
        {mustMigrateKosonV1 && (
          <div className='col-12 mt-3'>
            <KosonTokenMigration />
          </div>
        )}
        {mustMigrateLandChestAssets && (
          <div className='col-12 mt-3'>
            <LandChestContentMigration />
          </div>
        )}
        {mustMigrateStakedKoson && (
          <div className='col-12'>
            <StakedKosonMigration />
          </div>
        )}
        {hasNothingToMigrate && (
          <div className='col-12 mt-3'>
            <h3>
              You have no assets to migrate at the moment. Please get in touch
              with us on Discord if you have any questions.
            </h3>
          </div>
        )}
      </div>
      <div className='row mt-5'>
        <div className='col-12 mt-3 mb-2'>
          <AutomatedMigrationLogs />
        </div>
      </div>
    </>
  );
};
