import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { SafeNumberInput } from 'components/Inputs/SafeNumberInput';
import {
  ALL_KOSON_TOKEN_IDENTIFIERS,
  DEFAULT_TOKEN_DECIMALS
} from 'config/dapp-config';
import { selectEsdtBalance } from 'store/slices/kosonv2/account';
import { KosonTokenSelector } from './KosonTokenSelector';

export interface KosonInputSelector {
  buttonText: string;
  buttonAction: (
    selectedTokenIdentifier: string,
    selectedAmount: BigNumber
  ) => void;
  inputPlaceHolder?: string;
  value?: BigNumber;
  isDisabled?: boolean;
  hideMaxBalance?: boolean;
  onTokenChange?: (selectedTokenIdentifier: string) => void;
}

export const KosonInputSelector = ({
  buttonText,
  buttonAction,
  inputPlaceHolder,
  value,
  isDisabled,
  hideMaxBalance,
  onTokenChange
}: KosonInputSelector) => {
  const [selectedKosonTokenIndex, setSelectedKosonTokenIndex] =
    useState<number>(0);
  const [selectedAmount, setSelectedAmount] = useState<BigNumber>(
    value || new BigNumber(0)
  );

  const selectTokenBalance = useSelector(selectEsdtBalance);
  const kosonBalance = selectTokenBalance(
    ALL_KOSON_TOKEN_IDENTIFIERS[selectedKosonTokenIndex]
  );

  const handleAmountChange = (newValue: string) => {
    if (value === undefined) {
      setSelectedAmount(
        new BigNumber(newValue).shiftedBy(DEFAULT_TOKEN_DECIMALS)
      );
    }
  };

  useEffect(() => {
    if (onTokenChange) {
      onTokenChange(ALL_KOSON_TOKEN_IDENTIFIERS[selectedKosonTokenIndex]);
    }
  }, [selectedKosonTokenIndex]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-5 mt-3 order-2 order-md-1'>
          <SafeNumberInput
            className='form-control form-control-lg'
            placeholder={inputPlaceHolder ?? 'Enter amount'}
            max={kosonBalance.toString(10)}
            onChange={handleAmountChange}
            value={
              value
                ? value.shiftedBy(-DEFAULT_TOKEN_DECIMALS).toString(10)
                : undefined
            }
            disabled={isDisabled || value !== undefined}
            hideMaxBalance={hideMaxBalance}
          />
        </div>
        <div className='col-12 col-md-4 mt-3 order-1 order-md-2'>
          <KosonTokenSelector
            selectedIndex={selectedKosonTokenIndex}
            onSelect={setSelectedKosonTokenIndex}
          />
        </div>
        <div className='col-12 col-md-3 mt-3 order-3 order-md-3'>
          <button
            className='btn btn-primary w-100'
            onClick={() =>
              buttonAction(
                ALL_KOSON_TOKEN_IDENTIFIERS[selectedKosonTokenIndex],
                value || selectedAmount
              )
            }
            disabled={
              isDisabled ||
              (value || selectedAmount).isEqualTo(0) ||
              kosonBalance.isLessThan(value || selectedAmount)
            }
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};
