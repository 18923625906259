import React from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { AccountType } from '@multiversx/sdk-dapp/types';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import BigNumber from 'bignumber.js';
import { getActivityTransactions, getKosonBalance } from 'api/apiRequests';
import { getKYCStatus } from 'api/backendRequests';
import { toLocaleStringOptions } from 'config/dapp-config';
import { getActivityInfo } from 'pages/Dashboard/Overview/helpers/utils';
import { ActivityTransaction } from '../types';
import { useGetAccountInfoWrapper } from './MultiversX/useGetAccountInfoWrapper';

export default function useAccountInfo() {
  const { account } = useGetAccountInfoWrapper();
  const [heroTag] = React.useState(account.username);
  const [egldBalance, setEgldBalance] = React.useState(0);
  const [kosonBalance, setKosonBalance] = React.useState(0);
  const [denominatedKosonBalance, setDenominatedKosonBalance] =
    React.useState('0');
  const [activityTransactions, setActivityTransactions] = React.useState<
    ActivityTransaction[]
  >([]);
  const [kycStatus, setKycStatus] = React.useState<KYCStatusType>({
    status: KYC_STATUS.NOT_STARTED,
    sessionId: 'N/A'
  });

  const [egldBalanceLoading, setEgldBalanceLoading] = React.useState(true);
  const [kosonBalanceLoading, setKosonBalanceLoading] = React.useState(true);
  const [activityLoading, setActivityLoading] = React.useState(true);
  const [kycLoading, setKycLoading] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(
      egldBalanceLoading || kosonBalanceLoading || activityLoading || kycLoading
    );
  }, [egldBalanceLoading, kosonBalanceLoading, activityLoading, kycLoading]);

  const parseDenominatedAmount = (amount: string) => {
    return new BigNumber(amount).div(new BigNumber(10).pow(18)).toNumber();
  };

  React.useEffect(() => {
    if (!account.address.startsWith('erd1')) {
      return;
    }
    setEgldBalance(parseDenominatedAmount(account.balance));
    setEgldBalanceLoading(false);
  }, [account]);

  React.useEffect(() => {
    if (!account.address.startsWith('erd1')) {
      return;
    }
    getKosonBalance(account.address).then((res) => {
      if (res.success) {
        if (res.data.length === 0) {
          setDenominatedKosonBalance('0');
          setKosonBalance(0);
        } else {
          setDenominatedKosonBalance(res.data[0].balance);
          setKosonBalance(parseDenominatedAmount(res.data[0].balance));
        }
      }
      setKosonBalanceLoading(false);
    });
  }, [account]);

  React.useEffect(() => {
    if (!account.address.startsWith('erd1')) {
      return;
    }
    getActivityTransactions(account.address).then((res) => {
      if (res.success) {
        const items = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].function === undefined) {
            continue;
          }
          items.push(getActivityInfo(res.data[i]));
        }
        setActivityTransactions(items);
      }
      setActivityLoading(false);
    });
  }, [account]);

  React.useEffect(() => {
    if (!account.address.startsWith('erd1')) {
      return;
    }
    getKYCStatus(account.address).then((res) => {
      if (res.success) {
        const isKycVerified =
          res.data.status === 'VERIFIED' && res.data.state === 'VALIDATED';
        const isKycStarted = !isKycVerified && res.data.status !== null;
        const kycInfo: KYCStatusType = kycStatus;
        kycInfo.sessionId = res.data.sessionId;
        if (isKycVerified) {
          kycInfo.status = KYC_STATUS.VERIFIED;
        } else if (isKycStarted) {
          kycInfo.status = KYC_STATUS.STARTED;
        }
        setKycStatus(kycInfo);
      }
      setKycLoading(false);
    });
  }, [account]);

  return {
    account: account,
    heroTag: heroTag,
    egldBalance: egldBalance,
    egldBalanceDenominated: account.balance,
    egldBalanceString: egldBalance.toLocaleString(
      'en-US',
      toLocaleStringOptions
    ),
    kosonBalance: kosonBalance,
    kosonBalanceDenominated: denominatedKosonBalance,
    kosonBalanceString: kosonBalance.toLocaleString(
      'en-US',
      toLocaleStringOptions
    ),
    refreshState: refreshAccount,
    pastTransactions: activityTransactions,
    kycStatus: kycStatus,
    isActivityLoading: activityLoading,
    isLoading: isLoading
  };
}

export interface IAccountInfoType {
  account: AccountType;
  heroTag: string | undefined;
  egldBalance: number;
  egldBalanceDenominated: string;
  egldBalanceString: string;
  kosonBalance: number;
  kosonBalanceDenominated: string;
  kosonBalanceString: string;
  refreshState: () => Promise<any>;
  pastTransactions: ActivityTransaction[];
  kycStatus: KYCStatusType;
  isActivityLoading: boolean;
  isLoading: boolean;
}

export enum KYC_STATUS {
  NOT_STARTED = 0,
  STARTED = 1,
  VERIFIED = 2
}

export interface KYCStatusType {
  status: KYC_STATUS;
  sessionId: string;
}

export const KYCStatusType_DEFAULT = {
  status: KYC_STATUS.NOT_STARTED,
  sessionId: 'N/A'
};
