import React from 'react';
import BigNumber from 'bignumber.js';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DEFAULT_TOKEN_DECIMALS } from 'config/dapp-config';
import { useKosonStakingPool } from 'hooks/contracts/kosonStakingPool/useKosonStakingPool';
import { selectEpoch } from 'store/slices/kosonv2/blockchain-stats';

export interface UnbondingKosonBatchProps {
  amount: string;
  unbondingStartEpoch: number;
  maxUnbondingEpochs: number;
  maxUnbondingFee: number;
  poolAddress: string;
  batchTokenIdentifier: string;
  batchNonce: number;
}

export const UnbondingKosonBatch = ({
  amount,
  unbondingStartEpoch,
  maxUnbondingEpochs,
  maxUnbondingFee,
  poolAddress,
  batchTokenIdentifier,
  batchNonce
}: UnbondingKosonBatchProps) => {
  const currentEpoch = useSelector(selectEpoch);
  const unlockEpoch = unbondingStartEpoch + maxUnbondingEpochs;
  const epochsLeft = unlockEpoch - currentEpoch;
  const isUnlocked = epochsLeft < 0;
  const feePercentage = (maxUnbondingFee * epochsLeft) / maxUnbondingEpochs;

  const [showModal, setShowModal] = React.useState(false);

  const { sendClaimUnstakedTransaction } = useKosonStakingPool();

  const handleClaim = async () => {
    await sendClaimUnstakedTransaction(poolAddress, [
      {
        tokenIdentifier: batchTokenIdentifier,
        nonce: batchNonce,
        amount: amount
      }
    ]);
    handleHideConfirmationModal();
  };

  const handleShowConfirmationModal = () => {
    setShowModal(true);
  };

  const handleHideConfirmationModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className='d-flex justify-content-between m-2 p-2 border-bottom flex-column flex-md-row align-items-center'>
        <div className='text-center'>
          <strong className='important-text'>
            {new BigNumber(amount)
              .shiftedBy(-DEFAULT_TOKEN_DECIMALS)
              .toString(10)}
          </strong>{' '}
          {!isUnlocked ? (
            <>
              $KOSON unlocking in{' '}
              <strong className='important-text'>{epochsLeft}</strong> epochs
            </>
          ) : (
            <>$KOSON unlocked</>
          )}
        </div>
        <div className='d-flex justify-content-md-center justify-content-end'>
          {!isUnlocked ? (
            <button
              className='btn btn-outline-primary m-2'
              onClick={handleShowConfirmationModal}
            >
              Unlock now with {feePercentage * 100}% fee
            </button>
          ) : (
            <button
              className='btn btn-outline-primary m-2'
              onClick={handleClaim}
            >
              Claim now
            </button>
          )}
        </div>
      </div>
      <Modal show={showModal} centered>
        <Modal.Header className='border-0'>
          <Modal.Title>Confirm $KOSON Unlock</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <p>
            You are about to unlock{' '}
            <strong className='important-text'>
              {new BigNumber(amount)
                .shiftedBy(-DEFAULT_TOKEN_DECIMALS)
                .toString(10)}
            </strong>{' '}
            $KOSON with a fee of{' '}
            <strong className='important-text'>{feePercentage * 100}%</strong>.
          </p>
          <p>This action is irreversible. Are you sure you want to proceed?</p>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-end border-0'>
          <button
            className='btn btn-outline-primary m-2'
            onClick={handleHideConfirmationModal}
          >
            Cancel
          </button>
          <button className='btn btn-primary m-2' onClick={handleClaim}>
            Unlock
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
