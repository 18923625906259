import {
  ContractFunction,
  Interaction,
  TokenIdentifierValue,
  TokenPayment,
  Transaction,
  U64Value
} from '@multiversx/sdk-core';
import { getChainID } from '@multiversx/sdk-dapp/utils';
import BigNumber from 'bignumber.js';
import {
  DEFAULT_TOKEN_DECIMALS,
  MARKETPLACE_SC_ADDRESS
} from 'config/dapp-config';
import { getSmartContract } from 'contexts/Web3Context/helpers/getScObj';

export const useGetBuyoutTransaction = () => {
  const smartContract = getSmartContract(MARKETPLACE_SC_ADDRESS);

  const getTransaction = (buyoutPayload: BuyoutPayload): Transaction => {
    const functionName = buyoutPayload.isBuyout ? 'buyout' : 'bid';

    const args = [
      new U64Value(buyoutPayload.listingId),
      new TokenIdentifierValue(buyoutPayload.collection),
      new U64Value(buyoutPayload.nonce)
    ];
    const interaction = new Interaction(
      smartContract,
      new ContractFunction(functionName),
      args
    );

    let transaction = interaction
      .withGasLimit(25_000_000)
      .withChainID(getChainID());

    if (buyoutPayload.paymentToken === undefined) {
      transaction = transaction.withValue(
        buyoutPayload.listingAmountDenominated
      );
    } else {
      transaction = transaction.withSingleESDTTransfer(
        TokenPayment.fungibleFromAmount(
          buyoutPayload.collection,
          new BigNumber(buyoutPayload.listingAmountDenominated),
          DEFAULT_TOKEN_DECIMALS
        )
      );
    }
    return transaction.buildTransaction();
  };

  return getTransaction;
};

export interface BuyoutPayload {
  listingId: number;
  collection: string;
  nonce: number;
  paymentToken: string | undefined;
  listingAmountDenominated: string | BigNumber;
  isBuyout: boolean;
}
