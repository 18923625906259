import { useEffect, useState } from 'react';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { faCircleX, faShieldCheck } from '@fortawesome/pro-light-svg-icons';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { getKYCStatus } from 'api/backendRequests';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import KycStatusModalContent from 'components/Modals/KycStatusModalContent';
import { KYC_STATUS, KYCStatusType } from 'hooks/useAccountInfo';
import { useSelector } from 'react-redux';
import { selectKYCStatus } from 'store/slices/kosonv2/account';

export const KYCStatusCard = () => {
  const kycStatus = useSelector(selectKYCStatus);

  let kycStatusText = '';
  let kycStatusIcon: any;
  let kycStatusColor;
  let kycBadgeText;
  switch (kycStatus.status) {
    case KYC_STATUS.NOT_STARTED:
      kycStatusText = 'Not done';
      kycStatusIcon = faCircleX;
      kycStatusColor = 'red';
      kycBadgeText = 'Start now';
      break;
    case KYC_STATUS.STARTED:
      kycStatusText = 'Started';
      kycStatusIcon = faCirclePlay;
      kycBadgeText = 'Continue now';
      break;
    case KYC_STATUS.VERIFIED:
      kycStatusText = 'Verified';
      kycStatusIcon = faShieldCheck;
      kycStatusColor = 'green';
      break;
  }

  return (
    <>
      <div data-bs-toggle='modal' data-bs-target='#runKycModal'>
        <SimpleStatsCard
          title={'KYC Status'}
          value={kycStatusText}
          icon={kycStatusIcon}
          iconColor={kycStatusColor}
          badgeText={kycBadgeText}
        />
      </div>
      <div
        className='modal fade'
        id='runKycModal'
        tabIndex={-1}
        role='dialog'
        aria-hidden='true'
      >
        <KycStatusModalContent sessionId={kycStatus.sessionId || ''} />
      </div>
    </>
  );
};
