import { Loading } from 'components/Loading';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';
import ListingsContainer from './ListingsContainer';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const Listings = () => {
  const { marketplaceState } = React.useContext(Web3Context);
  const { status, data } = useSelector(
    (state: RootState) => state.accountInfo.marketplaceListings
  );
  return status === 'loading' ? (
    <Loading />
  ) : (
    <div className='row'>
      <ListingsContainer listings={[]} listings2={data?.listings || []} />
    </div>
  );
};

export default Listings;
