import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import Activity from 'components/Activity';
import NftStaking from 'components/Containers/NftStaking';
import { PRIMORDIAL_KOSON_TOKEN_IDENTIFIER } from 'config/dapp-config';
import { useSoulNftStaking } from 'hooks/contracts/soulNftStaking/useSoulNftStaking';
import { selectSoulNfts } from 'store/slices/kosonv2/account';
import { selectStakedSouls } from 'store/slices/kosonv2/soul-staking-pool';
import { ACTIVITY_INTERACTION_TYPES } from 'types';
import { StakeableAssetType } from 'types/store';
import NftStakingCardInfo from './CardInfo';

const SoulNftStaking = () => {
  const stakedSouls = useSelector(selectStakedSouls);
  const walletSouls = useSelector(selectSoulNfts);

  const { stakeNfts, unstakeNfts } = useSoulNftStaking();

  const handleStakeNfts = async (
    nfts: StakeableAssetType[],
    quantities: { [identifier: string]: number }
  ) => {
    const payments = nfts.map((nft) => ({
      tokenIdentifier: nft.tokenIdentifier,
      nonce: nft.nonce,
      amount: (quantities[nft.tokenIdentifier] ?? nft.amount).toString()
    }));

    if (payments.length === 0) {
      return;
    }

    await stakeNfts(payments);
  };

  const handleUnstake = async (
    nftsToUnstake: StakeableAssetType[],
    _: { [identifier: string]: number },
    unstakeFee: BigNumber
  ) => {
    const unstakeRequest = nftsToUnstake.map((nft) => ({
      tokenIdentifier: nft.tokenIdentifier,
      nonce: nft.nonce,
      amount: nft.amount
    }));

    const feePayment = {
      tokenIdentifier: PRIMORDIAL_KOSON_TOKEN_IDENTIFIER,
      amount: unstakeFee.multipliedBy(1.02).toString(10),
      nonce: 0
    };

    if (unstakeRequest.length === 0) {
      return;
    }

    await unstakeNfts(unstakeRequest, feePayment);
  };

  return (
    <>
      <div className='row'>
        <NftStakingCardInfo />
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
          <NftStaking
            title={'Soul Nft Staking'}
            stakedItems={stakedSouls}
            availableItems={walletSouls}
            handleStake={handleStakeNfts}
            handleUnstake={handleUnstake}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
          <Activity interaction={ACTIVITY_INTERACTION_TYPES.NftStaking} />
        </div>
      </div>
    </>
  );
};

export default SoulNftStaking;
