import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { Loading } from 'components/Loading';
import { toLocaleStringOptions } from 'config/dapp-config';
import { ChestChance } from 'hooks/LandChestOpening/useGetDropChances';

export const ChestPrizeDistributionTable = ({
  dropsRemaining,
  isLoading
}: {
  isLoading: boolean;
  dropsRemaining: ChestChance[];
}) => {
  return isLoading ? (
    <div className='mt-4'>
      <Loading loadingText='Land Chest Distribution Data' />
    </div>
  ) : (
    <div className='table-responsive'>
      <table className='table table-responsive-sm'>
        <thead className='bg-primary'>
          <tr>
            <td>Chest Type</td>
            <td>
              Regular drops{' '}
              <FontAwesomeIcon
                className='ms-2'
                role='button'
                icon={faInfoCircle}
                data-tip='Regular drops consist in guaranteed items: a land plot and a random piece of item (weapon/armour/miscellaneous)'
              />
            </td>
            <td>
              Rare drops{' '}
              <FontAwesomeIcon
                className='ms-2'
                role='button'
                icon={faInfoCircle}
                data-tip='Rare drops contain an extra Alpine Land Plot and/or an extra Legendary Item (weapon/armour/miscellaneous) or a very scarce collectible item'
              />
            </td>
            {/* <td>
              Exceptionally rare drops{' '}
              <FontAwesomeIcon
                className='ms-2'
                role='button'
                icon={faInfoCircle}
                data-tip='The exceptionally rare drops contain an extra Legendary Item (weapon/armour/miscellaneous) or a very scarce collectible item'
              />
            </td> */}
          </tr>
        </thead>
        <tbody>
          {dropsRemaining.map((prizeItem, i) => {
            const total =
              prizeItem.regular + prizeItem.rare + prizeItem.legendary;
            return (
              <tr key={`land-chest-prize-distribution-${i}`}>
                <td className='text-white'>
                  {prizeItem.name}
                  <br />
                  <small className='text-primary'>
                    (
                    {(
                      100 *
                      (1 - total / prizeItem.initialTotal)
                    ).toLocaleString(undefined, toLocaleStringOptions)}
                    % opened)
                  </small>
                </td>
                <td className='text-white'>
                  {prizeItem.regular} (
                  {((prizeItem.regular / total) * 100).toLocaleString(
                    undefined,
                    toLocaleStringOptions
                  )}
                  %)
                  <br />
                  <small className='text-primary'>
                    remaining out of the {prizeItem.initialRegular} supply
                  </small>
                </td>
                <td className='text-white'>
                  {prizeItem.rare} (
                  {(
                    ((prizeItem.rare + prizeItem.legendary) / total) *
                    100
                  ).toLocaleString(undefined, toLocaleStringOptions)}
                  %)
                  <br />
                  <small className='text-primary'>
                    remaining out of the{' '}
                    {prizeItem.initialRare + prizeItem.initialLegendary} supply
                  </small>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ReactTooltip />
    </div>
  );
};
