import React, { useEffect } from 'react';
import BigNumber from 'bignumber.js';
import StakeNftsModalButton from 'components/Modals/StakeNftsModalButton';
import { StakedSoulThumbnail } from 'components/NFT/StakedAssetThumbnail/StakedSoulThumbnail';
import { StakedAssetThumbnail } from 'components/NFT/StakedAssetThumbnail/Thumbnailv2';
import {
  StakeableAssetType,
  NftStakingStakedItemType,
  StakedSoulType
} from 'types/store';
import { formatBigNumberStringMaxPrecision } from 'utils';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';

interface CardContainerProps {
  title: string;
  stakedItems: NftStakingStakedItemType[];
  availableItems: StakeableAssetType[];
  handleStake: (
    nfts: StakeableAssetType[],
    quantities: { [identifier: string]: number }
  ) => Promise<void>;
  handleUnstake: (
    nfts: NftStakingStakedItemType[],
    quantities: { [identifier: string]: number },
    unstakeFee: BigNumber
  ) => void;
}

const NftStaking: React.FC<CardContainerProps> = ({
  title,
  stakedItems,
  availableItems,
  handleStake,
  handleUnstake
}) => {
  const { hasPendingTransactions } = useGetPendingTransactions();

  const [selectedNfts, setSelectedNfts] = React.useState<
    NftStakingStakedItemType[]
  >([]);
  const [selectedNftsQuantities, setSelectedNftsQuantities] = React.useState<{
    [identifier: string]: number;
  }>({});
  const [isAnySelected, setIsAnySelected] = React.useState(false);

  const [selectionUnstakeFee, setSelectionUnstakeFee] = React.useState<
    BigNumber | undefined
  >();

  const isSoulStaking = (stakedItems as StakedSoulType[]).every(
    (item) => 'summoningCounts' in item
  );

  useEffect(() => {
    if (hasPendingTransactions) {
      return;
    }

    setSelectedNfts([]);
    setSelectedNftsQuantities({});
    setIsAnySelected(false);
    setSelectionUnstakeFee(undefined);
  }, [hasPendingTransactions]);

  const handleSelect = (nft: NftStakingStakedItemType) => {
    const selectedNftIndex = selectedNfts.indexOf(nft);
    const co = selectedNfts;
    if (selectedNftIndex < 0) {
      co.push(nft);
      handleUpdateQuantity(nft, 1);
    } else {
      co.splice(selectedNftIndex, 1);
      handleUpdateQuantity(nft, 0);
    }
    setIsAnySelected(co.length > 0);
    setSelectedNfts(co);

    const selectedNftsFee = co.reduce(
      (prev, crt) =>
        prev.plus(
          new BigNumber(crt.amount).multipliedBy(new BigNumber(crt.unstakeFee))
        ),
      new BigNumber(0)
    );

    setSelectionUnstakeFee(selectedNftsFee);
  };

  const handleUpdateQuantity = (nft: NftStakingStakedItemType, qty: number) => {
    console.log('Handleupdatequantity', nft, qty);
    const newSelectedNftsQuantities = { ...selectedNftsQuantities };
    if (
      qty === 0 &&
      newSelectedNftsQuantities[nft.fullIdentifier] !== undefined
    ) {
      // Delete the entry if qty is 0
      delete newSelectedNftsQuantities[nft.fullIdentifier];
    } else {
      // Update the quantity otherwise
      newSelectedNftsQuantities[nft.fullIdentifier] = qty;
    }
    setSelectedNftsQuantities(newSelectedNftsQuantities);

    const selectedNftsFee = Object.keys(newSelectedNftsQuantities)
      .map((v) => {
        return new BigNumber(newSelectedNftsQuantities[v]).multipliedBy(
          new BigNumber(
            stakedItems.find((n) => n.fullIdentifier === v)?.unstakeFee ?? '0'
          )
        );
      })
      .reduce((prev, crt) => prev.plus(crt), new BigNumber(0));
    setSelectionUnstakeFee(selectedNftsFee);

    setIsAnySelected(
      Object.values(newSelectedNftsQuantities).reduce(
        (prev, crt) => prev + crt,
        0
      ) > 0
    );
  };

  return (
    <div className='card'>
      <div className='card-header d-flex justify-content-between align-items-center'>
        <h5 className='mb-0'>{title}</h5>
      </div>
      <div
        className='card-body'
        style={{ maxHeight: '50vh', overflowY: 'auto' }}
      >
        <div className='row row-cols-1 row-cols-md-3 row-cols-lg-6 g-4'>
          {stakedItems.length > 0 &&
            stakedItems.map((nft, i) => {
              return isSoulStaking ? (
                <StakedSoulThumbnail
                  key={`nft-staking-key-${i}`}
                  nft={nft as StakedSoulType}
                  handleSelect={() => handleSelect(nft)}
                  handleUpdateQuantity={(qty) => handleUpdateQuantity(nft, qty)}
                  isSelected={
                    selectedNfts.includes(nft) ||
                    selectedNftsQuantities[nft.fullIdentifier] !== undefined
                  }
                  selectedQuantity={selectedNftsQuantities[nft.fullIdentifier]}
                />
              ) : (
                <StakedAssetThumbnail
                  key={`nft-staking-key-${i}`}
                  token={nft}
                  handleSelect={() => handleSelect(nft)}
                  handleUpdateQuantity={(qty) => handleUpdateQuantity(nft, qty)}
                  isSelected={
                    selectedNfts.includes(nft) ||
                    selectedNftsQuantities[nft.fullIdentifier] !== undefined
                  }
                  selectedQuantity={selectedNftsQuantities[nft.fullIdentifier]}
                />
              );
            })}
          {stakedItems.length === 0 && (
            <div className='w-100 text-center'>
              <span style={{ fontSize: 'large' }}>You have nothing staked</span>
            </div>
          )}
        </div>
      </div>
      <div className='card-footer'>
        {isAnySelected && (
          <>
            <div className='d-flex justify-content-center mb-2'>
              Unstake cost:{' '}
              {formatBigNumberStringMaxPrecision(selectionUnstakeFee ?? '0')}
            </div>
            <div className='d-flex justify-content-center mb-2'>
              <small>
                You will send the fee + 2% in order to cover price swings. The
                contract will keep the fee and return the remainder
              </small>
            </div>
          </>
        )}
        <div className='d-flex justify-content-between'>
          <button
            className='btn btn-primary'
            disabled={stakedItems.length === 0}
            onClick={() =>
              handleUnstake(
                stakedItems,
                (() => {
                  const quantities: { [key: string]: number } = {};
                  for (const nft of stakedItems) {
                    quantities[nft.tokenIdentifier] = parseInt(nft.amount);
                  }
                  return quantities;
                })(),
                (() => {
                  let totalFee = new BigNumber(0);
                  for (const nft of stakedItems) {
                    totalFee = totalFee.plus(
                      new BigNumber(nft.amount).multipliedBy(
                        new BigNumber(nft.unstakeFee)
                      )
                    );
                  }
                  return totalFee;
                })()
              )
            }
          >
            Unstake all
          </button>

          <button
            className='btn btn-primary'
            onClick={() =>
              handleUnstake(
                selectedNfts,
                selectedNftsQuantities,
                selectionUnstakeFee ?? new BigNumber(0)
              )
            }
            disabled={!isAnySelected}
          >
            Unstake selected
          </button>

          <StakeNftsModalButton
            nfts={availableItems}
            handleStakeNfts={handleStake}
          />
        </div>
      </div>
    </div>
  );
};

export default NftStaking;
