import React from 'react';
import { Spinner } from 'react-bootstrap';

export const Loading = ({
  coverFullPage,
  spinnerOnly,
  loadingText
}: {
  coverFullPage?: boolean;
  spinnerOnly?: boolean;
  loadingText?: string;
}) => {
  return (
    <div className='row mb-5'>
      <div className='col-12 d-flex justify-content-center align-items-center w-100 h-100'>
        <div
          className='text-center'
          style={{ marginTop: coverFullPage ? '25%' : '0' }}
        >
          {!spinnerOnly && <h3>Loading {loadingText}</h3>}
          <Spinner animation={'border'} />
        </div>
      </div>
    </div>
  );
};
