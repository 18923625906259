import { KosonStakingPoolType } from 'types/koson-staking-pool';
import { ReactComponent as KossonsSymbolBasileus } from '../assets/images/aoz/Koson_Basileus.svg';
import { ReactComponent as KossonsSymbolComati } from '../assets/images/aoz/Koson_Comati.svg';
import { ReactComponent as KossonsSymbolPileati } from '../assets/images/aoz/Koson_Pileati.svg';
import { ReactComponent as KossonsSymbolPlebea } from '../assets/images/aoz/Koson_Plebea.svg';
import { ReactComponent as KossonsSymbolStrategos } from '../assets/images/aoz/Koson_Strategos.svg';
import { ReactComponent as KossonsSymbolTarabostes } from '../assets/images/aoz/Koson_Tarabostes.svg';

export const KOSON_STAKING_POOLS: KosonStakingPoolType[] = [
  {
    name: 'Plebea',
    poolId: 0,
    poolShareOfDailyEmission: 5 / 70
  },
  {
    name: 'Comati',
    poolId: 1,
    poolShareOfDailyEmission: 6 / 70
  },
  {
    name: 'Pileati',
    poolId: 2,
    poolShareOfDailyEmission: 7 / 70
  },
  {
    name: 'Tararabostes',
    poolId: 3,
    poolShareOfDailyEmission: 8 / 70
  },
  {
    name: 'Strategos',
    poolId: 4,
    poolShareOfDailyEmission: 9 / 70
  },
  {
    name: 'Basileus',
    poolId: 5,
    poolShareOfDailyEmission: 10 / 70
  }
];

export const KOSON_STAKING_POOLS_ICONS = [
  <KossonsSymbolPlebea style={{ width: '26px' }} />,
  <KossonsSymbolComati style={{ width: '26px' }} />,
  <KossonsSymbolPileati style={{ width: '26px' }} />,
  <KossonsSymbolTarabostes style={{ width: '26px' }} />,
  <KossonsSymbolStrategos style={{ width: '26px' }} />,
  <KossonsSymbolBasileus style={{ width: '26px' }} />
];
