import BigNumber from 'bignumber.js';
import {
  KosonTokenIdentifierType,
  StakedKosonTokenIdentifierType
} from 'types';
import { PaymentType } from 'types';
import { EsdtTokenType, TokenInfoMapType } from 'types/MultiversX';
import { getTokenTransfer, parseEsdtTokenPayment } from 'utils';
import { useMvxContractUtils } from '../useMvxContractUtils';
import kosonStakingPoolAbiJson from './koson-staking-pool.abi.json';
import { KosonStakingPoolContextScType } from './types';
import { decodeUnbondingBatchAttributes } from './utils';

export const useKosonStakingPool = () => {
  const { getSmartContract, queryContract, sendTransaction } =
    useMvxContractUtils();

  const getKosonStakingPoolContract = (poolAddress: string) => {
    return getSmartContract(poolAddress, kosonStakingPoolAbiJson);
  };

  const getStakingPoolContext = async (
    poolAddress: string
  ): Promise<KosonStakingPoolContextScType> => {
    const sc = getKosonStakingPoolContract(poolAddress);

    const interaction = sc.methods.getStakingPoolContext();
    const result = await queryContract(interaction);
    const tokenBalancesArray = result.token_balances.map(parseEsdtTokenPayment);

    const tokenBalances: TokenInfoMapType<EsdtTokenType> = {};
    tokenBalancesArray.forEach((tb: EsdtTokenType) => {
      if (!tokenBalances[tb.fullIdentifier]) {
        tokenBalances[tb.fullIdentifier] = [];
      }
      tokenBalances[tb.fullIdentifier].push(tb);
    });

    return {
      maxClaimFee: result.max_claim_fee.toNumber() / 100,
      rewardIndex: new BigNumber(result.reward_index)
        .div(1_000_000_000)
        .toString(10),
      indexTokenIdentifier: result.staked_koson_token_identifier,
      tokenBalances,
      unbondingTokenIdentifier: result.unbonding_koson_token_identifier,
      unbondingEpochs: result.unbonding_epochs.toNumber()
    };
  };

  const getUnbondingFee = async (
    poolAddress: string,
    amount: string,
    mintEpoch: number
  ) => {
    const sc = getKosonStakingPoolContract(poolAddress);

    const interaction = sc.methods.getUnbondingFeeAndResultingAmount([
      amount,
      mintEpoch
    ]);
    const result = await queryContract(interaction);

    return {
      amount: result.field0.toString(10),
      fee: result.field1.toString(10)
    };
  };

  const sendStakeTransaction = async (
    poolAddress: string,
    tokenIdentifier: KosonTokenIdentifierType,
    amount: string
  ) => {
    const sc = getKosonStakingPoolContract(poolAddress);

    const transfer = getTokenTransfer({ tokenIdentifier, amount });

    const interaction = sc.methods
      .stake()
      .withSingleESDTTransfer(transfer)
      .withGasLimit(35_000_000);

    return await sendTransaction(interaction, 'Stake Koson');
  };

  const sendUnstakeTransaction = async (
    poolAddress: string,
    tokenIdentifier: StakedKosonTokenIdentifierType,
    amount: string
  ) => {
    const sc = getKosonStakingPoolContract(poolAddress);

    const transfer = getTokenTransfer({ tokenIdentifier, amount });

    const interaction = sc.methods
      .startUnstake()
      .withSingleESDTTransfer(transfer)
      .withGasLimit(35_000_000);

    return await sendTransaction(interaction, 'Unbond Koson');
  };

  const sendClaimUnstakedTransaction = async (
    poolAddress: string,
    unbondingBatches: PaymentType[]
  ) => {
    const sc = getKosonStakingPoolContract(poolAddress);

    const transfers = unbondingBatches.map(getTokenTransfer);

    const interaction = sc.methods
      .claimUnstaked()
      .withMultiESDTNFTTransfer(transfers)
      .withGasLimit(35_000_000);

    return await sendTransaction(interaction, 'Claim Unbonding Koson');
  };

  return {
    getStakingPoolContext,
    sendStakeTransaction,
    sendUnstakeTransaction,
    sendClaimUnstakedTransaction,
    decodeUnbondingBatchAttributes,
    getUnbondingFee
  };
};
