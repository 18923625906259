import React from 'react';
import Activity from 'components/Activity';
import { Loading } from 'components/Loading';
import { Web3Context } from 'contexts/Web3Context';
import AccountInfo from './AccountInfo';
import AccountStatsCards from './AccountStatsCards';
import { useSelector } from 'react-redux';
import { selectStatus as selectKosonStakingStatus } from 'store/slices/kosonv2/koson-staking-pools';
import { selectStatus as selectLandPlotStakingStatus } from 'store/slices/kosonv2/land-plot-staking-pool';
import { selectStatus as selectSoulNftStakingStatus } from 'store/slices/kosonv2/soul-staking-pool';

const Overview = () => {
  const kosonStakingStatus = useSelector(selectKosonStakingStatus);
  const landPlotStakingStatus = useSelector(selectLandPlotStakingStatus);
  const soulNftStakingStatus = useSelector(selectSoulNftStakingStatus);

  return (
    <>
      {
        // accountState?.isLoading ||
        // nftStakingState?.isLoading ||
        // nftState?.isLoading ||
        // stakedLandChestsState?.isLoading ||
        // vestingState?.isLoading ||
        // kosonStakingState?.isLoading
        [kosonStakingStatus, landPlotStakingStatus, soulNftStakingStatus].some(
          (status) => status === 'loading'
        ) ? (
          <Loading coverFullPage={true} />
        ) : (
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <AccountInfo />
            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <AccountStatsCards />
            </div>
          </div>
        )
      }
      <div className='row mt-2'>
        <div className='col-xl-12 col-lg-12 col-sm-12'>
          <Activity />
        </div>
      </div>
    </>
  );
};

export default Overview;
