import {
  AbiRegistry,
  Address,
  Interaction,
  ResultsParser,
  SmartContract
} from '@multiversx/sdk-core/out';
import {
  useGetAccountInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { gatewayAddress } from 'config/dapp-config';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';

export const useMvxContractUtils = () => {
  const proxy = new ProxyNetworkProvider(gatewayAddress);

  const { address } = useGetAccountInfoWrapper();
  const { chainID } = useGetNetworkConfig();

  const getAbiRegistry = (abiJson: any) => AbiRegistry.create(abiJson);

  const getSmartContract = (poolAddress: string, abiJson: any) => {
    const abi = getAbiRegistry(abiJson);
    return new SmartContract({
      address: new Address(poolAddress),
      abi
    });
  };

  const queryContract = async (interaction: Interaction) => {
    const queryResponse = await proxy.queryContract(
      interaction.check().buildQuery()
    );

    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } =
      new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      throw Error(returnMessage);
    }

    return firstValue.valueOf();
  };

  const sendTransaction = async (interaction: Interaction, txName?: string) => {
    const tx = interaction
      .withSender(new Address(address))
      .withChainID(chainID)
      .check()
      .buildTransaction();

    return await sendTransactions({
      transactions: [tx],
      transactionsDisplayInfo: {
        processingMessage: `Processing ${txName} Request`,
        errorMessage: `Error occured during ${txName} Request`,
        successMessage: `${txName} Request Successful`
      },
      redirectAfterSign: false
    });
  };

  return {
    getSmartContract,
    getAbiRegistry,
    queryContract,
    sendTransaction
  };
};
