import React from 'react';
import { AddressValue, Address } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { getTotalStakedSoulNfts } from 'api/apiRequests';
import { SOUL_NFT_STAKING_SC_ADDRESS } from 'config/dapp-config';
import {
  getSmartContract,
  Parser,
  Provider
} from 'contexts/Web3Context/helpers/getScObj';
import {
  fillSummoningInfo,
  transformStakedNft
} from 'contexts/Web3Context/helpers/nftUtils';
import { AccountNft } from 'types';
import { NFT_UNBONDING_TIME_PENALTY } from 'config/soul-staking-config';

const useNftStakingInfo = ({ address }: { address: string }) => {
  const DAILY_ISSUANCE = 18442.6229508;

  const [stakedNfts, setStakedNfts] = React.useState<AccountNft[]>([]);
  const [pendingRewards, setPendingRewards] = React.useState(0);
  const [totalStakedNfts, setTotalStakedNfts] = React.useState(0);
  const [totalDailyReward, setTotalDailyReward] = React.useState(0);
  const [singleNftDailyReward, setSingleNftDailyReward] = React.useState(0);
  const [unbondingNfts, setUnbondingNfts] = React.useState<AccountNft[]>([]);
  const [hasClaimableUnbondingNfts, setHasClaimableUnbondingNfts] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    refreshState().then(() => {
      setIsLoading(false);
    });
  }, [address]);

  const refreshState = async () => {
    const userStakedNfts = await getUserStakedNfts();
    setStakedNfts(userStakedNfts);
    const userUnbondingNfts = await getUserUnbondingNfts();
    setUnbondingNfts(userUnbondingNfts);
    setHasClaimableUnbondingNfts(
      userUnbondingNfts.filter((nft) => nft.canBeClaimed).length > 0
    );
    // setPendingRewards(await getUserPendingRewards());
    const stakedNftsCount = await getTotalStakedSoulNfts();
    if (stakedNftsCount.success) {
      setTotalStakedNfts(stakedNftsCount.data);
      const dailyRewardPerNft = DAILY_ISSUANCE / stakedNftsCount.data;
      setTotalDailyReward(dailyRewardPerNft * userStakedNfts.length);
      setSingleNftDailyReward(dailyRewardPerNft);
    }
  };

  const getUserStakedNfts = async () => {
    const addressArg = new AddressValue(new Address(address));

    const contract = await getSmartContract(SOUL_NFT_STAKING_SC_ADDRESS);
    const interaction = contract.methodsExplicit.getUserStakedNfts([
      addressArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const items = [];
      const parsedStakedNfts = parsedResponse.firstValue?.valueOf();
      for (let i = 0; i < parsedStakedNfts.length; i++) {
        const parsedNft = transformStakedNft(
          parsedStakedNfts[i].token_id,
          parsedStakedNfts[i].nonce.toNumber(),
          parsedStakedNfts[i].amount.toNumber()
        );
        items.push(parsedNft);
      }
      return fillSummoningInfo(items);
    }
    return [];
  };

  const getUserUnbondingNfts = async () => {
    const addressArg = new AddressValue(new Address(address));

    const contract = await getSmartContract(SOUL_NFT_STAKING_SC_ADDRESS);
    const interaction = contract.methodsExplicit.getUserPendingUnstake([
      addressArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const items = [];
      const unstakingNftsBatches = parsedResponse.firstValue?.valueOf();
      for (let i = 0; i < unstakingNftsBatches.length; i++) {
        const batch = unstakingNftsBatches[i];
        const unstakeTriggerTimestamp = batch.field0.toNumber();
        const expectedClaimTime =
          unstakeTriggerTimestamp + NFT_UNBONDING_TIME_PENALTY;
        for (let j = 0; j < batch.field1.length; j++) {
          const batchItem = batch.field1[j];
          const parsedNft = transformStakedNft(
            batchItem.token_id,
            batchItem.nonce.toNumber(),
            batchItem.amount.toNumber(),
            expectedClaimTime
          );
          items.push(parsedNft);
        }
      }
      return fillSummoningInfo(items);
    }
    return [];
  };

  const getUserPendingRewards = async () => {
    const addressArg = new AddressValue(new Address(address));

    const contract = await getSmartContract(SOUL_NFT_STAKING_SC_ADDRESS);
    const interaction = contract.methodsExplicit.getRewards([addressArg]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const value = parsedResponse.firstValue?.valueOf();
      return value.dividedBy(new BigNumber(10).pow(18)).toNumber();
    }
    return 0;
  };

  return {
    stakedNfts,
    unbondingNfts,
    hasClaimableUnbondingNfts,
    singleNftDailyReward,
    totalDailyReward,
    pendingRewards,
    totalStakedNfts,
    refreshState,
    isLoading
  };
};

export default useNftStakingInfo;

export interface INFTStakingType {
  stakedNfts: AccountNft[];
  unbondingNfts: AccountNft[];
  hasClaimableUnbondingNfts: boolean;
  singleNftDailyReward: number;
  totalDailyReward: number;
  pendingRewards: number;
  totalStakedNfts: number;
  refreshState: () => Promise<any>;
  isLoading: boolean;
}
