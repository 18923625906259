import React from 'react';
import { getValidAccountNfts } from 'api/apiRequests';
import {
  LAND_CHEST_TOKEN_IDENTIFIER,
  OLD_LAND_PLOT_TOKEN_IDENTIFIER,
  ORIGIN_SOULS_TOKEN_IDENTIFIERS,
  ALL_SOUL_NFT_TOKEN_IDENTIFIERS,
  STAKED_KOSON_TOKEN_IDENTIFIERS,
  SUMMONED_SOULS_TOKEN_IDENTIFIERS
} from 'config/dapp-config';
import {
  fillPendingRewards,
  fillSummoningInfo,
  transformApiNft
} from 'contexts/Web3Context/helpers/nftUtils';
import { AccountNft } from 'types';

const useAccountNftInfo = ({ address }: { address: string }) => {
  const isLoggedIn = Boolean(address);
  const [isLoading, setIsLoading] = React.useState(true);
  const [accountNfts, setAccountNfts] = React.useState<any[]>([]);
  const [parsedAccountNfts, setParsedAccountNfts] = React.useState<
    AccountNft[]
  >([]);
  const [accountSoulNfts, setAccountSoulNfts] = React.useState<AccountNft[]>(
    []
  );
  const [accountLandChests, setAccountLandChests] = React.useState<
    AccountNft[]
  >([]);
  const [stakedKosonBatches, setStakedKosonBatches] = React.useState<
    AccountNft[]
  >([]);
  const [tokenNfts, setTokenNfts] = React.useState<AccountNft[]>([]);
  const [hasUnmigratedOriginSouls, setHasUnmigratedOriginSouls] =
    React.useState(false);
  const [landPlotNfts, setLandPlotNfts] = React.useState<AccountNft[]>([]);
  const [hasOriginSouls, setHasOriginSouls] = React.useState(false);
  const [hasSummonedSouls, setHasSummonedSouls] = React.useState(false);
  const [hasLandChests, setHasLandChests] = React.useState(false);
  const [hasLandPlots, setHasLandPlots] = React.useState(false);
  const [hasStakedKoson, setHasStakedKoson] = React.useState(false);
  const [canSummonRegularSummons, setCanSummonRegularSummons] =
    React.useState(false);
  const [canSummonDeathSoul, setCanSummonDeathSoul] = React.useState(false);

  React.useEffect(() => {
    if (accountNfts.length > 0) {
      return;
    }
    refreshState().then(() => {
      setIsLoading(false);
    });
  }, []);

  React.useEffect(() => {
    refreshLandChestsState();
  }, [accountNfts, parsedAccountNfts]);
  React.useEffect(() => {
    refreshSoulState();
  }, [accountNfts, parsedAccountNfts]);
  React.useEffect(() => {
    refreshKosonStakingState();
  }, [accountNfts, parsedAccountNfts]);
  React.useEffect(() => {
    refreshTokensState();
  }, [accountLandChests, parsedAccountNfts]);

  React.useEffect(() => {
    const originNfts = accountNfts.filter(
      (nft) =>
        nft.collection !== ORIGIN_SOULS_TOKEN_IDENTIFIERS[0] &&
        ORIGIN_SOULS_TOKEN_IDENTIFIERS.includes(nft.collection)
    );

    const originUnmigratedNfts = accountNfts.filter(
      (nft) => nft.collection === ORIGIN_SOULS_TOKEN_IDENTIFIERS[0]
    );

    const summonedNfts = accountNfts.filter((nft) =>
      SUMMONED_SOULS_TOKEN_IDENTIFIERS.includes(nft.collection)
    );
    const landChests = accountNfts.filter(
      (nft) => LAND_CHEST_TOKEN_IDENTIFIER === nft.collection
    );
    const stakedKoson = accountNfts.filter((nft) =>
      STAKED_KOSON_TOKEN_IDENTIFIERS.includes(nft.collection)
    );
    setHasUnmigratedOriginSouls(originUnmigratedNfts.length > 0);
    setHasOriginSouls(originNfts.length > 0);
    setHasSummonedSouls(summonedNfts.length > 0);
    setHasLandChests(landChests.length > 0);
    setHasStakedKoson(stakedKoson.length > 0);
    landChests;
    stakedKoson;
  }, [accountNfts, parsedAccountNfts]);

  const refreshState = async () => {
    if (!isLoggedIn) {
      return;
    }
    const validNfts = await getValidAccountNfts(address);
    if (validNfts.success) {
      setAccountNfts(validNfts.data);
      let parsed = validNfts.data.map((nft: any) => transformApiNft(nft));
      parsed = await fillSummoningInfo(parsed);
      parsed = await fillPendingRewards(parsed);
      setParsedAccountNfts(parsed);
    }
  };

  const refreshSoulState = async () => {
    const soulNfts = parsedAccountNfts.filter((nft) =>
      ALL_SOUL_NFT_TOKEN_IDENTIFIERS.includes(nft.collection)
    );
    const identifiers = soulNfts
      .filter((nft) => {
        const index = ALL_SOUL_NFT_TOKEN_IDENTIFIERS.indexOf(nft.collection);
        return index > 0 && nft.specialSummoningCount === 0;
      })
      .map((nft) => nft.collection);
    let hasFullSet = true;
    for (
      let i = 1;
      i < ORIGIN_SOULS_TOKEN_IDENTIFIERS.length && hasFullSet;
      i++
    ) {
      hasFullSet =
        hasFullSet && identifiers.includes(ORIGIN_SOULS_TOKEN_IDENTIFIERS[i]);
    }
    setCanSummonRegularSummons(
      soulNfts.filter((nft) => (nft.regularSummoningCount ?? 6) < 6).length >= 2
    );
    setCanSummonDeathSoul(hasFullSet);
    setAccountSoulNfts(soulNfts);
  };

  const refreshLandChestsState = async () => {
    const chests = parsedAccountNfts.filter((nft) => nft.isLandChest);
    setAccountLandChests(chests);
  };

  const refreshKosonStakingState = async () => {
    const stakingBatches = parsedAccountNfts.filter(
      (nft) => nft.isStakedKosonBatch
    );
    setStakedKosonBatches(stakingBatches);
  };

  const refreshTokensState = async () => {
    const tokens = parsedAccountNfts.filter((token) => token.isTokenNft);
    const landPlotTokens = tokens.filter(
      (tkn) => tkn.collection == OLD_LAND_PLOT_TOKEN_IDENTIFIER
    );
    setLandPlotNfts(landPlotTokens);
    setHasLandPlots(landPlotTokens.length > 0);
    setTokenNfts(tokens);
  };

  return {
    accountNfts: accountNfts,
    parsedAccountNfts,
    accountSoulNfts: accountSoulNfts,
    accountLandChests: accountLandChests,
    stakedKosonBatches: stakedKosonBatches,
    tokenNfts: tokenNfts,
    landPlotNfts: landPlotNfts,
    hasUnmigratedOriginSouls,
    hasOriginSouls,
    hasSummonedSouls,
    hasLandChests,
    hasLandPlots,
    hasStakedKoson,
    canSummonRegularSummons,
    canSummonDeathSoul,
    refreshEntireState: refreshState,
    isLoading
  };
};

export default useAccountNftInfo;

export interface IAccountNftInfoType {
  accountNfts: any[];
  parsedAccountNfts: AccountNft[];
  accountSoulNfts: AccountNft[];
  accountLandChests: AccountNft[];
  stakedKosonBatches: AccountNft[];
  tokenNfts: AccountNft[];
  landPlotNfts: AccountNft[];
  hasUnmigratedOriginSouls: boolean;
  hasOriginSouls: boolean;
  hasSummonedSouls: boolean;
  hasLandChests: boolean;
  hasLandPlots: boolean;
  hasStakedKoson: boolean;
  canSummonRegularSummons: boolean;
  canSummonDeathSoul: boolean;
  refreshEntireState: () => Promise<void>;
  isLoading: boolean;
}
