import { ALL_KOSON_TOKEN_IDENTIFIERS } from 'config/dapp-config';
import { KOSON_TOKEN_NAMES } from 'config/mapping-config';

export const KosonTokenSelector = ({
  selectedIndex,
  onSelect
}: {
  selectedIndex: number;
  onSelect: (index: number) => void;
}) => (
  <div className='basic-dropdown'>
    <div className='dropdown'>
      <button
        type='button'
        className='btn btn-outline-primary dropdown-toggle w-100'
        data-bs-toggle='dropdown'
      >
        {KOSON_TOKEN_NAMES[selectedIndex]}
      </button>
      <div className='dropdown-menu'>
        {ALL_KOSON_TOKEN_IDENTIFIERS.map((_, index) => (
          <div
            className='dropdown-item'
            role='button'
            key={`koson-token-dropdown-item-${index}`}
            onClick={() => onSelect(index)}
          >
            {KOSON_TOKEN_NAMES[index]}
          </div>
        ))}
      </div>
    </div>
  </div>
);
