import { ASSET_REMAPPER_SC_ADDRESS } from 'config/dapp-config';
import { PaymentType } from 'types';
import { getTokenTransfer } from 'utils';
import { useMvxContractUtils } from '../useMvxContractUtils';
import assetRemapperAbiJson from './assets-remapper.abi.json';

export const useAssetRemapper = () => {
  const { getSmartContract, sendTransaction } = useMvxContractUtils();

  const getAssetRemapperContract = () => {
    return getSmartContract(ASSET_REMAPPER_SC_ADDRESS, assetRemapperAbiJson);
  };

  const sendMigrateTransaction = async (payments: PaymentType[]) => {
    const sc = getAssetRemapperContract();

    const transfers = payments.map(getTokenTransfer);

    const interaction = sc.methods
      .migrate()
      .withMultiESDTNFTTransfer(transfers)
      .withGasLimit(65_000_000);

    await sendTransaction(interaction, 'Migrate Assets');
  };

  return {
    sendMigrateTransaction
  };
};
