import React from 'react';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import { DisplayAllMigrationModals } from 'components/Modals/MigrateModals';
import { environment, walletConnectV2ProjectId } from 'config/dapp-config';
import { Web3Provider } from 'contexts/Web3Context';
import PageNotFound from 'pages/PageNotFound';
import { routeNames } from 'routes';
import routes from 'routes';
import store from 'store';
import { KosonV2Loader } from 'store/KosonV2Loader';
import UnlockPage from './pages/UnlockPage';
import { SiteUnderConstruction } from 'pages/SiteUnderConstruction';

const App = () => {
  return (
    <Router>
      <DappProvider
        environment={environment}
        customNetworkConfig={{
          name: 'customConfig',
          apiTimeout: 6000,
          walletConnectV2ProjectId
        }}
      >
        <Provider store={store}>
          <Web3Provider>
            <KosonV2Loader />
            <Layout>
              <DisplayAllMigrationModals />
              <TransactionsToastList />
              <NotificationModal />
              <SignTransactionsModals className='custom-class-for-modals' />
              <Routes>
                <Route path={routeNames.unlock} element={<UnlockPage />} />
                {routes.map((route: any, index: number) => (
                  <Route
                    path={route.path}
                    key={'route-key-' + index}
                    element={<route.component />}
                  />
                ))}
                <Route path='*' element={<PageNotFound />} />
              </Routes>
              {/* <SiteUnderConstruction /> */}
            </Layout>
          </Web3Provider>
        </Provider>
      </DappProvider>
    </Router>
  );
};

export default App;
