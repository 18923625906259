import React from 'react';
import { AvailableAssetThumbnail } from 'components/NFT/StakedAssetThumbnail/AvailableAssetThumbnail';
import { AvailableSoulThumbnail } from 'components/NFT/StakedAssetThumbnail/AvailableSoulThumbnail';
import { StakeableAssetType, StakedSoulType } from 'types/store';

const StakeNftsModalButton = ({
  nfts,
  handleStakeNfts,
  buttonComponent,
  action,
  disableStakeAll
}: {
  nfts: StakeableAssetType[];
  handleStakeNfts: (
    nfts: StakeableAssetType[],
    quantities: { [identifier: string]: number }
  ) => Promise<void>;
  buttonComponent?: React.ReactNode;
  action?: 'Stake' | 'Select';
  disableStakeAll?: boolean;
}) => {
  const [selectedNfts, setSelectedNfts] = React.useState<StakeableAssetType[]>(
    []
  );
  const [isAnySelected, setIsAnySelected] = React.useState(false);
  const [selectedNftsQuantities, setSelectedNftsQuantities] = React.useState<{
    [identifier: string]: number;
  }>({});

  const isSoulStaking = (nfts as StakedSoulType[]).every(
    (item) => 'summoningCounts' in item
  );

  const handleSelect = (nft: StakeableAssetType) => {
    const selectedNftIndex = selectedNfts.indexOf(nft);
    const co = [...selectedNfts];
    if (selectedNftIndex < 0) {
      co.push(nft);
    } else {
      co.splice(selectedNftIndex, 1);
    }
    setIsAnySelected(co.length > 0);
    setSelectedNfts(co);
  };

  const handleStakeAll = async () => {
    const quantities: { [identifier: string]: number } = {};
    for (let i = 0; i < nfts.length; i++) {
      quantities[nfts[i].tokenIdentifier] = parseInt(nfts[i].amount);
    }
    await handleStakeNfts(nfts, quantities);
    handleCloseModal();
  };

  const handleStakeSelected = async () => {
    await handleStakeNfts(selectedNfts, selectedNftsQuantities);
    handleCloseModal();
  };

  const handleUpdateQuantity = (nft: StakeableAssetType, qty: number) => {
    const newSelectedNftsQuantities = { ...selectedNftsQuantities };
    if (
      qty === 0 &&
      newSelectedNftsQuantities[nft.fullIdentifier] !== undefined
    ) {
      // Delete the entry if qty is 0
      delete newSelectedNftsQuantities[nft.fullIdentifier];
    } else {
      // Update the quantity otherwise
      newSelectedNftsQuantities[nft.fullIdentifier] = qty;
    }
    setSelectedNftsQuantities(newSelectedNftsQuantities);

    setIsAnySelected(
      Object.values(newSelectedNftsQuantities).reduce(
        (prev, crt) => prev + crt,
        0
      ) > 0
    );
  };

  const handleCloseModal = () => {
    setSelectedNfts([]);
    setSelectedNftsQuantities({});
    setIsAnySelected(false);
  };

  return (
    <>
      {!buttonComponent && (
        <button
          className='btn btn-primary'
          data-bs-toggle='modal'
          data-bs-target='#stakeNftsModal2'
          disabled={nfts.length === 0}
        >
          {action || 'Stake'} more
        </button>
      )}
      {buttonComponent && (
        <div data-bs-toggle='modal' data-bs-target='#stakeNftsModal2'>
          {buttonComponent}
        </div>
      )}

      <div
        className='modal fade'
        id='stakeNftsModal2'
        tabIndex={-1}
        role='dialog'
        aria-hidden='true'
      >
        <div
          className='modal-dialog modal-lg'
          style={{ zIndex: '2050!important' }}
        >
          <div className='modal-content'>
            <div className='modal-header border-0'>
              <h5 className='modal-title'>Add to {action || 'Stake'}</h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div
              className='modal-body with-scroll container-fluid'
              style={{ height: '50vh', width: '100%' }}
            >
              <div className='row'>
                {nfts.map((nft, i) => {
                  return isSoulStaking ? (
                    <AvailableSoulThumbnail
                      key={`nft-staking-add-to-stake-key-${i}`}
                      nft={nft as StakedSoulType}
                      handleSelect={() => handleSelect(nft)}
                      handleUpdateQuantity={(qty) =>
                        handleUpdateQuantity(nft, qty)
                      }
                      customColSize='col-lg-4 col-sm-6 col-md-6'
                      isSelected={
                        selectedNfts.includes(nft) ||
                        selectedNftsQuantities[nft.fullIdentifier] !== undefined
                      }
                      selectedQuantity={
                        selectedNftsQuantities[nft.fullIdentifier]
                      }
                    />
                  ) : (
                    <AvailableAssetThumbnail
                      key={`nft-staking-add-to-stake-key-${i}`}
                      token={nft}
                      handleSelect={() => handleSelect(nft)}
                      handleUpdateQuantity={(qty) =>
                        handleUpdateQuantity(nft, qty)
                      }
                      customColSize='col-lg-4 col-sm-6 col-md-6'
                      isSelected={
                        selectedNfts.includes(nft) ||
                        selectedNftsQuantities[nft.fullIdentifier] !== undefined
                      }
                      selectedQuantity={
                        selectedNftsQuantities[nft.fullIdentifier]
                      }
                    />
                  );
                })}
              </div>
            </div>
            <div className='modal-footer border-0'>
              <button
                type='button'
                className='btn btn-danger light'
                data-bs-dismiss='modal'
                onClick={handleCloseModal}
              >
                Close
              </button>
              {nfts.length === 0 && (
                <button type='button' className='btn btn-primary disabled'>
                  {action || 'Stake'} All
                </button>
              )}
              {!disableStakeAll && nfts.length > 0 && (
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  onClick={handleStakeAll}
                >
                  {action || 'Stake'} All
                </button>
              )}
              {!isAnySelected && (
                <button type='button' className='btn btn-primary disabled'>
                  {action || 'Stake'}
                </button>
              )}
              {isAnySelected && (
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={handleStakeSelected}
                  data-bs-dismiss='modal'
                >
                  {action || 'Stake'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StakeNftsModalButton;
