import React, { useState } from 'react';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OLD_KOSON_TICKER } from 'config/dapp-config';
import { routeNames } from 'routes';
import {
  selectEsdtBalance,
  selectUnmigratedAssets,
  selectUnmigratedStakedKosonBatches
} from 'store/slices/kosonv2/account';

export const MigrateKosonTokenModal = () => {
  const [selectedPage, setSelectedPage] = useState(1);
  const kosonV1Balance = useSelector(selectEsdtBalance)(OLD_KOSON_TICKER);
  const unmigratedAssets = useSelector(selectUnmigratedAssets);
  const unmigratedStakedKosonBatches = useSelector(
    selectUnmigratedStakedKosonBatches
  );

  const mustMigrateKosonV1 = kosonV1Balance.gt(0);
  const mustMigrateLandChestAssets = unmigratedAssets.length > 0;
  const mustMigrateStakedKoson = unmigratedStakedKosonBatches.length > 0;

  const mustMigrateAny =
    mustMigrateKosonV1 || mustMigrateLandChestAssets || mustMigrateStakedKoson;
  const navigate = useNavigate();

  const handleAgreement = () => {
    navigate(routeNames.migrate);
  };

  const toggleChest = (by: number) => {
    const slidesCount = 3;
    let newNumber = selectedPage + by;
    if (newNumber > slidesCount) {
      newNumber = 1;
    }
    if (newNumber < 1) {
      newNumber = slidesCount;
    }
    setSelectedPage(newNumber);
  };

  return (
    <Modal
      show={
        mustMigrateAny && !window.location.pathname.includes(routeNames.migrate)
      }
      size='lg'
      centered
    >
      <Modal.Body className='p-5 container-fluid'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h1>
              <strong>A new era for Age of Zalmoxis! 🔥</strong>
            </h1>
            <h6>$KOSON has suffered some extensive changes.</h6>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-12 text-center'>
            <h6>
              <strong>Migration details:</strong>
            </h6>
          </div>
        </div>
        <div className='row mt-4'>
          {' '}
          <div className='d-flex justify-content-between align-items-center'>
            <button className='btn me-2' onClick={() => toggleChest(-1)}>
              <FontAwesomeIcon
                icon={faChevronLeft as any}
                className='btn-carousel'
                role='button'
              />
            </button>
            <div>
              <img
                src={`/images/aoz/koson-v2-migration/${selectedPage}.png`}
                style={{
                  width: 'auto',
                  maxWidth: '100%'
                }}
              />
            </div>
            <button className='btn ms-2' onClick={() => toggleChest(1)}>
              <FontAwesomeIcon
                icon={faChevronRight as any}
                className='btn-carousel'
                role='button'
              />
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        className='d-flex justify-content-center p-4'
        style={{ border: 'none' }}
      >
        <div className='d-flex justify-content-center flex-column align-items-center'>
          <span className='mb-2' style={{ fontSize: 'larger' }}>
            Looks complicated? The migration assistant is here to help!
          </span>
          <button className='btn btn-primary' onClick={handleAgreement}>
            Take me there
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
