import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { explorerAddress } from 'config/dapp-config';
import { routeNames } from 'routes';
import { MarketplaceListingV2 } from 'types';
import './style.css';
import { ListingAttributes } from './ListingAttributes';
import { ListingDetails } from './ListingDetails';

const MarketplaceListingPage = ({
  listing
}: {
  listing: MarketplaceListingV2;
}) => {
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(routeNames.marketplace);
  };

  return (
    <div className='marketplace-listing-page'>
      <div className='container-fluid w-100 p-0 m-0 mb-5'>
        <div className='row p-1'>
          <div className='col-3 col-lg-1'>
            <button
              className='btn btn-primary me-3 ms-2'
              onClick={navigateBack}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </div>
          <div className='col-12 col-md-10 listing-asset-name mt-2'>
            <h1 className='d-flex align-items-center'>{listing.name}</h1>
            <a
              href={`${explorerAddress}/nfts/${listing.identifier}`}
              target='_blank'
              rel='noreferrer'
            >
              <small>{listing.identifier}</small>
            </a>
          </div>
        </div>
      </div>
      <div className='listing-details'>
        <ListingDetails listing={listing} onNavigateBack={navigateBack} />
        <div className='d-flex justify-content-start asset-wrapper'>
          <img src={listing.fullResourceUrl} className='img-responsive' />
          <ListingAttributes listing={listing} />
        </div>
      </div>
    </div>
  );
};

export default MarketplaceListingPage;
