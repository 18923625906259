import React from 'react';
import BigNumber from 'bignumber.js';
import { EVENT_SETTINGS, toLocaleStringOptions } from 'config/dapp-config';
import { ReactComponent as KosonLogo } from './../../../assets/images/aoz/koson-symbol.svg';
import { ReactComponent as UtrustLogo } from './../../../assets/images/aoz/utrust-logo-large.svg';
import { LandChest } from './../LandChestData';

const CheckoutSelectPaymentProvider = ({
  cartItems,
  totalCostUsd,
  totalCostKoson,
  onProceed,
  onNavigatePrevious
}: {
  cartItems: {
    chest: LandChest;
    quantity: number;
  }[];
  totalCostUsd: number;
  totalCostKoson: number;
  onProceed: (step1: PAYMENT_PROVIDERS, step2: PAYMENT_PROVIDERS) => void;
  onNavigatePrevious: () => void;
}) => {
  const [paymentProviderStep1, setPaymentProviderStep1] =
    React.useState<PAYMENT_PROVIDERS>();
  const [paymentProviderStep2, setPaymentProviderStep2] =
    React.useState<PAYMENT_PROVIDERS>();

  const [isSelectPaymentProviderStep2, setIsSelectPaymentProviderStep2] =
    React.useState(false);

  const getStep1PaymentProviders = () => {
    const s1ppCopy = [];
    s1ppCopy.push({
      provider: PAYMENT_PROVIDERS.Utrust,
      name: 'Utrust - pay with crypto'
    });
    if (EVENT_SETTINGS.onlyKosonChestPurchase) {
      s1ppCopy.push({
        provider: PAYMENT_PROVIDERS.KOSON,
        name: 'KOSON - limited time event'
      });
    }
    return s1ppCopy;
  };

  const [PAYMENT_PROVIDERS_STEP1] = React.useState<PaymentProviderInfoType[]>(
    getStep1PaymentProviders()
  );
  const [PAYMENT_PROVIDERS_STEP2] = React.useState<PaymentProviderInfoType[]>([
    { provider: PAYMENT_PROVIDERS.KOSON, name: '$KOSON' }
  ]);

  const handleProceed = () => {
    if (isSelectPaymentProviderStep2) {
      if (
        paymentProviderStep1 === undefined ||
        paymentProviderStep2 === undefined
      ) {
        window.alert('Something went wrong, try again');
        return;
      }
      onProceed(paymentProviderStep1, paymentProviderStep2);
    } else {
      setIsSelectPaymentProviderStep2(true);
    }
  };

  const selectPaymentProviderComponent = (
    paymentProviders: PaymentProviderInfoType[]
  ) => {
    return (
      <div className='col-12'>
        {paymentProviders.map((pp, i) => (
          <div
            key={`payment_providers-step-${
              isSelectPaymentProviderStep2 ? '2' : '1'
            }-option-${i}`}
          >
            {i > 0 && <br />}
            <input
              type='radio'
              className='form-check-input ms-2 mt-2'
              id={`payment-provider-step-${
                isSelectPaymentProviderStep2 ? '2' : '1'
              }-option-${i}`}
              name={`payment-provider-step-${
                isSelectPaymentProviderStep2 ? '2' : '1'
              }-radio`}
              onClick={() =>
                isSelectPaymentProviderStep2
                  ? setPaymentProviderStep2(pp.provider)
                  : setPaymentProviderStep1(pp.provider)
              }
            />
            <label className='form-check-label mt-1'>
              {pp.provider !== PAYMENT_PROVIDERS.Utrust && pp.name}
            </label>
            {pp.provider === PAYMENT_PROVIDERS.Utrust && (
              <UtrustLogo
                className='mt-1'
                style={{
                  width: 'auto',
                  maxWidth: '120px'
                }}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='card' style={{ height: '100%' }}>
      <div className='card-header'>
        <h4 className='card-title'>Your order</h4>
      </div>
      <div className='card-body'>
        <div className='basic-form container'>
          <div className='row'>
            <div className='col-12'>
              <h4>You&apos;re about to purchase:</h4>
            </div>
            <div className='col-12'>
              <ul>
                {cartItems.map((item, idx) => (
                  <li key={`checkout-payment-provider-step-item-${idx}`}>
                    • {item.quantity} x {item.chest.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className='col-12'>
              <h4 className='mt-4'>For a grand total of:</h4>
            </div>
            <div className='col-12'>
              <h5>
                <strong>
                  {totalCostUsd.toLocaleString(
                    undefined,
                    toLocaleStringOptions
                  )}
                  $
                </strong>{' '}
                and{' '}
                <strong>
                  {new BigNumber(totalCostKoson)
                    .div(new BigNumber(10).pow(18))
                    .toNumber()
                    .toLocaleString(undefined, toLocaleStringOptions)}
                </strong>
                <KosonLogo width={'16px'} className='mb-1 ms-1' />
              </h5>
            </div>
            <div className='col-12'>
              <h4 className='mt-4'>
                Choose your step {isSelectPaymentProviderStep2 ? '2' : '1'}{' '}
                payment provider:
              </h4>
              <small>
                This applies for the payment of{' '}
                {(isSelectPaymentProviderStep2
                  ? new BigNumber(totalCostKoson)
                      .div(new BigNumber(10).pow(18))
                      .toNumber()
                  : totalCostUsd
                ).toLocaleString(undefined, toLocaleStringOptions)}
                {isSelectPaymentProviderStep2 ? (
                  <KosonLogo width={'16px'} className='mb-1 ms-1' />
                ) : (
                  '$'
                )}
              </small>
            </div>
            {selectPaymentProviderComponent(
              isSelectPaymentProviderStep2
                ? PAYMENT_PROVIDERS_STEP2
                : PAYMENT_PROVIDERS_STEP1
            )}
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between align-items-center'>
        <button
          className='btn btn-outline-primary'
          onClick={onNavigatePrevious}
        >
          Back to cart
        </button>
        {!isSelectPaymentProviderStep2 && paymentProviderStep1 !== undefined && (
          <button className='btn btn-outline-primary' onClick={handleProceed}>
            Proceed
          </button>
        )}
        {!isSelectPaymentProviderStep2 &&
          paymentProviderStep1 === undefined && (
            <button className='btn btn-outline-primary disabled'>
              Proceed
            </button>
          )}

        {isSelectPaymentProviderStep2 && paymentProviderStep2 !== undefined && (
          <button className='btn btn-outline-primary' onClick={handleProceed}>
            Proceed
          </button>
        )}
        {isSelectPaymentProviderStep2 && paymentProviderStep2 === undefined && (
          <button className='btn btn-outline-primary disabled'>Proceed</button>
        )}
      </div>
    </div>
  );
};

export default CheckoutSelectPaymentProvider;

export enum PAYMENT_PROVIDERS {
  NONE = 0,
  Utrust = 1,
  CreditCard = 2,
  KOSON = 3
}

export interface PaymentProviderInfoType {
  provider: PAYMENT_PROVIDERS;
  name: string;
  isDisabled?: boolean;
  svgComponent?: JSX.Element;
}
