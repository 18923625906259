import { faWind } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';
import { ACTIVITY_INTERACTION_TYPES } from 'types';
import ActivityInfo from './ActivityInfo';
import './style.css';

const Activity = ({
  interaction,
  customHeader
}: {
  interaction?: ACTIVITY_INTERACTION_TYPES;
  customHeader?: string;
}) => {
  // const { accountState } = React.useContext(Web3Context);
  // return accountState?.isActivityLoading ? (
  //   <Loading />
  // ) : (
  //   <div className='card'>
  //     <div className='card-header border-0 pb-0'>
  //       <h4 className='card-title'>
  //         {customHeader ? customHeader : 'Activity'}
  //       </h4>
  //     </div>
  //     <div className='card-body container-fluid p-0 with-scroll no-x-scroll'>
  //       {accountState?.pastTransactions?.filter((pt) =>
  //         interaction ? pt.interaction === interaction : true
  //       ).length === 0 && (
  //         <div className='row p-0'>
  //           <div className='col-12'>
  //             <div className='text-center mt-3 mb-5'>
  //               <FontAwesomeIcon
  //                 icon={faWind}
  //                 className='mt-3 mb-5'
  //                 size='5x'
  //               />
  //               <h3 className='mt-2'>No activity to display</h3>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //       {(accountState?.pastTransactions?.filter((pt) =>
  //         interaction ? pt.interaction === interaction : true
  //       )?.length ?? 0) > 0 && (
  //         <div className='row my-4 px-4' style={{ height: '40vh' }}>
  //           <ul className='timeline'>
  //             {accountState?.pastTransactions
  //               ?.filter((pt) =>
  //                 interaction ? pt.interaction === interaction : true
  //               )
  //               .map((tx, i) => (
  //                 <li key={`activity-key-${i}`}>
  //                   <ActivityInfo
  //                     timestamp={tx.timestamp}
  //                     interaction={tx.interaction}
  //                     message={tx.message}
  //                     valueMessage={tx.valueMessage}
  //                     txHash={tx.txHash}
  //                   />
  //                 </li>
  //               ))}
  //             <li>{/* <ActivityInfo  />  */}</li>
  //           </ul>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
  return null;
};

export default Activity;
