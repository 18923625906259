import { useEffect } from 'react';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { SOUL_NFT_STAKING_SC_ADDRESS } from 'config/dapp-config';
import { useSoulNftStaking } from 'hooks/contracts/soulNftStaking/useSoulNftStaking';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';
import { useMultiversXApi } from 'hooks/MultiversX/useMultiversXApi';
import {
  SoulStakingPoolState,
  selectStatus,
  setSoulNftStakingPool,
  setStatus
} from 'store/slices/kosonv2/soul-staking-pool';
import { StakedSoulType } from 'types/store';
import { parseStakedSoulTokenBalanceType } from 'utils';

export const SoulStakingPoolLoader = () => {
  const { address } = useGetAccountInfoWrapper();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const { getNftStakingContext } = useSoulNftStaking();
  const { getNftDetailsByIdentifiers, getAddressNftsCount } =
    useMultiversXApi();

  const dispatch = useDispatch();
  const status = useSelector(selectStatus);

  const loadSoulStakingPool = async (): Promise<SoulStakingPoolState> => {
    const scContext = await getNftStakingContext(address);
    const totalStakedNftsCount = await getAddressNftsCount(
      SOUL_NFT_STAKING_SC_ADDRESS
    );

    console.log(scContext);

    const userStakedApiNfts =
      scContext.stakedAssets.length > 0
        ? await getNftDetailsByIdentifiers(
            scContext.stakedAssets.map(
              (stakedAsset) => stakedAsset.asset.fullIdentifier
            )
          )
        : [];

    const stakedSouls: StakedSoulType[] = userStakedApiNfts.map(
      (apiSoulNft) => {
        const stakedAsset = scContext.stakedAssets.find(
          (asset) => asset.asset.fullIdentifier === apiSoulNft.identifier
        );

        return parseStakedSoulTokenBalanceType(
          apiSoulNft,
          stakedAsset?.stakeEpoch ?? -1,
          stakedAsset?.unstakeFee ?? '-1'
        );
      }
    );

    return {
      totalStakedNftsCount,
      totalStakedScore: scContext.aggregatedScore,
      userStakedNftsCount: stakedSouls.length,
      userStakedScore: scContext.userScore,
      stakedSouls,
      pendingRewards: scContext.pendingRewards
    };
  };

  // load soul nft staking pool
  useEffect(() => {
    if (status !== 'loading' && hasPendingTransactions) {
      return;
    }

    loadSoulStakingPool()
      .then((state) => {
        dispatch(setSoulNftStakingPool(state));
        dispatch(setStatus('succeeded'));
      })
      .catch((err) => {
        console.log(
          'Something went wrong when loading soul staking pool: ',
          err
        );
        dispatch(setStatus('failed'));
      });
  }, [hasPendingTransactions, address]);

  return <></>;
};
