import { AddressValue, Address } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import {
  LAND_PLOT_STAKING_SC_ADDRESS,
  SOUL_NFT_STAKING_SC_ADDRESS
} from 'config/dapp-config';
import {
  getSmartContract,
  Parser,
  Provider
} from 'contexts/Web3Context/helpers/getScObj';
import {
  DefaultState,
  PendingRewardsData
} from 'store/slices/accountInfo/pendingRewards';

export const getPendingRewards = async (
  address: string
): Promise<PendingRewardsData> => {
  const nftStakingPendingRewards = await getNftStakingPendingRewards(address);
  const landStakingPendingRewards =
    await getLandChestAndPlotStakingPendingRewards(address);

  return {
    soulStaking: nftStakingPendingRewards,
    landChestStaking: landStakingPendingRewards,
    kosonStakingClaimable: 0,
    kosonStakingTotal: 0
  };
};

const getNftStakingPendingRewards = async (address: string) => {
  const addressArg = new AddressValue(new Address(address));

  const contract = await getSmartContract(SOUL_NFT_STAKING_SC_ADDRESS);
  const interaction = contract.methodsExplicit.getRewards([addressArg]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value.dividedBy(new BigNumber(10).pow(18)).toNumber();
  }
  return 0;
};

const getLandChestAndPlotStakingPendingRewards = async (address: string) => {
  const addressArg = new AddressValue(new Address(address));

  const contract = getSmartContract(LAND_PLOT_STAKING_SC_ADDRESS);
  const interaction = contract.methodsExplicit.getPendingRewards([addressArg]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value.dividedBy(new BigNumber(10).pow(18)).toNumber();
  }
  return 0;
};
