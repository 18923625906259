import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllNftListings2, getLatestNftListings2 } from 'api/backendRequests';
import {
  setAllListings,
  setFreshListings,
  setStatus
} from 'store/slices/marketplace/marketplaceListings';
import { MarketplaceListingV2 } from 'types';

export const MarketplaceLoader = () => {
  const dispatch = useDispatch();

  const loadListings = async (): Promise<{
    freshListings: MarketplaceListingV2[];
    allListings: MarketplaceListingV2[];
  }> => {
    const latestListings = await getLatestNftListings2();
    const allListings = await getAllNftListings2();

    return {
      freshListings: latestListings.data,
      allListings: allListings.data
    };
  };

  // load staking pool contexts
  useEffect(() => {
    loadListings().then(({ freshListings, allListings }) => {
      dispatch(setFreshListings(freshListings));
      dispatch(setAllListings(allListings));
      dispatch(setStatus('succeeded'));
    });
  }, []);

  return <></>;
};
