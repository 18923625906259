import { KosonSymbol } from 'components/KosonSymbol';
import { EsdtTokenType } from 'types/MultiversX';
import { formatBigNumberString, getKosonTokenNameByIdentifier } from 'utils';
import { ReactComponent as ElrondLogo } from './../../../../assets/images/aoz/egld-symbol.svg';

const BalanceCard = ({
  balances,
  text
}: {
  balances: EsdtTokenType[];
  text: string;
}) => {
  return (
    <div className='bgl-primary rounded p-3 h-100'>
      {balances.map((balance, index) => (
        <h4
          key={`token-balances-overview-${index}`}
          className='mb-0 d-flex align-items-center justify-content-center'
        >
          {formatBigNumberString(balance.amount)}{' '}
          {balance.tokenIdentifier === 'EGLD' ? (
            <ElrondLogo style={{ width: '15px', marginLeft: '5px' }} />
          ) : (
            <>
              {/* <KosonLogo
                style={{ width: '15px', height: '39px', marginLeft: '5px' }}
              /> */}
              <KosonSymbol tokenIdentifier={balance.tokenIdentifier} />
              <small className='ms-1'>
                {getKosonTokenNameByIdentifier(balance.tokenIdentifier)}
              </small>
            </>
          )}
        </h4>
      ))}
      <small>{text}</small>
    </div>
  );
};

export default BalanceCard;
