import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { MarketplaceInteractionButton } from 'components/Buttons/MarketplaceInteractionButton';
import { toLocaleStringOptions } from 'config/dapp-config';
import { Web3Context } from 'contexts/Web3Context';
import { getTimeUntilFull } from 'contexts/Web3Context/helpers/generalUtils';
import {
  AvailableListingInteractions,
  getAvailableListingInteractions
} from 'helpers';
import { MarketplaceListingV2 } from 'types';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useGetAccountInfoWrapper } from 'hooks/MultiversX/useGetAccountInfoWrapper';

export const ListingDetails = ({
  listing,
  onNavigateBack
}: {
  listing: MarketplaceListingV2;
  onNavigateBack: () => void;
}) => {
  const { priceState } = useContext(Web3Context);
  const { account } = useGetAccountInfoWrapper();

  const [currentPrice, setCurrentPrice] = useState(0);
  const [currentUsdPrice, setCurrentUsdPrice] = useState(0);
  const [expiringTime, setExpiringTime] = useState('');
  const [buySettings, setBuySettings] =
    useState<AvailableListingInteractions>();

  useEffect(() => {
    if (account === undefined) {
      return;
    }
    const lbuySettings = getAvailableListingInteractions(
      {
        address: account.address,
        balance: account.balance
      },
      listing
    );
    setBuySettings(lbuySettings);
  }, [account.address, account.balance]);

  useEffect(() => {
    const nftPrice = new BigNumber(listing.minBid).shiftedBy(-18).toNumber();
    const nftPriceUsd = (priceState?.egldPrice ?? -1) * nftPrice;
    setCurrentPrice(nftPrice);
    setCurrentUsdPrice(nftPriceUsd);
  }, [listing, listing.minBid, priceState?.egldPrice]);

  useEffect(() => {
    setExpiringTime(getTimeUntilFull(listing.endTime));
  }, [listing, listing.endTime]);

  React.useEffect(() => {
    const interval = setInterval(
      () => setExpiringTime(getTimeUntilFull(listing.endTime)),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, [listing]);

  return (
    <div className='card'>
      <div className='card-body listing-box'>
        <div className='listing-spec border-bottom-sep'>
          <div>Listing Type</div>
          <div className='value'>{listing.isBuyout ? 'BUYOUT' : 'AUCTION'}</div>
        </div>
        <div className='listing-spec border-bottom-sep'>
          <div>Chain</div>
          <div className='value'>
            MultiversX{' '}
            <img
              src='/images/mvx/x-mint.png'
              className='img-responsive chain-logo'
            />
          </div>
        </div>
        <div className='listing-spec border-bottom-sep native-price'>
          <div>
            {listing.isBuyout
              ? 'Listing price (Native)'
              : 'Current bid (Native)'}
          </div>
          <div className='value'>
            {currentPrice.toLocaleString(undefined, toLocaleStringOptions)} EGLD
          </div>
        </div>
        <div className='listing-spec border-bottom-sep usd-price'>
          <div>
            {listing.isBuyout ? 'Listing price (USD)' : 'Current bid (USD)'}
          </div>
          <div className='value'>
            ${currentUsdPrice.toLocaleString(undefined, toLocaleStringOptions)}
          </div>
        </div>
        <div className='listing-spec border-bottom-sep full-price'>
          <div>{listing.isBuyout ? 'Listing price' : 'Current bid'}</div>
          <div className='value'>
            {currentPrice.toLocaleString(undefined, toLocaleStringOptions)} EGLD
            / $
            {currentUsdPrice.toLocaleString(undefined, toLocaleStringOptions)}
          </div>
        </div>
        <div className='listing-spec border-bottom-sep'>
          <div>Royalties</div>
          <div className='value'>5%</div>
        </div>
        <div className='listing-spec border-bottom-sep'>
          <div>Description</div>
          <div className='value description'>{listing.description}</div>
        </div>
        <div className='listing-buy-controls mt-5 mb-3'>
          {listing.hasExpired && (
            <div className='mb-2'>Listing has expired</div>
          )}
          {!listing.hasExpired && (
            <div className='mb-2'>Listing expires in {expiringTime}</div>
          )}
          {buySettings !== undefined && (
            <MarketplaceInteractionButton
              isBuyout={listing.isBuyout}
              buyoutPayload={{
                listingId: listing.listingId,
                collection: listing.collection,
                nonce: listing.nonce,
                paymentToken: undefined,
                listingAmountDenominated: listing.minBid,
                isBuyout: listing.isBuyout
              }}
              buySettings={buySettings}
            />
          )}
          <button
            className='btn btn-outline-primary w-50 mt-2'
            onClick={onNavigateBack}
          >
            Back to marketplace
          </button>
        </div>
      </div>
    </div>
  );
};
